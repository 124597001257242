import React, { createContext } from 'react';
import { connect } from 'react-redux';

import { StoreState } from '@src/meta/types/store';
import { RoleAssignmentMap, Role } from '@modules/auth/components/types/user';
import { getRoleAssignmentMap } from '@modules/auth/components/lib/account';

type CollectionsContextProps = {
  readonly: boolean;
};

export const CollectionsContext = createContext<CollectionsContextProps>({
  readonly: false,
});

type Props = {
  children: React.ReactNode;
};

type PropsFromStore = {
  roleAssignments: RoleAssignmentMap | undefined;
};

function hasReadonlyAccess(RoleAssignmentMap: RoleAssignmentMap | undefined) {
  return Boolean(RoleAssignmentMap && RoleAssignmentMap.byc === Role.APPLICATION_RO);
}

function CollectionsContextProvider({ children, roleAssignments }: Props & PropsFromStore) {
  return (
    <CollectionsContext.Provider
      value={{
        readonly: hasReadonlyAccess(roleAssignments),
      }}
    >
      {children}
    </CollectionsContext.Provider>
  );
}

const mapStateToProps = (state: StoreState): PropsFromStore => {
  return {
    roleAssignments: getRoleAssignmentMap(state),
  };
};

export default connect(mapStateToProps)(CollectionsContextProvider);
