export type BillingInfoDto = {
  vatNumber?: string;
  countryCode?: string;
  name?: string;
  email?: string;
  secondaryEmails?: string[];
  companyName?: string;
  address1?: string;
  address2?: string;
  postalCode?: string;
  city?: string;
  type?: Payer;
  province?: string;
  industry?: string;
  subIndustry?: string;
  defaultCurrency?: string;
};

export interface ServerBillingInfo extends BillingInfoDto {
  id: number;
  created: string;
  userId: string;
}

export interface BillingInfo extends BillingInfoDto {
  id: number;
  created: Date;
  userId: string;
}

export enum Payer {
  VAT_REGISTERED_COMPANY = 'VAT_REGISTERED_COMPANY',
  NOT_VAT_REGISTERED_COMPANY = 'NOT_VAT_REGISTERED_COMPANY',
  INDIVIDUAL = 'INDIVIDUAL',
}

export const isPayerTypeCompany = (payerType: Payer | undefined) =>
  payerType === Payer.NOT_VAT_REGISTERED_COMPANY || payerType === Payer.VAT_REGISTERED_COMPANY;

export const isCompany = (billingInfo: BillingInfo | BillingInfoDto) =>
  isPayerTypeCompany(billingInfo.type);

export type UserBillingInfoDto = {
  userId?: string;
  id?: number;
  billingInfo: BillingInfoDto;
};
