import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Breadcrumb } from 'semantic-ui-react';

import config from '@config/config';

import { StoreState } from '../../../meta/types/store';
import { SidebarTab } from '../../../constants/paths/internal';
import { formatActiveTab, activeTabToLink } from '../../../lib/ui';
import { NestedBreadcrumb } from '../../../meta/types/ui';
import { styled } from '../../../styles/Theme';

type Props = {
  activeTab: SidebarTab;
  breadcrumb: NestedBreadcrumb[];
};

const NavbarBreadcrumb = ({ activeTab, breadcrumb }: Props) => {
  const lastBreadcrumb = breadcrumb[breadcrumb.length - 1];
  const linkBreadcrumbs = breadcrumb.slice(0, breadcrumb.length - 1);
  return (
    <StyledBreadcrumb>
      <Breadcrumb.Section
        as={breadcrumb.length > 0 ? Link : undefined}
        key={formatActiveTab[activeTab]}
        content={formatActiveTab[activeTab]}
        to={breadcrumb.length > 0 ? activeTabToLink[activeTab] : undefined}
        active={breadcrumb.length === 0}
      />
      {linkBreadcrumbs.map(b => (
        <React.Fragment key={b.key}>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section {...b} active={false} />
        </React.Fragment>
      ))}
      {breadcrumb.length >= 1 && (
        <>
          <Breadcrumb.Divider icon="right angle" />
          <Breadcrumb.Section {...lastBreadcrumb} active href={undefined} />
        </>
      )}
    </StyledBreadcrumb>
  );
};

const mapStateToProps = (state: StoreState) => ({
  activeTab: state.ui.activeTab,
  breadcrumb: state.ui.nestedBreadcrumb,
});

const StyledBreadcrumb = styled(Breadcrumb)`
  font-size: 1.2rem !important;
  font-weight: normal;

  a {
    color: ${() => config.primaryColor} !important;
    font-weight: bold;
  }
`;

export default connect(mapStateToProps)(NavbarBreadcrumb);
