import { NewsEntry } from '../meta/types/news';
import { UserData } from '../meta/types/userdata';

export function checkHasUnseenNews(
  news: NewsEntry[] | undefined,
  userData: UserData | undefined,
): boolean {
  if (news === undefined || news.length === 0 || userData === undefined) {
    return false;
  }

  const lastShownNewsEntryId = userData.last_shown_news_entry_id;

  if (lastShownNewsEntryId === undefined) {
    return true;
  }

  return news.some(newsEntry => newsEntry.id > lastShownNewsEntryId);
}
