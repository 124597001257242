import { RoleAssignmentMap, RoleAssignmentTarget, Role } from '@modules/auth/components/types/user';

const ROLES_WITH_ACCESS = [
  Role.USER,
  Role.ROOT,
  Role.SH_SERVICE,
  Role.APPLICATION_RO,
  Role.SUPPORT,
  Role.SUPPORT_READ,
];

const hasAccess = (rat: RoleAssignmentTarget, ra?: RoleAssignmentMap) =>
  ra && ROLES_WITH_ACCESS.includes(ra[rat]);

export const hasAccessToByoc = (ra?: RoleAssignmentMap) => hasAccess(RoleAssignmentTarget.BYOC, ra);

export const hasAccessToBatch = (ra?: RoleAssignmentMap) =>
  hasAccess(RoleAssignmentTarget.BATCH, ra);

export const hasAccessToBatchStatistical = (ra?: RoleAssignmentMap) =>
  hasAccess(RoleAssignmentTarget.BATCH_STATISTICS, ra);

export const hasAccessToTPDI = (ra?: RoleAssignmentMap) =>
  hasAccess(RoleAssignmentTarget.DATA_IMPORT, ra);
