import { localLogout, ILogout } from '@src/actions/auth';
import { ThunkDispatch } from '@src/meta/types/actions';
import config from '@config/config';

import { getAccessToken, doLogout } from '..';

type Authorization = {
  access_token: string;
  expires_in: number;
  id_token: string;
  state: string;
};

class Auth {
  static getAuthorizationHeader = () => {
    return `Bearer ${Auth.getAccessToken()}`;
  };

  static getAccessToken = (): string => {
    return getAccessToken() || '';
  };

  static getAuthorization = (): Authorization | undefined => {
    return undefined;
  };

  // eslint-disable-next-line
  static deleteAuthorization = () => {};

  static passwordChangeUrlClick = () => {
    window.open(
      `${config.keycloakAuthUrl}/realms/${
        config.keycloakRealm
      }/login-actions/reset-credentials?client_id=${
        config.clientId
      }&redirect_uri=${encodeURIComponent(window.location.href)}`,
      '_blank',
    );
  };

  static logout = () => {
    return (dispatch: ThunkDispatch): any => {
      doLogout();
      return dispatch(localLogout());
    };
  };
}

export default Auth;
