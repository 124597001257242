import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { Claims, SentinelUser, AccountInfo } from '@modules/auth/components/types/user';

import { logout } from '../../../actions/auth';
import { setMenuTab, setSidebarCollapsed } from '../../../actions/ui';
import { SidebarTab } from '../../../constants/paths/internal';
import { StoreState } from '../../../meta/types/store';
import Sidebar from './Sidebar';
import { NewsEntry } from '../../../meta/types/news';
import { fetchUserData } from '../../../actions/dashboard';
import { checkHasUnseenNews } from '../../../utils/dashboard';
import { UserData } from '../../../meta/types/userdata';
import useWindowSize from '../../../hooks/WindowSize';

type SidebarContextProps = {
  sidebarCollapsed: boolean;
  activeTab: SidebarTab;
  authenticatedUser?: Claims;
  configurableUser?: SentinelUser;
  logout: any;
  rootManaging: boolean;
  hasUnseenNews: boolean;
  news?: NewsEntry[];
  shAccount: AccountInfo | undefined;
};

export const SidebarContext = React.createContext<SidebarContextProps>({
  sidebarCollapsed: false,
  activeTab: SidebarTab.DASHBOARD,
  authenticatedUser: undefined,
  configurableUser: undefined,
  logout: undefined,
  rootManaging: false,
  news: undefined,
  hasUnseenNews: false,
  shAccount: undefined,
});

interface SidebarProps {
  activeTab: SidebarTab;
  setMenuTab: (menuTab: SidebarTab) => void;
  sidebarCollapsed: boolean;
  setSidebarCollapsed: (collapsed: boolean) => void;
  authenticatedUser?: Claims;
  configurableUser?: SentinelUser;
  rootManaging: boolean;
  logout: any;
  fetchUserData: () => void;
  news?: NewsEntry[];
  userData?: UserData;
  shAccount: AccountInfo | undefined;
}

const SidebarContainer = ({
  activeTab,
  sidebarCollapsed,
  setSidebarCollapsed,
  authenticatedUser,
  configurableUser,
  logout,
  rootManaging,
  fetchUserData,
  news,
  userData,
  shAccount,
}: SidebarProps) => {
  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const { width } = useWindowSize(true);

  // Collapse sidebar on mount when using on mobile
  useEffect(() => {
    if (width !== undefined && width < 600) {
      setSidebarCollapsed(true);
    }
    // eslint-disable-next-line
  }, [width]);

  const hasUnseenNews = checkHasUnseenNews(news, userData);

  return (
    <SidebarContext.Provider
      value={{
        sidebarCollapsed,
        activeTab,
        authenticatedUser,
        configurableUser,
        logout,
        rootManaging,
        news,
        hasUnseenNews,
        shAccount,
      }}
    >
      <Sidebar setSidebarCollapsed={setSidebarCollapsed} />
    </SidebarContext.Provider>
  );
};

function mapStateToProps(state: StoreState) {
  const shAccount = state.account.account;
  return {
    authenticatedUser: state.auth.authenticatedUser,
    activeTab: state.ui.activeTab,
    sidebarCollapsed: state.ui.sidebarCollapsed,
    rootManaging: state.auth.rootManaging,
    news: state.dashboard.news,
    userData: state.dashboard.userData,
    shAccount,
  };
}

const mapDispatchToProps = {
  setMenuTab,
  setSidebarCollapsed,
  logout,
  fetchUserData,
};

const ConnectedSidebarContainer = connect(mapStateToProps, mapDispatchToProps)(SidebarContainer);

export default ConnectedSidebarContainer;
