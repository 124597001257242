import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import config from '@config/config';

import AsyncLoad from '@src/components/common/AsyncLoad';
import { BaseAuthRoute } from '@src/components/common/Route';
import { withTracker } from '@src/hocs';

import { Props, BatchStatisticalType } from './types';

export const RouteContainer = posed.div({
  enter: { opacity: 1, beforeChildren: true, delay: 125 },
  exit: { opacity: 0 },
});

const AsyncStatBatchPage = AsyncLoad({
  loader: () => import('./components/StatBatchPage'),
}) as React.FunctionComponent;
const AsyncStatBatchPageWithTracker = withTracker(AsyncStatBatchPage);

function NewsBase({}: Props): BatchStatisticalType {
  return () => {
    return (
      <PoseGroup className="batch-statistical-routes-container">
        <RouteContainer key="batch-statistical-routes-container">
          <BaseAuthRoute
            path={config.STATAPI_BATCH_PATH}
            component={AsyncStatBatchPageWithTracker}
          />
        </RouteContainer>
      </PoseGroup>
    );
  };
}
export * from './types';
export { NewsBase };
export default NewsBase({});
