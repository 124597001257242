import config from '@config/config';

import { styled } from '../../../styles/Theme';

type DashboardCardProps = {
  height?: string;
  borderRadius?: string;
  noPadding?: boolean;
};

export default styled.section<DashboardCardProps>`
  height: ${props => props.height || '100%'};
  background: ${config.backgroundGrey};
  padding: ${props => (props.noPadding ? '0' : '1.5rem')};
  min-width: 300px;
`;
