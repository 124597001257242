import { ComplianceStatus } from '@src/meta/types/compliance';

export type SentinelUser = {
  created: Date;
  givenName: string;
  familyName: string;
  email: string;
  countryCode?: string;
  id: string;
  organization: Organization;
  active: boolean;
  extra?: SentinelUserExtra;
  complianceStatus?: ComplianceStatus;
};

export enum RoleAssignmentTarget {
  CONFIGURATION = 'cfg',
  INDEX = 'idx',
  DASHBOARD = 'dbd',
  IDENTITY = 'idn',
  MANAGEMENT = 'idn',
  OGC = 'ogc',
  PROCESSOR = 'prc',
  ASYNC_PROCESSOR = 'asp',
  BYOC = 'byc',
  DATA_IMPORT = 'di',
  RATE_LIMIT = 'rl',
  BATCH = 'btc',
  BATCH_STATISTICS = 'btc',
  ZARR = 'byc',
  ACCOUNTING = 'acc',
  ACCESS_CONTROL = 'acl',
  RATE_OVERLORD = 'rol',
  INDEX_SERVICE2 = 'idx2',
}

export enum Role {
  NO_ROLE = 0,
  USER = 1,
  ROOT = 2,
  SH_SERVICE = 3,
  APPLICATION_RO = 4,
  SUPPORT = 8,
  SUPPORT_READ = 9,
}

export type RoleAssignmentMap = {
  [key in RoleAssignmentTarget]: Role;
};

export type Organization = {};

export type Claims = {
  name: string;
  given_name?: string;
  family_name?: string;
  email: string;
  preferred_username: string;
} & { [key: string]: string };

type SentinelUserExtra = {
  extAuthProviderId?: number;
  extAuthId?: string;
};

/* Server responses */

export const toInternalUser = (user: ServerSentinelUser): SentinelUser => {
  return { ...user, created: new Date(user.created) };
};

export type ServerOrganization = {
  '@id': string;
};

export type ServerSentinelUser = {
  id: string;
  givenName: string;
  familyName: string;
  email: string;
  created: string;
  active: boolean;
  organization: ServerOrganization;
  extra: SentinelUserExtra;
};

export interface UserIdentifiable {
  userId: string;
}

type AccountInfoBase = {
  id: string;
  name: string;
  type: number;
  typeInfo: AccountTypeInfo;
  oauthClientLimitN: number;
  roleAssignments: RoleAssignmentMap;
};

export type ServerAccountInfo = AccountInfoBase & {
  validTo: string;
};

export type AccountInfo = AccountInfoBase & {
  validTo: Date;
};

type AccountTypeInfo = {
  id: number;
  name: string;
  properties: AccountTypeProperties;
};

type AccountTypeProperties = Record<any, any>;
