import * as Sentry from 'raven-js';

import { ErrorResponse } from '../../meta/types/api';
import { ApiError } from '../api';

class SentryUtils {
  static ignoredStatuses = [401];

  static captureAPIError(apiError: ApiError<any>, clientErrorMessage?: string) {
    if (!SentryUtils.ignoredStatuses.includes(apiError.status)) {
      const isBoomResponse: boolean =
        apiError && apiError.body && apiError.body.error !== undefined;

      if (isBoomResponse) {
        const boomAPIError = apiError as ApiError<ErrorResponse<any>>;
        Sentry.captureException(boomAPIError.body.error.message, {
          extra: boomAPIError.body.error.errors,
        });
      } else {
        const errorMessage =
          clientErrorMessage || 'Something went wrong while communicating with backend API';

        Sentry.captureException(new Error(errorMessage), { extra: apiError.body });
      }
    }
  }
}

export default SentryUtils;
