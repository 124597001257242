import { omit } from 'lodash';
import React from 'react';
import { Modal, ModalProps } from 'semantic-ui-react';

interface Props extends ModalProps {
  children: (handleClose: () => void) => React.ReactNode;
  trigger?: (handleOpen: () => void) => React.ReactNode;
  handleKeyDown?: any;
  open?: boolean;
  initialOpen?: boolean;
}

function getInitialState(props: Props): State {
  return { open: props.initialOpen !== undefined ? props.initialOpen : false };
}

type State = {
  open: boolean;
};

class BaseModalContainer extends React.PureComponent<Props, State> {
  static readonly defaultProps = {
    trigger: () => undefined,
    children: (handleClose: () => void) => <div onClick={handleClose}>Handle close</div>,
  };

  readonly state: State = getInitialState(this.props);

  componentDidUpdate() {
    const { open } = this.state;

    if (open) {
      window.addEventListener('keydown', this.handleKeyDown);
    } else {
      window.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleOpen = () => this.setState({ open: true });

  handleClose = () => this.setState({ open: false });

  handleKeyDown = (event: any) => {
    if (this.props.handleKeyDown) {
      this.props.handleKeyDown(event);
    }
  };

  render() {
    const { children, trigger, open: propsOpen } = this.props;
    const { open } = this.state;
    const actualyOpen = propsOpen || open;

    return (
      <>
        <Modal
          {...omit(this.props, ['handleKeyDown', 'trigger', 'initialOpen'])}
          open={actualyOpen}
          trigger={trigger ? trigger(this.handleOpen) : () => undefined}
          onClose={this.handleClose}
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
        >
          {children(this.handleClose)}
        </Modal>
      </>
    );
  }
}

export default BaseModalContainer;
