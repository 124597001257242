import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import config from '@config/config';

import { canUserManageConfigurations } from '@modules/auth/components/lib/user';
import { Claims } from '@modules/auth/components/types/user';

import { StoreState } from '../../../../meta/types/store';
import { BaseRouteProps } from '../BaseRoute';
import { alertError } from '../../../../lib/alert';

type PropsFromState = {
  authenticatedUser: Claims;
  rootManaging: boolean;
};

type Props = PropsFromState & BaseRouteProps;

const RootProtectedRoute = ({
  authenticatedUser,
  rootManaging,
  component: Component,
  ...rest
}: Props) => {
  const canManageConfigurations = rootManaging || canUserManageConfigurations(authenticatedUser);

  useEffect(() => {
    if (!canManageConfigurations) {
      alertError('No active account exists, please upgrade.');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Route
      {...rest}
      render={props => {
        if (!canManageConfigurations) {
          return <Redirect to={config.USER_SETTINGS_PATH} />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

function mapStateToProps(state: StoreState): PropsFromState {
  return {
    authenticatedUser: state.auth.authenticatedUser!,
    rootManaging: state.auth.rootManaging,
  };
}

const enhance = connect(mapStateToProps);

export default enhance(RootProtectedRoute);
