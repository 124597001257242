import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import config from '@config/config';

import AsyncLoad from '@src/components/common/AsyncLoad';
import { BaseAuthRoute } from '@src/components/common/Route';
import { withTracker } from '@src/hocs';

import { Props, BatchType } from './types';

export const RouteContainer = posed.div({
  enter: { opacity: 1, beforeChildren: true, delay: 125 },
  exit: { opacity: 0 },
});

const AsyncBatchPage = AsyncLoad({
  loader: () => import('./components/BatchPage'),
}) as React.FunctionComponent;
const AsyncBatchPageWithTracker = withTracker(AsyncBatchPage);

function BatchBase({}: Props): BatchType {
  return () => {
    return (
      <PoseGroup className="batch-routes-container">
        <RouteContainer key="batch-routes-container">
          <BaseAuthRoute path={config.BATCH_PATH} component={AsyncBatchPageWithTracker} />
        </RouteContainer>
      </PoseGroup>
    );
  };
}
export * from './types';
export { BatchBase };
export default BatchBase({});
