import React from 'react';
import { MessageProps, Icon } from 'semantic-ui-react';

import config from '@config/config';

import BaseBanner from './BaseMessage';
import { styled } from '../../../styles/Theme';

type Props = {
  header?: string;
} & MessageProps;

export const getBannerIconStyles = (color: string) => {
  return `.icon-wrapper {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${color};
    i {
      margin-right: 0;
    }
  }`;
};

const SuccessMessage = (props: Props) => {
  return (
    <StyledSuccessMessage>
      <div className="icon-wrapper">
        <Icon name="checkmark" />
      </div>
      <BaseBanner {...props} style={{ width: '100%', marginTop: '0' }}>
        {props.children}
      </BaseBanner>
    </StyledSuccessMessage>
  );
};

export default SuccessMessage;

const StyledSuccessMessage = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1rem;

  ${getBannerIconStyles(config.primaryColor)}
`;
