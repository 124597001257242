class StringUtils {
  public static capitalize(s: string) {
    return s.charAt(0).toUpperCase() + s.substring(1, s.length).toLowerCase();
  }

  public static isNonEmpty = (s?: string) => {
    return s ? s.trim() !== '' : false;
  };

  public static isEmpty = (s?: string) => {
    return !StringUtils.isNonEmpty(s);
  };

  public static randomString(len = 40) {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';

    for (let i = 0; i < len; i += 1) {
      randomString += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return randomString;
  }

  public static canParseToObject(s: string): boolean {
    try {
      JSON.parse(s);
      return true;
    } catch (err) {
      return false;
    }
  }
}

export default StringUtils;
