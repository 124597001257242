export const ROOT_PATH = '/';

export enum SidebarTab {
  DASHBOARD = 'DASHBOARD',
  CONFIGURATOR = 'CONFIGURATOR',
  ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS',
  STATISTICS = 'STATISTICS',
  BILLING = 'BILLING',
  BYOC = 'BYOC',
  HELP = 'Help',
  NEWS = 'NEWS',
  BATCH = 'BATCH',
  STATAPI_BATCH = 'STATAPI_BATCH',
  TPDI = 'TPDI',
  PLANS = 'PLANS',
}

export const SH_SUPPORT_EMAIL = 'support@sentinel-hub.com';
export const INFO_EMAIL = 'info@sentinel-hub.com';
