import React from 'react';
import { MessageProps } from 'semantic-ui-react';
import BaseBanner from './BaseMessage';

type Props = {
  header?: string;
} & MessageProps;

const InfoMessage = (props: Props) => <BaseBanner {...props} />;

export default InfoMessage;
