import React from 'react';
import { connect } from 'react-redux';

import WarningMessage from '@src/components/common/banners/WarningMessage';
import { StoreState } from '@src/meta/types/store';
import { SentinelUser } from '@modules/auth/components/types/user';

import { getDisplayedName } from '../../components/lib/user';

type PropsFromStore = {
  configurableUser: SentinelUser | undefined;
  rootManaging: boolean;
};

const ImpersonationBanner = ({ configurableUser, rootManaging }: PropsFromStore) => {
  if (!rootManaging) {
    return null;
  }
  return (
    <div style={{ margin: '1rem' }}>
      <WarningMessage
        warning
        content={`Managing user ${getDisplayedName(
          configurableUser!,
        )} as root. Any changes you make will be applied on their account.`}
      />
    </div>
  );
};

const mapStateToProps = (state: StoreState): PropsFromStore => ({
  configurableUser: state.auth.configurableUser,
  rootManaging: state.auth.rootManaging,
});

export default connect(mapStateToProps)(ImpersonationBanner);
