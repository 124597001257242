import { AdyenEnv } from '@src/meta/types/billing/adyen';
import { PaypalEnv } from '@src/meta/types/billing/paypal';

import baseConfig from '../baseConfig';
import { DefaultThemePaymentOptionsConfig } from '../config.types';
import AppConfig, {
  ClientsInfoMappingConfig,
  CommonServicesConfig,
  KeycloakConfig,
} from '../../baseAppConfig.types';
import prodEndpoints from './common/prodEndpoints';
import { Environment } from '../../baseAppConfig';
import { prodKeycloakConfig } from './common/prodKeycloak';
import clientsInfo from './common/prodClientsInfo';

const prodServicesConfig: CommonServicesConfig & KeycloakConfig & ClientsInfoMappingConfig = {
  ...prodKeycloakConfig,
  ...clientsInfo,
  clientId: '30cf1d69-af7e-4f3a-997d-0643d660a478',
  appsBaseUrl: 'https://apps.sentinel-hub.com/',
  configurationTemplateAccountId: 'b191955e-d7bc-48eb-971e-9b6fd0a3299f',

  sentry: {
    dsn: 'https://5336c02b48194ca09ac6f65803f0d663@sentry.io/1339645',
    env: 'prod',
    enable: true,
  },

  analytics: {
    trackingID: 'DQMEKKLA',
    enable: true,
  },

  environment: Environment.PRODUCTION,
};

const prodPaymentConfig: DefaultThemePaymentOptionsConfig = {
  adyenClientId: 'live_GHP4REL4JZDTNESRJUKBD7G56EBFFMPP',
  adyenEnvironment: AdyenEnv.LIVE,
  paypalEnv: PaypalEnv.PRODUCTION,
};

const prodConfig: AppConfig = {
  ...baseConfig,
  ...prodServicesConfig,
  ...prodEndpoints,
  ...prodPaymentConfig,
};

export default prodConfig;
