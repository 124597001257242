import moment from 'moment-timezone';
import Calendar from 'rc-calendar/lib/Calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import TimePickerPanel from 'rc-time-picker/lib/Panel';
import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { formatLocalDate, formatUtcDate } from '../../../lib/date';

require('rc-calendar/dist/rc-calendar.css');
require('rc-time-picker/assets/index.css');

const DatePickerContainer = ({
  format,
  inputEnabled,
  value,
  handleChange,
  error,
  width = '165px',
  disabledDates,
  defaultTime = moment('12:00:00', 'HH:mm:ss'),
  timePickerDisabled = true,
  showDateInput = true,
  showTime = false,
}) => {
  return (
    <DatePicker
      animation="slide-down"
      calendar={
        <Calendar
          showToday
          showClear
          disabledDate={disabledDates}
          onChange={changedValue => {
            if (changedValue !== undefined) {
              changedValue.millisecond(0);
            }
          }}
          format={format}
          style={{ zIndex: 1000, marginTop: 33 }}
          timePicker={
            timePickerDisabled === false && <TimePickerPanel defaultValue={defaultTime} />
          }
          showDateInput={showDateInput}
        />
      }
      value={value}
      onChange={changedValue => handleChange(changedValue)}
      disabled={!inputEnabled}
    >
      {({ value }) => (
        <Form.Field error={error}>
          <Input
            style={{ width }}
            readOnly={!inputEnabled}
            value={
              value &&
              (showTime ? formatUtcDate(value.valueOf()) : formatLocalDate(value.valueOf()))
            }
            placeholder={value ? 'Select date' : 'No date selected'}
          />
          {error ? <div style={{ color: 'red' }}>To date is before start date</div> : null}
        </Form.Field>
      )}
    </DatePicker>
  );
};

export default DatePickerContainer;
