import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import config from '@config/config';

import AsyncLoad from '@src/components/common/AsyncLoad';
import { BaseAuthRoute } from '@src/components/common/Route';
import { withTracker } from '@src/hocs';

import { Props, HelpType } from './types';

export const RouteContainer = posed.div({
  enter: { opacity: 1, beforeChildren: true, delay: 125 },
  exit: { opacity: 0 },
});

const AsyncHelpPage = AsyncLoad({
  loader: () => import('./components/Help'),
}) as React.FunctionComponent;
const AsyncHelpPageWithTracker = withTracker(AsyncHelpPage);

function HelpBase({}: Props): HelpType {
  return () => {
    return (
      <PoseGroup className="help-routes-container">
        <RouteContainer key="help-routes-container">
          <BaseAuthRoute path={config.HELP_PAGE_PATH} component={AsyncHelpPageWithTracker} />
        </RouteContainer>
      </PoseGroup>
    );
  };
}
export * from './types';
export { HelpBase };
export default HelpBase({});
