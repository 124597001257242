import { isEmpty, Dictionary } from 'lodash';

import { Claims, SentinelUser } from '@modules/auth/components/types/user';

import * as actionTypes from '../../constants/actionTypes';
import SentryUtils from '../../lib/sentry';
import { ThunkDispatch } from '../../meta/types/actions';
import {
  QuickTimeRange,
  StatisticsWrapper,
  ArchiveTimeRangePayload,
} from '../../meta/types/requests-statistics';
import { StoreState } from '../../meta/types/store';
import StatisticsResource from '../../services/Accounting/StatisticsResource';
import RequestStatisticsResource from '../../services/Dashboard/RequestStatisticsResource';
import { mapNewsEntry, NewsEntry } from '../../meta/types/news';
import { UserData } from '../../meta/types/userdata';
import UserDataResource from '../../services/Dashboard/UserDataResource';
import NewsResource from '../../services/Dashboard/NewsResource';
import TpdiService, { TpdiQuotaResponse } from '../../services/ThirdPartyDataImport/TpdiService';
import { getMessageFromApiError } from '../../lib/api';

// ------------ Request statistics ------------

export interface ISetStatisticsTimeRange {
  type: actionTypes.SET_STATISTICS_TIME_RANGE;
  timeRange: QuickTimeRange;
}

export interface ISetArchiveTimeRange {
  type: actionTypes.SET_ARCHIVE_TIME_RANGE;
  archiveTimeRange: ArchiveTimeRangePayload;
}

export interface ISetUsingArchiveTimeRange {
  type: actionTypes.SET_SELECT_ARCHIVE_TIME_RANGE;
  isUsingArchive: boolean;
}

export const setStatisticsTimeRange = (timeRange: QuickTimeRange): ISetStatisticsTimeRange => ({
  type: actionTypes.SET_STATISTICS_TIME_RANGE,
  timeRange,
});

export const setArchiveTimeRange = (
  archiveTimeRange: ArchiveTimeRangePayload,
): ISetArchiveTimeRange => ({
  type: actionTypes.SET_ARCHIVE_TIME_RANGE,
  archiveTimeRange,
});

export const setUsingArchiveTimeRange = (isUsingArchive: boolean): ISetUsingArchiveTimeRange => ({
  type: actionTypes.SET_SELECT_ARCHIVE_TIME_RANGE,
  isUsingArchive,
});

// Statistics

export interface ISetStatisticsFetching {
  type: actionTypes.SET_STATISTICS_FETCHING;
}

export const setStatisticsFetching = (): ISetStatisticsFetching => {
  return {
    type: actionTypes.SET_STATISTICS_FETCHING,
  };
};

export interface ISetStatistics {
  type: actionTypes.SET_STATISTICS;
  cost: StatisticsWrapper;
  timeRange: QuickTimeRange;
}

export const setStatistics = (
  cost: StatisticsWrapper,
  timeRange: QuickTimeRange,
): ISetStatistics => {
  return {
    type: actionTypes.SET_STATISTICS,
    cost,
    timeRange,
  };
};

export interface ISetStatisticsError {
  type: actionTypes.SET_STATISTICS_ERROR;
  message: string;
}

export const setStatisticsError = (message: string): ISetStatisticsError => {
  return {
    type: actionTypes.SET_STATISTICS_ERROR,
    message,
  };
};

export const fetchStatisticsHistogram = (timeRange: QuickTimeRange) => {
  return async (dispatch: ThunkDispatch, getState: () => StoreState<SentinelUser, Claims>) => {
    const dashboardState = getState().dashboard;
    if (dashboardState.fetchingStatistics) {
      return;
    }

    const costCache = dashboardState.statisticsCache.statistics;
    if (costCache[timeRange.value] !== undefined) {
      return;
    }

    const state = getState();
    const shAccount = state.account.account;
    dispatch(setStatisticsFetching());
    try {
      if (timeRange.oldValue === null) {
        const monthYear = timeRange.value.split('-');
        const response = await StatisticsResource.getArchive({
          accountId: shAccount?.id,
          resolution: timeRange.resolution,
          year: monthYear[1],
          month: (Number(monthYear[0]) + 1).toString(),
        });
        dispatch(setStatistics(response.data, timeRange));
      } else {
        const response = await StatisticsResource.getStatistics({
          accountId: shAccount?.id,
          range: timeRange.value,
          resolution: timeRange.resolution,
        });
        dispatch(setStatistics(response.data, timeRange));
      }
    } catch (apiError) {
      const errorMessage = 'Something went wrong while fetching statistics';
      dispatch(setStatisticsError(errorMessage));
      SentryUtils.captureAPIError(apiError, errorMessage);
    }
  };
};

// Rate limited request count
export interface ISetRateLimitedOgcCountFetching {
  type: actionTypes.SET_RATE_LIMITED_REQUESTS_COUNT_FETCHING;
  fetching: boolean;
}

/* Rate limited count */
export const setRateLimitedRequestsCountFecthing = (
  fetching: boolean,
): ISetRateLimitedOgcCountFetching => ({
  type: actionTypes.SET_RATE_LIMITED_REQUESTS_COUNT_FETCHING,
  fetching,
});

export interface ISetRateLimitedOgcCount {
  type: actionTypes.SET_RATE_LIMITED_REQUESTS_COUNT;
  count: number;
  timeRange: QuickTimeRange;
}

export const setRateLimitedRequestsCount = (
  count: number,
  timeRange: QuickTimeRange,
): ISetRateLimitedOgcCount => ({
  type: actionTypes.SET_RATE_LIMITED_REQUESTS_COUNT,
  count,
  timeRange,
});

export interface ISetRateLimitedOgcError {
  type: actionTypes.SET_RATE_LIMITED_REQUESTS_ERROR;
  message: string;
}

const setRateLimitedRequestsError = (message: string) => {
  return {
    type: actionTypes.SET_RATE_LIMITED_REQUESTS_ERROR,
    message,
  };
};

export const fetchRateLimitedRequestsCount = (userId: string, timeRange: QuickTimeRange) => {
  return async (dispatch: ThunkDispatch, getState: () => StoreState) => {
    if (timeRange.oldValue === null) {
      dispatch(setRateLimitedRequestsCount(0, timeRange));
      return;
    }
    const dashboardState = getState().dashboard;
    if (dashboardState.fetchingRateLimitedCount) {
      return;
    }
    const rateLimitedTimeRanges = dashboardState.statisticsCache.rateLimitedRequestsCount;

    if (rateLimitedTimeRanges[timeRange.value] !== undefined) {
      return;
    }

    dispatch(setRateLimitedRequestsCountFecthing(true));
    try {
      const resp = await RequestStatisticsResource.getRateLimitedRequestsCount({
        userId,
        timestamp_gte: timeRange.oldValue,
      });
      dispatch(setRateLimitedRequestsCount(resp.data, timeRange));
    } catch (apiError) {
      const errorMessage = 'Something went wrong while fetching rate limited request count';
      dispatch(setRateLimitedRequestsError(errorMessage));
      SentryUtils.captureAPIError(apiError, errorMessage);
    }
  };
};

// News

export const fetchNews = () => {
  return async (dispatch: ThunkDispatch, getState: () => StoreState) => {
    const dashboardState = getState().dashboard;
    if (dashboardState.fetchingNews) {
      return;
    }

    dispatch(setNewsFetching());

    try {
      const resp = await NewsResource.getNews();
      dispatch(setNews(resp.member.map(mapNewsEntry)));
    } catch (apiError) {
      const errorMessage = 'Something went wrong while fetching news';
      dispatch(setNewsError(errorMessage));
      SentryUtils.captureAPIError(apiError, errorMessage);
    }
  };
};

export interface ISetNewsFetching {
  type: actionTypes.SET_NEWS_FETCHING;
}

export const setNewsFetching = (): ISetNewsFetching => {
  return {
    type: actionTypes.SET_NEWS_FETCHING,
  };
};

export interface ISetNews {
  type: actionTypes.SET_NEWS;
  news: NewsEntry[];
}

export const setNews = (news: NewsEntry[]): ISetNews => {
  return {
    type: actionTypes.SET_NEWS,
    news,
  };
};

export interface ISetNewsError {
  type: actionTypes.SET_NEWS_ERROR;
  message: string;
}

export const setNewsError = (message: string): ISetNewsError => {
  return {
    type: actionTypes.SET_NEWS_ERROR,
    message,
  };
};

// User data

export const fetchUserData = () => {
  return async (dispatch: ThunkDispatch, getState: () => StoreState) => {
    const dashboardState = getState().dashboard;
    if (dashboardState.fetchingUserData) {
      return;
    }

    dispatch(setUserDataFetching());

    try {
      const resp = await UserDataResource.get();
      // Initialize userdata by PUT {}
      if (isEmpty(resp.data)) {
        await UserDataResource.update({ ...resp.data });
      }
      dispatch(setUserData(resp.data));
    } catch (apiError) {
      const errorMessage = 'Something went wrong while fetching user data';
      dispatch(setUserDataError(errorMessage));
      SentryUtils.captureAPIError(apiError, errorMessage);
    }
  };
};

export interface ISetUserDataFetching {
  type: actionTypes.SET_USER_DATA_FETCHING;
}

export const setUserDataFetching = (): ISetUserDataFetching => {
  return {
    type: actionTypes.SET_USER_DATA_FETCHING,
  };
};

export interface ISetUserData {
  type: actionTypes.SET_USER_DATA;
  userData: UserData;
}

export const setUserData = (userData: UserData): ISetUserData => {
  return {
    type: actionTypes.SET_USER_DATA,
    userData,
  };
};

export interface IPatchUserData {
  type: actionTypes.PATCH_USER_DATA;
  userData: UserData;
}

export const patchUserData = (userData: UserData): IPatchUserData => {
  return {
    type: actionTypes.PATCH_USER_DATA,
    userData,
  };
};

export interface ISetUserDataError {
  type: actionTypes.SET_USER_DATA_ERROR;
  message: string;
}

export const setUserDataError = (message: string): ISetUserDataError => {
  return {
    type: actionTypes.SET_USER_DATA_ERROR,
    message,
  };
};

export const markNewsSeen = () => {
  return async (dispatch: ThunkDispatch, getState: () => StoreState) => {
    const state = getState();
    const lastNewsEntryId = Math.max(...state.dashboard.news!.map(entry => entry.id), 0);

    const updatedUserData = {
      ...state.dashboard.userData,
      last_shown_news_entry_id: lastNewsEntryId,
    };

    try {
      await UserDataResource.update(updatedUserData);
    } catch (apiError) {
      const errorMessage = 'Something went wrong while updating user data';
      dispatch(setUserDataError(errorMessage));
      SentryUtils.captureAPIError(apiError, errorMessage);
    }

    dispatch(setUserData(updatedUserData));
  };
};

export type QuotaPair = {
  quota: number;
  used: number;
};

export interface ISetTpdiQuota {
  type: actionTypes.SET_TPDI_QUOTA;
  quotas: Dictionary<QuotaPair>;
}

export interface ISetTpdiQuotaFetching {
  type: actionTypes.SET_TPDI_QUOTA_FETCHING;
}

export interface ISetTpdiQuotaError {
  type: actionTypes.SET_TPDI_QUOTA_ERROR;
  message: string;
}

export const setTpdiQuota = (quotas: Dictionary<QuotaPair>): ISetTpdiQuota => ({
  type: actionTypes.SET_TPDI_QUOTA,
  quotas,
});

export const setTpdiQuotaError = (errorMsg: string): ISetTpdiQuotaError => ({
  type: actionTypes.SET_TPDI_QUOTA_ERROR,
  message: errorMsg,
});

export const fetchTpdiQuotas = (isRefreshing = false) => {
  return async (dispatch: ThunkDispatch, getState: () => StoreState) => {
    const { account, dashboard } = getState();

    const accountId = account.account.id;

    // skip if not refreshing and it's already set.
    if (dashboard.tpdiQuotas !== undefined && !isRefreshing) {
      return;
    }
    try {
      const resp = await TpdiService.getAccountIdQuota({ accountId });
      const { data } = resp;
      const qp: Dictionary<QuotaPair> = data
        .map(i => {
          return { id: i.collectionId, quota: i.quotaSqkm, used: i.quotaUsed };
        })
        .reduce((acc: Dictionary<QuotaPair>, cur) => {
          acc[cur.id] = { quota: cur.quota, used: cur.used };
          return acc;
        }, {});
      dispatch(setTpdiQuota(qp));
    } catch (apiError) {
      const msg = getMessageFromApiError(apiError);
      dispatch(setTpdiQuotaError(msg));
    }
  };
};
