import React from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';
import config from '@config/config';

import { styled } from '../../../../styles/Theme';

const SecondaryButton = (props: ButtonProps) => {
  return <StyledSecondaryButton type="button" {...props} />;
};

const StyledSecondaryButton = styled(Button)(props => {
  const { active, style } = props;
  const { primaryColor, darkBackgroundColor, primaryTextColor } = config;
  return `
  background-color: ${
    style?.background ? style.background : active ? primaryColor : 'transparent'
  } !important;
  color: ${style?.color ? style.color : active ? primaryTextColor : darkBackgroundColor} !important;
  border: ${style?.border ? style.border : `1px solid ${primaryColor}`} !important;
  font-weight: ${active ? 'bold' : 'normal'} !important;
  white-space: nowrap !important;
  border-radius: 0 !important;
`;
});

export default SecondaryButton;
