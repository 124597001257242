export interface IDocumentationLink {
  EVALSCRIPT_V3: string;
  DATA_IMPORT: string;
  BYOC: string;
  PROCESS_CRS: string;
  CONFIGURING_COLLECTIONS: string;
  BATCH: string;
  BATCH_TILING_GRIDS: string;
  BILLING: string;
  BILLING_POLICY: string;
  DATASET_DEM: string;
  DATASET_L8L1C: string;
  DATASET_LTML1: string;
  DATASET_LTML2: string;
  DATASET_LETML1: string;
  DATASET_LETML2: string;
  DATASET_LMSSL1: string;
  DATASET_LOTL1: string;
  DATASET_LOTL2: string;
  DATASET_MODIS: string;
  DATASET_S1GRD: string;
  DATASET_S2L1C: string;
  DATASET_S2L2A: string;
  DATASET_S3OLCI: string;
  DATASET_S3SLSTR: string;
  DATASET_S5PL2: string;
  DATASET_HLS: string;
  OAUTH_CLIENTS: string;
}

export const DocumentationLink: IDocumentationLink = {
  EVALSCRIPT_V3: 'https://docs.sentinel-hub.com/api/latest/evalscript/v3/',
  DATA_IMPORT: 'https://docs.sentinel-hub.com/api/latest/api/data-import/',
  BYOC: 'https://docs.sentinel-hub.com/api/latest/api/byoc/',
  PROCESS_CRS: 'https://docs.sentinel-hub.com/api/latest/api/process/crs/',
  CONFIGURING_COLLECTIONS:
    'https://docs.sentinel-hub.com/api/latest/api/byoc/#configuring-collections',
  BATCH: 'https://docs.sentinel-hub.com/api/latest/api/batch/',
  BATCH_TILING_GRIDS: 'https://docs.sentinel-hub.com/api/latest/api/batch/#tiling-grids',
  BILLING: 'https://docs.sentinel-hub.com/api/latest/api/overview/billing/',
  BILLING_POLICY: 'https://docs.sentinel-hub.com/api/latest/api/overview/billing/#billing-policy',
  DATASET_DEM: 'https://docs.sentinel-hub.com/api/latest/data/dem/',
  DATASET_L8L1C: 'https://docs.sentinel-hub.com/api/latest/data/landsat-8/',
  DATASET_LTML1: 'https://docs.sentinel-hub.com/api/latest/data/landsat-tm/',
  DATASET_LTML2: 'https://docs.sentinel-hub.com/api/latest/data/landsat-tm-l2/',
  DATASET_LETML1: 'https://docs.sentinel-hub.com/api/latest/data/landsat-etm/',
  DATASET_LETML2: 'https://docs.sentinel-hub.com/api/latest/data/landsat-etm-l2/',
  DATASET_LMSSL1: 'https://docs.sentinel-hub.com/api/latest/data/landsat-mss/',
  DATASET_LOTL1: 'https://docs.sentinel-hub.com/api/latest/data/landsat-8-l2/',
  DATASET_LOTL2: 'https://docs.sentinel-hub.com/api/latest/data/landsat-8-l2/',
  DATASET_MODIS: 'https://docs.sentinel-hub.com/api/latest/data/modis/mcd/',
  DATASET_S1GRD: 'https://docs.sentinel-hub.com/api/latest/data/sentinel-1-grd/',
  DATASET_S2L1C: 'https://docs.sentinel-hub.com/api/latest/data/sentinel-2-l1c/',
  DATASET_S2L2A: 'https://docs.sentinel-hub.com/api/latest/data/sentinel-2-l2a/',
  DATASET_S3OLCI: 'https://docs.sentinel-hub.com/api/latest/data/sentinel-3-olci-l1b/',
  DATASET_S3SLSTR: 'https://docs.sentinel-hub.com/api/latest/data/sentinel-3-slstr-l1b/',
  DATASET_S5PL2: 'https://docs.sentinel-hub.com/api/latest/data/sentinel-5p-l2/',
  DATASET_HLS: 'https://docs.sentinel-hub.com/api/latest/data/hls/',
  OAUTH_CLIENTS:
    'https://docs.sentinel-hub.com/api/latest/api/overview/authentication/#registering-oauth-client',
};
