import React from 'react';
import { Visibility, VisibilityEventData } from 'semantic-ui-react';

import { HOC } from '../../meta/types/hoc';

type WidthState = { width?: number };

export type WidthProps = WidthState;

const withWidth = <P, S>(WrappedComponent: HOC<P, WidthState>) => {
  return class WithWidth extends React.PureComponent<P & WidthState, WidthState> {
    state = { width: undefined };

    handleUpdate = (_: any, data: VisibilityEventData) => {
      const {
        calculations: { width },
      } = data;

      if (width !== this.state.width) {
        this.setState({ width });
      }
    };

    render() {
      const { width } = this.state;
      return (
        <Visibility onUpdate={this.handleUpdate} fireOnMount style={{ width: '100%' }}>
          {width !== undefined ? <WrappedComponent {...this.props} width={width} /> : null}
        </Visibility>
      );
    }
  };
};

export default withWidth;
