import ReactLoadable, { OptionsWithoutRender } from 'react-loadable';

import Loading from '../AsyncLoading';

export const ASYNC_LOADING_DELAY = 550;

type Props = {
  loader: () => any;
  delay?: number;
  timeout?: number;
};

function loadableOptions<T>(opts: Props): OptionsWithoutRender<T> {
  return {
    delay: ASYNC_LOADING_DELAY,
    timeout: 10,
    loading: Loading,
    ...opts,
  };
}

function Loadable<T>(opts: Props) {
  return ReactLoadable(loadableOptions<T>(opts));
}

export default Loadable;
