import { EventEmitter } from 'events';

import Auth from '@modules/auth/components/lib/auth';

import {
  FetchConfig,
  ResourceFetch,
  ResourceFetchTemplate,
  ErrorResponse,
} from '../../meta/types/api';
import { ContentType } from '../../meta/types/content-type';
import { HttpMethod, HttpStatus } from '../../meta/types/http';
import { toFormData } from '../form';
import { injectParameters } from '../urls';

type Mappings = {
  [contentType: string]: (resp: any) => any;
};

const defaultHeaders: any = {};

const contentTypeMappings: Mappings = {
  [ContentType.JSON]: resp => resp.json(),
  [ContentType.JS]: resp => resp.json(),
  [ContentType.XML]: resp => resp.text(),
  [ContentType.TEXT]: resp => resp.text(),
  [ContentType.CSV]: resp => resp.text(),
  [ContentType.HTML]: resp => resp.text(),
  'application/json;charset=utf-8': resp => resp.json(),
  'application/json; charset=UTF-8': resp => resp.json(),
  'application/octet-stream': resp => resp.arrayBuffer(),
  [`${ContentType.GEOJSON};charset=utf-8`]: resp => resp.json(),
  [ContentType.PDF]: resp => resp.arrayBuffer(),
};

export type EndpointConfig = {
  authenticated?: boolean;
  endpointHeaders?: HeadersInit;
  withCredentials?: boolean;
  contentType?: ContentType;
};

export const DEFAULT_ENDPOINT_CONFIG = {
  authenticated: true,
  withCredentials: false,
  endpointHeaders: {},
};

const CONTENT_TYPE_HEADER = 'Content-Type';

class Api extends EventEmitter {
  GET = this.makeMethod(HttpMethod.GET);
  POST = this.makeMethod(HttpMethod.POST, true);
  PUT = this.makeMethod(HttpMethod.PUT, true);
  DELETE = this.makeMethod(HttpMethod.DELETE);
  PATCH = this.makeMethod(HttpMethod.PATCH, true);
  HEAD = this.makeMethod(HttpMethod.HEAD);

  setDefaultHeader = (key: string, value: string) => {
    defaultHeaders[key] = value;
  };

  makeMethod(method: HttpMethod, hasBody = false): ResourceFetchTemplate<any, any> {
    return (
      urlTemplate: string,
      endpointConfig: EndpointConfig = DEFAULT_ENDPOINT_CONFIG,
      contentType: ContentType = ContentType.JSON,
    ): ResourceFetch<any, any> => {
      return (data: any = undefined, fetchConfig: FetchConfig = {}): Promise<any> => {
        const { url, params } = injectParameters(urlTemplate, data, hasBody);
        if (fetchConfig.removeQueryParamsFromPayload) {
          for (const param of params) {
            delete data[param];
          }
        }
        const { authenticated, endpointHeaders } = endpointConfig;
        const headers: any = {
          Accept: contentType,
          ...defaultHeaders,
          ...endpointHeaders,
        };

        let body = null;
        if (hasBody && data) {
          if (typeof data === 'string') {
            body = data;
            headers[CONTENT_TYPE_HEADER] = ContentType.TEXT;
          } else if (data instanceof FormData || fetchConfig.asFormData) {
            body = toFormData(data);
            headers[CONTENT_TYPE_HEADER] = ContentType.FORM_DATA;
          } else {
            body = JSON.stringify(data);
            headers[CONTENT_TYPE_HEADER] = ContentType.JSON;
          }
        }

        if (authenticated && !headers.Authorization) {
          headers.Authorization = Auth.getAuthorizationHeader();
        }

        return this.makeRequest(
          method,
          url,
          headers,
          body,
          endpointConfig.withCredentials,
          endpointConfig.contentType,
        );
      };
    };
  }
  makeRequest(
    method: HttpMethod,
    url: string,
    headers: any,
    body: string | FormData | null,
    withCredentials = false,
    customContentType: ContentType | undefined,
  ): Promise<any> {
    return fetch(url, {
      method,
      headers,
      body,
      credentials: withCredentials ? 'include' : 'omit',
    })
      .then((response: any) => {
        this.emit(`${response.status}`, url);
        const contentType = customContentType || response.headers.get(CONTENT_TYPE_HEADER);
        const mappingFunction = contentTypeMappings[contentType] || (resp => resp.text());
        return new Promise(resolve => resolve(mappingFunction(response)))
          .catch(err => {
            return Promise.reject({
              type: 'NetworkError',
              status: response.status,
              message: err,
            });
          })
          .then((responseBody: any) => {
            if (response.ok) {
              return responseBody;
            }

            if (response.status >= HttpStatus.SERVER_ERROR) {
              return Promise.reject({
                type: 'ServerError',
                status: response.status,
                body: responseBody,
              });
            }
            if (response.status < HttpStatus.SERVER_ERROR) {
              return Promise.reject({
                type: 'ApplicationError',
                status: response.status,
                body: responseBody,
              });
            }
          });
      })
      .catch((err: ApiError<any>) => {
        console.error(err);
        return err.type
          ? Promise.reject(err)
          : Promise.reject({
              type: 'ConnectionRefused',
              status: HttpStatus.SERVER_ERROR,
              body: 'Check your internet connection',
              err,
            });
      });
  }
}

export interface ApiError<T> {
  type: 'ApplicationError' | 'ServerError' | 'NetworkError';
  status: number;
  body: T;
}

const instance = new Api();

export default instance;

export const getMessageFromApiError = (
  error: ApiError<ErrorResponse<any>>,
  defaultErrorText = 'Something went wrong',
) => {
  const message = error.body?.error?.message ?? defaultErrorText;
  let errors = error.body?.error?.errors;
  if (!errors) {
    return message;
  }

  if (!errors.length) {
    errors = [errors];
  }
  const len = errors.length;
  const errMessages = errors
    .filter((err: any) => err.parameter !== undefined && err.violation !== undefined)
    .map(
      (error: any, idx: number) =>
        `parameter: ${error.parameter} has an invalid value. ${error.violation}${
          idx < len - 1 ? '\n' : ''
        }`,
    );
  return `${message}\n${errMessages}`;
};
