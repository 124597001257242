import config from '@config/config';

import { BatchDeployment } from '../const';

export const locationToBatchDeployment = (location: string): BatchDeployment => {
  if (location === 'aws-us-west-2') {
    return BatchDeployment.US_WEST_DEPLOYMENT;
  }
  return BatchDeployment.EU_CENTRAL_DEPLOYMENT;
};

export const batchDeploymentToBaseUrl = (deployment: BatchDeployment) => {
  if (deployment === BatchDeployment.US_WEST_DEPLOYMENT) {
    return config.byocUsWestServiceBasePath;
  }
  if (deployment === BatchDeployment.EU_CENTRAL_DEPLOYMENT) {
    return config.byocGlobalServiceBasePath;
  }
  if (deployment === BatchDeployment.CREODIAS) {
    return config.byocCreodiasServiceBasePath;
  }
  return config.byocGlobalServiceBasePath;
};

export const getDefaultBatchDeployment = () => BatchDeployment.EU_CENTRAL_DEPLOYMENT;
