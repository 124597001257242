import { alertError } from '@src/lib/alert';
import SessionStorage from '@src/lib/storage/session';
import config from '@config/config';

import { Claims } from '../../types/user';

const IMPERSONATION_STORAGE_KEY = 'impersonation';

const IMPERSONATED_ACCOUNT_ID_QUERY_PARAM = 'impersonating_account';
const IMPERSONATED_USER_ID_QUERY_PARAM = 'impersonating_user';

const IMPERSONATED_ACCOUNT_ID_KEY = 'impersonatedAccountId';
const IMPERSONATED_USER_ID_KEY = 'impersonatedUserId';

function getURLSearchParams(): URLSearchParams {
  const search = window.location.hash.replace(/^[^?]+\?/, '');
  return new URLSearchParams(search);
}

export function getImpersonationParams() {
  const queryParams = getURLSearchParams();
  let impersonatedAccountId = queryParams.get(IMPERSONATED_ACCOUNT_ID_QUERY_PARAM);
  let impersonatedUserId = queryParams.get(IMPERSONATED_USER_ID_QUERY_PARAM);

  if (impersonatedAccountId === null && impersonatedUserId === null) {
    const savedImpersonation = SessionStorage.getStorageKeyItem(IMPERSONATION_STORAGE_KEY);

    if (
      savedImpersonation &&
      savedImpersonation[IMPERSONATED_ACCOUNT_ID_KEY] &&
      savedImpersonation[IMPERSONATED_USER_ID_KEY]
    ) {
      impersonatedAccountId = savedImpersonation[IMPERSONATED_ACCOUNT_ID_KEY];
      impersonatedUserId = savedImpersonation[IMPERSONATED_USER_ID_KEY];
    }
  }

  return { impersonatedUserId, impersonatedAccountId };
}

export const checkIsImpersonating = (
  impersonatedUserId: string | null,
  impersonatedAccountId: string | null,
): boolean => {
  if (impersonatedUserId !== null && impersonatedAccountId !== null) {
    return true;
  }
  if (impersonatedUserId !== null || impersonatedAccountId !== null) {
    alertError(
      'Both impersonated user ID and impersonated account ID must be set for impersonation!',
    );
  }
  return false;
};

export const updateClaimsForImpersonation = (
  claims: Claims,
  impersonatedUserId: string,
  impersonatedAccountId: string,
) => {
  claims[config.userClaimsKey] = impersonatedUserId;
  claims[config.accountClaimsKey] = impersonatedAccountId;
};

export function saveImpersonationParams(impersonatedUserId: string, impersonatedAccountId: string) {
  SessionStorage.addStorageObject({
    [IMPERSONATION_STORAGE_KEY]: {
      [IMPERSONATED_ACCOUNT_ID_KEY]: impersonatedAccountId,
      [IMPERSONATED_USER_ID_KEY]: impersonatedUserId,
    },
  });
}

export function clearImpersonationParams(): URLSearchParams {
  SessionStorage.deleteStorageKey(IMPERSONATION_STORAGE_KEY);
  const queryParams = getURLSearchParams();
  queryParams.delete(IMPERSONATED_ACCOUNT_ID_QUERY_PARAM);
  queryParams.delete(IMPERSONATED_USER_ID_QUERY_PARAM);
  return queryParams;
}
