import React, { useState } from 'react';
import { Input } from 'semantic-ui-react';

import { Currency } from '@src/meta/types/billing/payment';

import { Discount } from '../style';
import { ISetAmountFactorPackages } from '../../../../../../../../../actions/billing';

type Props = {
  header: string;
  price: string;
  billingType: string;
  savings?: string;
  isOrderingOffer: boolean;
  asFactor?: boolean;
  amountFactorPackages: number;
  hasActivePaymentBillingInfo: boolean;
  setAmountFactorPackages:
    | ((amount: number) => ISetAmountFactorPackages)
    | ((amount: number) => void);
  currency: Currency;
};

const FrequencyCardContent = ({
  header,
  price,
  billingType,
  savings,
  isOrderingOffer,
  asFactor,
  amountFactorPackages,
  setAmountFactorPackages,
  hasActivePaymentBillingInfo,
  currency,
}: Props) => {
  const [inputVal, setInputVal] = useState(amountFactorPackages.toString());
  const handleFactorPackagesChange = (_: any, { value }: any) => {
    const num = Math.floor(Number(value));

    if (num <= 0) {
      setAmountFactorPackages(1);
      setInputVal('');
      return;
    }
    if (num > 100) {
      setAmountFactorPackages(100);
      setInputVal('100');
      return;
    }
    setInputVal(num.toString());
    setAmountFactorPackages(num);
  };

  return isOrderingOffer ? (
    <>
      <h4 className="Header">Custom offer</h4>
      <h3 className="Price">{price}</h3>
      <p className="Info">+ VAT</p>
    </>
  ) : (
    <>
      <h4 className="Header">{header}</h4>
      <h3 className="Price">
        {price} {asFactor === true ? 'per package' : ''}
      </h3>
      <p className="Info">+ VAT</p>
      {asFactor === true && (
        <>
          <p style={{ fontWeight: 'bold' }}>Amount of packages</p>
          <Input
            placeholder="Packages"
            value={inputVal}
            onChange={handleFactorPackagesChange}
            type="number"
            style={{ padding: '0 1.5rem', width: '50%', marginBottom: '0.75rem' }}
            disabled={hasActivePaymentBillingInfo}
          />
        </>
      )}
      <p className="Info">{billingType}</p>
      {savings && (
        <Discount>
          SAVE
          <br />
          {`${savings} ${currency.code}`}
        </Discount>
      )}
    </>
  );
};

export default React.memo(FrequencyCardContent);
