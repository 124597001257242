import { ErrorResponse, Boom } from '../api';

export enum PaypalEnv {
  SANDBOX = 'sandbox',
  PRODUCTION = 'production',
}

export type ErrorDetail = {
  field: string;
  issue: string;
};

export type ErrorDetails = ErrorDetail[];

export type PaypalPaymentError = Boom<ErrorDetails>;

export type PaypalErrorResponse = ErrorResponse<ErrorDetails>;
