// eslint-disable-next-line import/no-extraneous-dependencies
import { Dispatch } from 'redux';

import { CountryListType } from '@src/constants/countries';

import * as actionTypes from '../../constants/actionTypes';
import SentryUtils from '../../lib/sentry';
import { ThunkDispatch } from '../../meta/types/actions';
import { Payer, UserBillingInfoDto } from '../../meta/types/billing/billingInfo';
import { mapOrder, Order } from '../../meta/types/billing/order';
import { CartItemPayload } from '../../meta/types/billing/cart';
import {
  CountryDto,
  VatVerificationResponse,
  ExtendedVatResponse,
} from '../../meta/types/billing/vat';
import { HttpStatus } from '../../meta/types/http';
import { StoreState } from '../../meta/types/store';
import BillingInfoResource from '../../services/Dashboard/BillingInfoResource';
import CountryResource from '../../services/Dashboard/CountryResource';
import OrderResource from '../../services/Dashboard/OrderResource';
import SalesPackageResource from '../../services/Dashboard/SalesPackageResource';
import {
  SalesPackage,
  SalesPackageSelector,
  BILLING_PLAN_RETRY,
  BILLING_PLAN_MANAGEMENT_OFFER,
} from '../../meta/types/billing/salespackage';
import { CurrencyCode, Currency } from '../../meta/types/billing/payment';

export interface IConfirmPurchaseOrder {
  type: actionTypes.CONFIRM_PURCHASE_ORDER;
  order: Order;
}

export const confirmPurchaseOrder = (order: Order): IConfirmPurchaseOrder => ({
  type: actionTypes.CONFIRM_PURCHASE_ORDER,
  order,
});

export interface IUpdatePurchaseOrder {
  type: actionTypes.UPDATE_PURCHASE_ORDER;
  order: Order;
}

export const updatePurchaseOrder = (order: Order): IUpdatePurchaseOrder => ({
  type: actionTypes.UPDATE_PURCHASE_ORDER,
  order,
});

export interface ICloseManagementOrderPopup {
  type: actionTypes.CLOSE_MANAGEMENT_ORDER_POPUP;
}

export const closeManagementOrderPopup = (): ICloseManagementOrderPopup => ({
  type: actionTypes.CLOSE_MANAGEMENT_ORDER_POPUP,
});

export interface ISetManagementIssuedOrderFetching {
  type: actionTypes.SET_MANAGEMENT_ISSUED_ORDER_FETCHING;
  fetching: boolean;
}

export const setManagementIssuedOrderFetching = (
  fetching: boolean,
): ISetManagementIssuedOrderFetching => ({
  type: actionTypes.SET_MANAGEMENT_ISSUED_ORDER_FETCHING,
  fetching,
});

export interface ISetManagementIssuedOrder {
  type: actionTypes.SET_MANAGEMENT_ISSUED_ORDER;
  order?: Order;
}

export const setManagementIssuedOrder = (order: Order | undefined): ISetManagementIssuedOrder => ({
  type: actionTypes.SET_MANAGEMENT_ISSUED_ORDER,
  order,
});

export const getManagementIssuedOrder = (userId: string) => {
  return async (dispatch: Dispatch, getState: () => StoreState): Promise<Order | undefined> => {
    dispatch(setManagementIssuedOrderFetching(true));
    try {
      const resp = await OrderResource.getManagementOffer({ userId });
      const order = mapOrder(resp);
      dispatch(setManagementIssuedOrder(order));
      return order;
    } catch (apiError) {
      dispatch(setManagementIssuedOrderFetching(false));
      if (apiError.status !== HttpStatus.NOT_FOUND) {
        const error = new Error(apiError.message);
        SentryUtils.captureAPIError(apiError, error.message);
      }
      return undefined;
    }
  };
};

export interface IUpdateUserBillingInfo {
  type: actionTypes.UPDATE_USER_BILLING_INFO;
  userBillingInfo: UserBillingInfoDto;
}

export const updateUserBillingInfo = (
  userBillingInfo: UserBillingInfoDto,
): IUpdateUserBillingInfo => ({
  type: actionTypes.UPDATE_USER_BILLING_INFO,
  userBillingInfo,
});

export interface IRemoveUserBillingInfo {
  type: actionTypes.REMOVE_USER_BILLING_INFO;
  id: number;
}

export const removeUserBillingInfo = (id: number): IRemoveUserBillingInfo => ({
  type: actionTypes.REMOVE_USER_BILLING_INFO,
  id,
});

export interface IAddUserBillingInfo {
  type: actionTypes.ADD_USER_BILLING_INFO;
  userBillingInfo: UserBillingInfoDto;
}

export const addUserBillingInfo = (userBillingInfo: UserBillingInfoDto): IAddUserBillingInfo => ({
  type: actionTypes.ADD_USER_BILLING_INFO,
  userBillingInfo,
});

export interface ISetUserBillingInfo {
  type: actionTypes.SET_USER_BILLING_INFO;
  userBillingInfo: UserBillingInfoDto[];
}

export const setUserBillingInfo = (userBillingInfo: UserBillingInfoDto[]): ISetUserBillingInfo => ({
  type: actionTypes.SET_USER_BILLING_INFO,
  userBillingInfo,
});

export interface ISetUserBillingInfoFetching {
  type: actionTypes.SET_USER_BILLING_INFO_FETCHING;
  fetching: boolean;
}

const setUserBillingInfoFetching = (fetching: boolean): ISetUserBillingInfoFetching => ({
  type: actionTypes.SET_USER_BILLING_INFO_FETCHING,
  fetching,
});

export const fetchUserBillingInfo = () => {
  return async (
    dispatch: ThunkDispatch,
    getState: () => StoreState,
  ): Promise<UserBillingInfoDto[]> => {
    const userId = getState().auth.configurableUser!.id;
    dispatch(setUserBillingInfoFetching(true));
    const resp = await BillingInfoResource.listBillingInfo({ userId });
    dispatch(setUserBillingInfo(resp.member));

    return Promise.resolve(resp.member);
  };
};

export interface IAddToCart {
  type: actionTypes.ADD_TO_CART;
  item: CartItemPayload;
}

export const addToCart = (item: CartItemPayload): IAddToCart => ({
  type: actionTypes.ADD_TO_CART,
  item,
});

export interface IRemoveFromCart {
  type: actionTypes.REMOVE_FROM_CART;
  id: string;
}

export const removeFromCart = (id: string): IRemoveFromCart => ({
  type: actionTypes.REMOVE_FROM_CART,
  id,
});

export interface ISetRetryOrder {
  type: actionTypes.SET_RETRY_ORDER;
  order?: Order;
}

export const setRetryOrder = (order: Order): ISetRetryOrder => ({
  type: actionTypes.SET_RETRY_ORDER,
  order,
});

// ------------ Subscription plans ------------

export interface ISetFetchingSubscriptionPlans {
  type: actionTypes.SET_FETCHING_SUBSCRIPTION_PLANS;
  fetching: boolean;
}

const setFetchingSubscriptionPlans = (fetching: boolean): ISetFetchingSubscriptionPlans => ({
  type: actionTypes.SET_FETCHING_SUBSCRIPTION_PLANS,
  fetching,
});

export interface ISetSubscriptionPlans {
  type: actionTypes.SET_SUBSCRIPTION_PLANS;
  plans: SalesPackage[];
}

const setSubscriptionPlans = (plans: SalesPackage[]): ISetSubscriptionPlans => ({
  type: actionTypes.SET_SUBSCRIPTION_PLANS,
  plans,
});

export const fetchSubscriptionPlans = () => {
  return async (dispatch: ThunkDispatch, getState: () => StoreState): Promise<SalesPackage[]> => {
    const billingState = getState().billing;

    if (
      billingState.salesPackages.some(
        p => p.planId !== BILLING_PLAN_RETRY && p.planId !== BILLING_PLAN_MANAGEMENT_OFFER,
      )
    ) {
      return billingState.salesPackages;
    }

    dispatch(setFetchingSubscriptionPlans(true));
    const plans = await SalesPackageResource.getPlans();
    dispatch(setSubscriptionPlans(plans));
    return Promise.resolve(plans);
  };
};

export const getSubscriptionPlanById = (planId: number) => {
  return (_: Dispatch, getState: () => StoreState): SalesPackage => {
    const { salesPackages } = getState().billing;
    return salesPackages.find(p => p.id === planId)!;
  };
};

export interface ISetClientPackage {
  type: actionTypes.SET_CLIENT_PACKAGE;
  clientPackage: SalesPackage;
}

export const addAndSetClientPackage = (clientPackage: SalesPackage): ISetClientPackage => ({
  type: actionTypes.SET_CLIENT_PACKAGE,
  clientPackage,
});

export interface ISetSupportedCurrencies {
  type: actionTypes.SET_SUPPORTED_CURRENCIES;
  currencies: Currency[];
}

export const setSupportedCurrencies = (currencies: Currency[]): ISetSupportedCurrencies => ({
  type: actionTypes.SET_SUPPORTED_CURRENCIES,
  currencies,
});

export interface ISetSelectedCurrency {
  type: actionTypes.SET_SELECTED_CURRENCY;
  currency: CurrencyCode;
  isInitialSelect: boolean | undefined;
}

export const setSelectedCurrency = (
  currency: CurrencyCode,
  isInitialSelect?: boolean,
): ISetSelectedCurrency => ({
  type: actionTypes.SET_SELECTED_CURRENCY,
  isInitialSelect,
  currency,
});

export interface ISetCountriesFetching {
  type: actionTypes.SET_COUNTRIES_FETCHING;
  fetching: boolean;
}

const setCountriesFetching = (fetching: boolean): ISetCountriesFetching => ({
  type: actionTypes.SET_COUNTRIES_FETCHING,
  fetching,
});

export interface ISetCountries {
  type: actionTypes.SET_COUNTRIES;
  countries: CountryDto[];
  listType: CountryListType;
}

const setCountries = (listType: CountryListType, countries: CountryDto[]): ISetCountries => ({
  type: actionTypes.SET_COUNTRIES,
  listType,
  countries,
});

export const getCountries = (listType: CountryListType) => {
  return async (dispatch: Dispatch, getState: () => StoreState) => {
    const billingState = getState().billing;

    if (listType in billingState.countries) {
      return Promise.resolve();
    }

    dispatch(setCountriesFetching(true));
    const resp = await CountryResource.list({
      listType,
    });
    dispatch(setCountries(listType, resp.member));
    dispatch(setCountriesFetching(false));

    return Promise.resolve();
  };
};

// ------------ Purchase orders ------------

export interface ISetPurchaseOrdersFetching {
  type: actionTypes.SET_PURCHASE_ORDERS_FETCHING;
  fetching: boolean;
}

export const setPurchaseOrdersFetching = (fetching: boolean): ISetPurchaseOrdersFetching => ({
  type: actionTypes.SET_PURCHASE_ORDERS_FETCHING,
  fetching,
});

export interface IAddPurchaseOrder {
  type: actionTypes.ADD_PURCAHSE_ORDER;
  order: Order;
}

export const addPurchaseOrder = (order: Order): IAddPurchaseOrder => ({
  type: actionTypes.ADD_PURCAHSE_ORDER,
  order,
});

export interface ISetPurchaseOrders {
  type: actionTypes.SET_PURCHASE_ORDERS;
  orders: Order[];
}

export const setPurchaseOrders = (orders: Order[]): ISetPurchaseOrders => ({
  type: actionTypes.SET_PURCHASE_ORDERS,
  orders,
});

export interface ICancelPaypalOrder {
  type: actionTypes.CANCEL_PAYPAL_ORDER;
  paypalPaymentId: string;
}

const cancelPaypalOrderData = (paypalPaymentId: string): ICancelPaypalOrder => ({
  type: actionTypes.CANCEL_PAYPAL_ORDER,
  paypalPaymentId,
});

export const cancelPaypalOrder = (paypalPaymentId: string) => {
  return async (dispatch: ThunkDispatch, getState: () => StoreState): Promise<void> => {
    dispatch(cancelPaypalOrderData(paypalPaymentId));
    const billingState = getState().billing;

    if (billingState.userBillingInfo.length === 0) {
      fetchUserBillingInfo()(dispatch, getState);
    }
  };
};

export const fetchOrders = (userId: string) => {
  return async (dispatch: ThunkDispatch) => {
    dispatch(setPurchaseOrdersFetching(true));
    const resp = await OrderResource.getOrders({ userId });
    const orders = resp.member.map(mapOrder);
    dispatch(setPurchaseOrders(orders));
  };
};

// ------------ Billing flow ------------

export interface ISetActiveSalesPackage {
  type: actionTypes.SET_ACTIVE_SALES_PACKAGE;
  selector?: SalesPackageSelector;
}

export const setActiveSalesPackage = (
  selector: SalesPackageSelector | undefined,
): ISetActiveSalesPackage => ({
  type: actionTypes.SET_ACTIVE_SALES_PACKAGE,
  selector,
});

export interface ISetPayerType {
  type: actionTypes.SET_PAYER_TYPE;
  payerType?: Payer;
}

export const setPayerType = (payerType: Payer | undefined): ISetPayerType => ({
  type: actionTypes.SET_PAYER_TYPE,
  payerType,
});

export interface ISetPaymentCountry {
  type: actionTypes.SET_PAYMENT_COUNTRY;
  paymentCountry: string;
}

export const setPaymentCountry = (paymentCountry: string): ISetPaymentCountry => ({
  type: actionTypes.SET_PAYMENT_COUNTRY,
  paymentCountry,
});

export interface ISetPayerVatRegistered {
  type: actionTypes.SET_PAYER_VAT_REGISTERED;
  vatRegistered: boolean;
}

export const setPayerVatRegistered = (vatRegistered: boolean): ISetPayerVatRegistered => ({
  type: actionTypes.SET_PAYER_VAT_REGISTERED,
  vatRegistered,
});

export interface ISetPayerVatNumber {
  type: actionTypes.SET_PAYER_VAT_NUMBER;
  vatNumber: string;
}

export const setPayerVatNumber = (vatNumber: string): ISetPayerVatNumber => ({
  type: actionTypes.SET_PAYER_VAT_NUMBER,
  vatNumber,
});

export interface ISetVatVerificationResponse {
  type: actionTypes.SET_VAT_VERIFICATION_RESPONSE;
  vatVerification: VatVerificationResponse;
}

export const setVatVerificationResponse = (
  vatVerification: VatVerificationResponse,
): ISetVatVerificationResponse => ({
  type: actionTypes.SET_VAT_VERIFICATION_RESPONSE,
  vatVerification,
});

export interface ISetPaymentBillingInfo {
  type: actionTypes.SET_PAYMENT_BILLING_INFO;
  userBillingInfo?: UserBillingInfoDto;
}

export const setPaymentBillingInfo = (
  userBillingInfo: UserBillingInfoDto | undefined,
): ISetPaymentBillingInfo => ({
  type: actionTypes.SET_PAYMENT_BILLING_INFO,
  userBillingInfo,
});

export interface ISetSelectedUserBillingInfo {
  type: actionTypes.SET_SELECTED_USER_BILLING_INFO;
  id: number | undefined;
}

export const setSelectedUserBillingInfo = (
  id: number | undefined,
): ISetSelectedUserBillingInfo => ({
  type: actionTypes.SET_SELECTED_USER_BILLING_INFO,
  id,
});

export interface ISetVatCalculations {
  type: actionTypes.SET_VAT_CALCULATIONS;
  vatCalculations: ExtendedVatResponse;
}

export const setVatCalculations = (vatCalculations: ExtendedVatResponse): ISetVatCalculations => ({
  type: actionTypes.SET_VAT_CALCULATIONS,
  vatCalculations,
});

export interface ISetPurchasingManagementIssuedOrder {
  type: actionTypes.SET_PURCHASING_MANAGEMENT_ISSUED_ORDER;
  purchasingManagementIssuedOrder: boolean;
}

export const setPurchasingManagementIssuedOrder = (
  purchasingManagementIssuedOrder: boolean,
): ISetPurchasingManagementIssuedOrder => ({
  type: actionTypes.SET_PURCHASING_MANAGEMENT_ISSUED_ORDER,
  purchasingManagementIssuedOrder,
});

export interface ISetAmountFactorPackages {
  type: actionTypes.SET_AMOUNT_FACTOR_PACKAGES;
  amount: number;
}

export const setAmountFactorPackages = (amount: number): ISetAmountFactorPackages => ({
  type: actionTypes.SET_AMOUNT_FACTOR_PACKAGES,
  amount,
});
