import React from 'react';
import { Button, ButtonProps } from 'semantic-ui-react';
import config from '@config/config';

const PrimaryButton = (props: ButtonProps) => {
  return (
    <Button
      type="submit"
      {...props}
      style={{
        ...props.style,
        color: 'black',
        backgroundColor: config.primaryColor,
        borderRadius: 0,
        border: `1px solid ${config.primaryColor}`,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
      }}
    />
  );
};

export default PrimaryButton;
