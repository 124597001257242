const LOCAL_STORAGE_IDENTIFIER = 'sh_dashboard';
const DEFAULT_STORAGE_STATE = '{}';

abstract class Storage {
  abstract getInterface(): globalThis.Storage;

  getAndDeleteStorageKeyItem = (key: string) => {
    const item = this.getStorageKeyItem(key);
    this.deleteStorageKey(key);
    return item;
  };

  getStorageKeyItem = (key: string) => {
    return this.getStorageObject()[key];
  };

  getStorageObject = () => {
    return JSON.parse(
      this.getInterface().getItem(LOCAL_STORAGE_IDENTIFIER) || DEFAULT_STORAGE_STATE,
    );
  };

  addStorageObject = (obj: object) => {
    const currentObject = this.getStorageObject();
    this.getInterface().setItem(
      LOCAL_STORAGE_IDENTIFIER,
      JSON.stringify({ ...currentObject, ...obj }),
    );
  };

  setStorageObject = (obj: object) => {
    this.getInterface().setItem(LOCAL_STORAGE_IDENTIFIER, JSON.stringify(obj));
  };

  setStorageItem = (key: string, item: object) => {
    const object = this.getStorageObject();
    object[key] = item;
    this.setStorageObject(object);
  };

  deleteStorageKey = (key: string) => {
    const storageItem = this.getStorageObject();
    delete storageItem[key];
    this.setStorageObject(storageItem);
  };
}

export default Storage;
