export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
}

export enum HttpStatus {
  NOT_FOUND = 404,
  SERVER_ERROR = 500,
  BAD_REQUEST = 400,
}
