import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, List, SemanticICONS } from 'semantic-ui-react';

import { SidebarContext } from '..';
import { slugify } from '../../../../meta/types/wms/Layer';
import { StyledSidebarItem } from '../../../../styles/Sidebar';
import Popup from '../../../common/Popup';
import TargetBlankLink from '../../../common/TargetBlankLink';
import { SidebarListItemContent } from './style';

type Props = {
  content?: string;
  icon: SemanticICONS;
  circularIcon?: boolean;
  additionalIcon?: any;
  active: boolean;
  to?: string;
  externalLink?: string;
  dropdownMenu?: any;
  onClick?: () => void;
  popupContent?: any;
  disabled?: boolean;
};

const SidebarListItem = ({
  content,
  icon,
  circularIcon,
  additionalIcon,
  active,
  to,
  externalLink,
  dropdownMenu,
  onClick,
  popupContent,
  disabled = false,
}: Props) => {
  const ItemContent = (
    <SidebarContext.Consumer>
      {({ sidebarCollapsed }) => {
        const C = (
          <SidebarListItemContent active={active && !disabled} disabled={disabled}>
            <span>
              {additionalIcon === undefined ? (
                <List.Icon
                  name={icon}
                  circular={circularIcon}
                  className={sidebarCollapsed ? 'collapsed' : ''}
                />
              ) : (
                <i className={`icons ${sidebarCollapsed ? 'collapsed' : ''}`}>
                  <i className={`icon ${icon}`} />
                  {additionalIcon}
                </i>
              )}
            </span>
            {!sidebarCollapsed ? (
              <List.Content style={{ paddingLeft: '8px' }}>{content}</List.Content>
            ) : null}
          </SidebarListItemContent>
        );
        if (!popupContent) {
          return C;
        }
        return <Popup trigger={C} content={popupContent} position="right center" basic={false} />;
      }}
    </SidebarContext.Consumer>
  );

  return (
    <StyledSidebarItem
      active={active}
      className="sidebar-list__item"
      data-testid={getTestId(to, content)}
    >
      {to !== undefined && !disabled ? (
        <Link to={to}>{ItemContent}</Link>
      ) : externalLink !== undefined && !disabled ? (
        <TargetBlankLink href={externalLink}>{ItemContent}</TargetBlankLink>
      ) : dropdownMenu !== undefined && !disabled ? (
        <Dropdown
          icon={null}
          style={{ width: '100%' }}
          pointing="bottom left"
          trigger={<span onClick={onClick}>{ItemContent}</span>}
        >
          {dropdownMenu}
        </Dropdown>
      ) : (
        <span onClick={onClick}>{ItemContent}</span>
      )}
    </StyledSidebarItem>
  );
};

function getTestId(to?: string, content?: string): string {
  return to ? `nav${to}` : `nav/${slugify(content || '')}`;
}

export default React.memo(SidebarListItem);
