import config from '@config/config';
import {
  AWS_EU_CENTRAL_1,
  AWS_US_WEST_2,
  CREODIAS,
  SGS_HQ,
  CODE_DE,
  EO_LAB,
  GCP_US_CENTRAL_1,
} from '@src/meta/types/byoc/const';
import { Environment } from '@src/config/baseAppConfig';

export const BYOC_LOCATION_CONFIG: { [s: string]: string | undefined } = {
  [AWS_EU_CENTRAL_1]: config.byocEuCentralServiceBasePath,
  [AWS_US_WEST_2]: config.byocUsWestServiceBasePath,
  [CREODIAS]: config.byocCreodiasServiceBasePath,
  ...(config.environment === Environment.PRODUCTION
    ? {}
    : {
        [SGS_HQ]: config.byocSgsHqServiceBasePath,
        [GCP_US_CENTRAL_1]: config.byocGcpUsCentralStageBasePath,
      }),
};

export const ALL_LOCATIONS_CONFIG: { [s: string]: string | undefined } = {
  [AWS_EU_CENTRAL_1]: config.byocEuCentralServiceBasePath,
  [AWS_US_WEST_2]: config.byocUsWestServiceBasePath,
  [CREODIAS]: config.byocCreodiasServiceBasePath,
  [CODE_DE]: config.byocCodeDeServiceBasePath,
  [SGS_HQ]: config.byocSgsHqServiceBasePath,
  [EO_LAB]: config.byocCodeDeServiceBasePath,
  [GCP_US_CENTRAL_1]: config.byocGcpUsCentralStageBasePath,
};

export const ZARR_LOCATION_CONFIG: { [s: string]: string | undefined } = {
  [AWS_EU_CENTRAL_1]: config.byocEuCentralServiceBasePath,
  ...(config.environment === Environment.PRODUCTION
    ? {}
    : { [GCP_US_CENTRAL_1]: config.byocGcpUsCentralStageBasePath }),
};
