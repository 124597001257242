import React, { SyntheticEvent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dropdown, Icon, List } from 'semantic-ui-react';

import {
  setStatisticsTimeRange,
  setArchiveTimeRange,
  setUsingArchiveTimeRange,
} from '@src/actions/dashboard';
import Popup from '@src/components/common/Popup';
import { DropDownType } from '@src/constants/datasources';
import {
  ArchiveTimeRange,
  ArchiveTimeRangePayload,
  QuickTimeRange,
} from '@src/meta/types/requests-statistics';
import { StoreState } from '@src/meta/types/store';
import { styled } from '@src/styles/Theme';
import SecondaryButton from '@src/components/common/buttons/SecondaryButton';
import config from '@config/config';
import FlexAlignedStart from '@src/components/common/flex/FlexAligned';

type PropsFromState = {
  selectedTimeRange: QuickTimeRange;
  archiveTimeRange: ArchiveTimeRange;
};

type PropsFromDispatch = {
  setStatisticsTimeRange: (timeRange: QuickTimeRange) => void;
  setArchiveTimeRange: (archiveTimeRange: ArchiveTimeRangePayload) => void;
  setUsingArchiveTimeRange: (isUsingArchive: boolean) => void;
};

type Properties = {
  showArchive?: boolean;
};

type Props = PropsFromState & PropsFromDispatch & Properties;

const CURRENT_YEAR_STRING = new Date().getFullYear().toString();
const CURRENT_MONTH_NUMBER = new Date().getMonth();

const TimeRangePicker = ({
  selectedTimeRange,
  archiveTimeRange,
  setStatisticsTimeRange,
  setArchiveTimeRange,
  setUsingArchiveTimeRange,
  showArchive,
}: Props) => {
  if (showArchive !== true && selectedTimeRange.oldValue === null) {
    setStatisticsTimeRange(AVAILABLE_TIME_RANGES[2]);
  }

  const monthsText = 'Months';
  const selectedMonthText = archiveTimeRange.month
    ? months[Number(archiveTimeRange.month)].name
    : monthsText;
  const yearsText = 'Years';
  const selectedYear = archiveTimeRange.year ?? yearsText;

  const handleYearChange = (_: SyntheticEvent, { value }: any) => {
    const availableMonths = getAvailableMonths(value);
    if (
      availableMonths.find(m => m.name === selectedMonthText) === undefined &&
      availableMonths.length > 0
    ) {
      setArchiveTimeRange({ month: availableMonths[0].idx });
    }
    setArchiveTimeRange({ year: value });
  };
  const handleMonthsChange = (_: SyntheticEvent, { value }: any) => {
    setArchiveTimeRange({ month: value });
  };

  useEffect(() => {
    if (
      selectedMonthText !== monthsText &&
      selectedYear !== yearsText &&
      archiveTimeRange.isSelected
    ) {
      setStatisticsTimeRange({
        value: `${archiveTimeRange.month}-${selectedYear}`,
        oldValue: null,
        text: `${selectedMonthText} of ${selectedYear}`,
        resolution: 'DAILY',
      });
    }
  }, [selectedMonthText, selectedYear, setStatisticsTimeRange, archiveTimeRange]);

  const styleFunction = (predicate: boolean) => (predicate ? { textDecoration: 'underline' } : {});
  const archiveButtonStyle = showArchive ? { color: config.primaryTextColor } : {};

  return (
    <DropdownStyles>
      <FlexAlignedStart className="buttons-container">
        {AVAILABLE_TIME_RANGES.map(tr => (
          <SecondaryButton
            key={tr.value}
            onClick={() => {
              setUsingArchiveTimeRange(false);
              setStatisticsTimeRange(tr);
            }}
            active={tr.value === selectedTimeRange.value}
            size={showArchive ? 'medium' : 'tiny'}
            style={{ margin: '0.25rem', ...archiveButtonStyle }}
          >
            {tr.text}
          </SecondaryButton>
        ))}
        {showArchive ? (
          <div
            style={{
              border: `1px solid ${config.primaryColor}`,
              padding: '0.2rem',
              height: '38px',
              display: 'flex',
              alignItems: 'center',
              margin: '0.25rem',
              ...archiveButtonStyle,
            }}
          >
            <Dropdown
              className="dropdown"
              compact
              header={monthsText}
              onChange={handleMonthsChange}
              options={getDropDownItems(selectedYear)}
              trigger={
                <span style={styleFunction(selectedTimeRange.oldValue === null)}>
                  {selectedMonthText}
                </span>
              }
              style={{ marginLeft: '0.5rem' }}
            />

            <Dropdown
              compact
              header={yearsText}
              trigger={
                <span style={styleFunction(selectedTimeRange.oldValue === null)}>
                  {selectedYear}
                </span>
              }
              onChange={handleYearChange}
              options={getArchiveYears()}
            />

            {!archiveTimeRange.isSelected ? (
              <SecondaryButton
                size="mini"
                icon="refresh"
                disabled={selectedMonthText === monthsText || selectedYear === yearsText}
                onClick={() => setUsingArchiveTimeRange(true)}
                style={archiveButtonStyle}
              />
            ) : null}
          </div>
        ) : null}
        <Popup
          trigger={
            <List.Item style={{ marginLeft: '0.5rem' }}>
              <Icon name="info circle" />
            </List.Item>
          }
          content="Requests statistics may sometimes be delayed and not 100% accurate."
        />
      </FlexAlignedStart>
    </DropdownStyles>
  );
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
].map((month, idx) => ({
  name: month,
  idx: idx.toString(),
}));

const getAvailableMonths = (year: string) => {
  const dateYear = year !== 'Years' ? new Date(year) : new Date(new Date().getFullYear(), 0, 1);
  // Aggregations available from Oct 2019.
  if (dateYear <= new Date('2019')) {
    return months.slice(9, 12);
  }
  if (year === CURRENT_YEAR_STRING) {
    return months.slice(0, CURRENT_MONTH_NUMBER + 1);
  }
  return months;
};
const getDropDownItems = (year: string) => {
  return getAvailableMonths(year).map(val => ({
    value: val.idx,
    text: val.name,
    key: val.idx,
  }));
};
const getArchiveYears = (): DropDownType[] => {
  const start = 2019;
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = start; i <= currentYear; i += 1) {
    const val = i.toString();
    years.push({
      text: val,
      value: val,
      key: val,
    });
  }
  return years;
};

export const AVAILABLE_TIME_RANGES: QuickTimeRange[] = [
  { value: 'HOURS-1', oldValue: 'now-1h', text: 'Last 1 hour', resolution: 'HOURLY' },
  { value: 'HOURS-12', oldValue: 'now-12h', text: 'Last 12 hours', resolution: 'HOURLY' },
  { value: 'HOURS-24', oldValue: 'now-24h', text: 'Last 24 hours', resolution: 'HOURLY' },
  { value: 'DAYS-7', oldValue: 'now-7d', text: 'Last 7 days', resolution: 'DAILY' },
  { value: 'DAYS-31', oldValue: 'now-31d', text: 'Last 31 days', resolution: 'DAILY' },
];

function mapStateToProps(state: StoreState): PropsFromState {
  return {
    selectedTimeRange: state.dashboard.selectedTimeRange,
    archiveTimeRange: state.dashboard.archiveTimeRange,
  };
}

const mapDispatchToProps: PropsFromDispatch = {
  setStatisticsTimeRange,
  setArchiveTimeRange,
  setUsingArchiveTimeRange,
};

const enhance = connect(mapStateToProps, mapDispatchToProps);

export default enhance(TimeRangePicker);

const DropdownStyles = styled.div`
  .dropdown {
    margin-right: 1rem;
  }

  .segment-item {
    margin-left: 0 !important;

    .content > div.ui.segment {
      border: 1px solid ${config.primaryColor};
      border-radius: 0;
      background-color: transparent;
      color: ${config.secondaryColor};
      font-weight: normal;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      height: 39px;
    }
  }

  .segment {
    cursor: auto;
  }

  .buttons-container {
    flex-wrap: wrap;
    margin: -0.25rem;

    @media (max-width: 600px) {
      button {
        font-size: 0.75rem;
      }
    }
  }
`;
