import config from '@config/config';
import { BYOC_LOCATION_CONFIG } from '@modules/collections/components/const';

import { Dataset, DatasetId } from '../../meta/types/wms/Dataset';
import { Layer, isUsWestDem } from '../../meta/types/wms/Layer';
import { ByocCollectionsCache, locationToBasePath } from '../../meta/types/byoc';
import { AWS_EU_CENTRAL_1_COLLECTIONS, CODEDE_COLLECTIONS, CREODIAS_COLLECTIONS } from './const';

export const AWS_US_WEST_2_COLLECTIONS = new Set([
  DatasetId.L8L1C,
  DatasetId.MODIS,
  DatasetId.DEM,
  DatasetId.BYOC,
  DatasetId.LOTL1,
  DatasetId.LOTL2,
  DatasetId.LTML1,
  DatasetId.LTML2,
  DatasetId.LETML1,
  DatasetId.LETML2,
  DatasetId.LMSSL1,
  DatasetId.HLS,
]);

export const filterBySet = (
  supportedCollections: Set<DatasetId>,
  collections: Dataset[],
): Dataset[] => {
  return collections.filter(dataset => supportedCollections.has(dataset.id));
};

export const filterCodeDEDatasets = (collections: Dataset[]): Dataset[] =>
  filterBySet(CODEDE_COLLECTIONS, collections);

export const filterCreoDatasets = (collections: Dataset[]): Dataset[] =>
  filterBySet(
    new Set([
      ...Array.from(CREODIAS_COLLECTIONS),
      ...Array.from(AWS_EU_CENTRAL_1_COLLECTIONS),
      ...Array.from(AWS_US_WEST_2_COLLECTIONS),
    ]),
    collections,
  );

export const getEndpointUrl = (
  layer: Layer,
  byocCollectionsCache: ByocCollectionsCache,
  defaultEndpoint?: string,
) => {
  const layerDataset = layer.datasourceDefaults.type;
  // DEM is handled depending on the demInstance selected (MAPZEN = us-west, COP = eu-central)
  if (layerDataset === DatasetId.DEM) {
    const { demInstance } = layer.datasourceDefaults;
    if (isUsWestDem(demInstance)) {
      return config.usWestWmsServiceBasePath;
    }
    return config.wmsConfigurationServiceBasePath;
  }
  if (layerDataset === DatasetId.BYOC && byocCollectionsCache !== undefined) {
    const collId = layer.datasourceDefaults.collectionId!;
    const location = byocCollectionsCache[collId]?.collection.location;
    if (location === undefined && defaultEndpoint) {
      return defaultEndpoint;
    }
    return locationToBasePath(BYOC_LOCATION_CONFIG)[location];
  }
  if (AWS_EU_CENTRAL_1_COLLECTIONS.has(layerDataset)) {
    return config.wmsConfigurationServiceBasePath;
  }
  if (AWS_US_WEST_2_COLLECTIONS.has(layerDataset)) {
    return config.usWestWmsServiceBasePath;
  }
  if (CREODIAS_COLLECTIONS.has(layerDataset)) {
    return config.byocCreodiasServiceBasePath;
  }
};

const getExtAuthEndpointUrl = (
  layer: Layer,
  currentAuthCollections: Set<DatasetId> | undefined,
  defaultEndpoint: string,
  byocCollectionsCache: ByocCollectionsCache,
): string | undefined => {
  const { type } = layer.datasourceDefaults;
  if (currentAuthCollections !== undefined && currentAuthCollections.has(type)) {
    return defaultEndpoint;
  }
  return getEndpointUrl(layer, byocCollectionsCache, defaultEndpoint);
};

const doLayersUseSameSource = (
  layers: Layer[],
  currentAuthCollections: Set<DatasetId> | undefined,
  defaultEndpoint: string,
  byocCollectionsCache: ByocCollectionsCache,
): boolean => {
  const layerUrl = getExtAuthEndpointUrl(
    layers[0],
    currentAuthCollections,
    defaultEndpoint,
    byocCollectionsCache,
  );
  return layers.every(
    layer =>
      getExtAuthEndpointUrl(
        layer,
        currentAuthCollections,
        defaultEndpoint,
        byocCollectionsCache,
      ) === layerUrl,
  );
};

export const getServiceEndpoint = (
  layers: Layer[] | undefined,
  currentAuthCollections: Set<DatasetId> | undefined,
  defaultEndpoint: string,
  byocCollectionsCache: ByocCollectionsCache,
) => {
  if (layers === undefined || layers.length === 0) {
    return defaultEndpoint;
  }
  if (
    doLayersUseSameSource(layers, currentAuthCollections, defaultEndpoint, byocCollectionsCache)
  ) {
    return getExtAuthEndpointUrl(
      layers[0],
      currentAuthCollections,
      defaultEndpoint,
      byocCollectionsCache,
    );
  }
};
