import { Claims, SentinelUser } from '@modules/auth/components/types/user';
import Auth from '@modules/auth/components/lib/auth';

import * as actionTypes from '../../constants/actionTypes';

export interface ILogout {
  type: actionTypes.LOGOUT;
}

export const localLogout = (): ILogout => ({
  type: actionTypes.LOGOUT,
});

export const logout = () => {
  return Auth.logout();
};

export interface ISetConfigurableUser {
  type: actionTypes.SET_CONFIGURABLE_USER;
  user: SentinelUser;
}

export const setConfigurableUser = (user: SentinelUser): ISetConfigurableUser => ({
  type: actionTypes.SET_CONFIGURABLE_USER,
  user,
});

export interface ISetTokenChecked {
  type: actionTypes.SET_TOKEN_CHECKED;
  tokenChecked: boolean;
}

export interface ISetUserAlreadyHadTrial {
  type: actionTypes.SET_USER_ALREADY_HAD_TRIAL;
  alreadyHadTrial: boolean;
}

export const setUserAlreadyHadTrial = (alreadyHadTrial: boolean): ISetUserAlreadyHadTrial => ({
  type: actionTypes.SET_USER_ALREADY_HAD_TRIAL,
  alreadyHadTrial,
});

export const setTokenChecked = (tokenChecked: boolean): ISetTokenChecked => ({
  type: actionTypes.SET_TOKEN_CHECKED,
  tokenChecked,
});

export interface ISetLoggedIn<UserType, ClaimsType> {
  type: actionTypes.SET_LOGGED_IN;
  user: UserType;
  impersonatorClaims?: ClaimsType;
  rootManaging: boolean;
}

export function setLoggedIn<UserType = SentinelUser, ClaimsType = Claims>(
  user: UserType,
  impersonatorClaims: ClaimsType | undefined,
  rootManaging: boolean,
): ISetLoggedIn<UserType, ClaimsType> {
  return {
    type: actionTypes.SET_LOGGED_IN,
    user,
    impersonatorClaims,
    rootManaging,
  };
}
