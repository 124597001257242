import React from 'react';
import { Button } from 'semantic-ui-react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { clearImpersonationParams } from '@modules/auth/components/lib/impersonation';
import config from '@config/config';

export type Props = {
  isImpersonating: boolean;
};

const StopImpersonatingButton = ({
  isImpersonating,
  history,
  location,
}: Props & RouteComponentProps) => {
  function stopImpersonating() {
    const queryParams = clearImpersonationParams();
    history.replace({ pathname: location.pathname, search: queryParams.toString() });
    window.location.reload();
  }

  if (isImpersonating) {
    return (
      <li>
        <Button
          type="submit"
          content="Stop Impersonating"
          onClick={stopImpersonating}
          style={{
            backgroundColor: config.toastErrorColor,
            color: config.terciaryTextColor,
            borderRadius: 0,
            border: `1px solid ${config.primaryColor}`,
          }}
        />
      </li>
    );
  }

  return null;
};

export default withRouter(StopImpersonatingButton);
