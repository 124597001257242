import React, { useState, useMemo } from 'react';
import moment, { Moment } from 'moment-timezone';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { SalesPackage } from '@src/meta/types/billing/salespackage';
import { addAndSetClientPackage } from '@src/actions/billing';
import BaseModalContainer from '@src/components/common/BaseModal';
import PrimaryButton from '@src/components/common/buttons/PrimaryButton';
import DatePickerContainer from '@src/components/common/DatePicker';
import SecondaryButton from '@src/components/common/buttons/SecondaryButton';
import ErrorBanner from '@src/components/common/banners/ErrorMessage';
import WarningMessage from '@src/components/common/banners/WarningMessage';
import InfoMessage from '@src/components/common/banners/InfoMessage';
import { PrimaryBlankLink } from '@src/components/common/links';
import SettingsModal from '../../../Checkout/SettingsModals';

type CoTermProps = {
  plan: SalesPackage;
  addAndSetClientPackage: (clientPackage: SalesPackage) => void;
  disabled: boolean;
};
enum CoTermDateStatus {
  '1_YEAR_AGO',
  '9_MONTHS_AGO',
  'VALID',
}

export const TOP_UP_QUOTA_SUFFIX = '_TOP_UP_QUOTA';

const calculateCoTermPrice = (
  plan: SalesPackage,
  status: CoTermDateStatus,
  startDateOfSubscription: Moment,
) => {
  const initialPrice = plan.paymentStrategies[0].netAmount;
  const days = moment().diff(startDateOfSubscription, 'days');
  const proportionalPart = ((365 - days) / 365) * initialPrice;
  const proportionalPartRoundedToOneDecimal = Math.round(proportionalPart * 10) / 10;
  if (status === CoTermDateStatus['9_MONTHS_AGO']) {
    return initialPrice + proportionalPartRoundedToOneDecimal;
  }
  if (status === CoTermDateStatus.VALID) {
    return proportionalPartRoundedToOneDecimal;
  }
};
const createCoTermPackage = (
  plan: SalesPackage,
  status: CoTermDateStatus,
  startDateOfSubscription: Moment,
): SalesPackage => {
  const price = calculateCoTermPrice(plan, status, startDateOfSubscription);
  const paymentStrategies = plan.paymentStrategies.map(strat => ({
    ...strat,
    netAmount: price!,
    showcaseNetAmount: price!,
    asFactor: true,
  }));
  return {
    ...plan,
    description: `${plan.description} (top-up quota)`,
    invoiceDescription: plan.invoiceDescription,
    name: `${plan.name} (top-up quota)`,
    paymentStrategies,
    planId: `${plan.planId}${TOP_UP_QUOTA_SUFFIX}`,
    shortName: `${plan.shortName} (top-up quota)`,
    isClientCreatedPackage: true,
    planetscopeSubscriptionStartDate: startDateOfSubscription,
  };
};

const calculateDiscount = (newPrice: number, planPrice: number) => {
  if (newPrice > planPrice) {
    return (1 - (newPrice - planPrice) / planPrice) * 100;
  }
  return (1 - newPrice / planPrice) * 100;
};

const validateDate = (date: Moment | undefined | null): CoTermDateStatus | undefined => {
  if (!date) {
    return undefined;
  }
  if (date.isBefore(moment().subtract('1', 'year'))) {
    return CoTermDateStatus['1_YEAR_AGO'];
  }
  const nineMonthsAgo = moment().subtract('9', 'months');
  if (date.isBefore(nineMonthsAgo)) {
    return CoTermDateStatus['9_MONTHS_AGO'];
  }
  if (date.isBetween(nineMonthsAgo, moment(), undefined, '[]')) {
    return CoTermDateStatus.VALID;
  }
  return undefined;
};

const messageFromCoTermStatus = (status: CoTermDateStatus | undefined) => {
  if (status === undefined) {
    return undefined;
  }
  switch (status) {
    case CoTermDateStatus['1_YEAR_AGO']:
      return (
        <ErrorBanner>
          Top-up quota cannot be applied for subscriptions older than 1 year.
        </ErrorBanner>
      );
    case CoTermDateStatus['9_MONTHS_AGO']:
      return (
        <WarningMessage>
          Your subscription has been active for more than 9 months. To top-up it you will have to
          purchase another full year.
        </WarningMessage>
      );
    case CoTermDateStatus.VALID:
      return (
        <InfoMessage>
          Valid top-up quota purchase, only the proportional part will be charged.
        </InfoMessage>
      );
    default:
      return undefined;
  }
};
const PlanetCoTermModal = ({ plan, addAndSetClientPackage, disabled }: CoTermProps) => {
  const [cotermDate, setCotermDate] = useState<Moment | undefined>(undefined);

  const handleCotermDateChange = (newDate: Moment) => {
    setCotermDate(newDate);
  };

  const handleSetDate = (handleContinue: any) => (e: any) => {
    e.stopPropagation();
    addAndSetClientPackage(createCoTermPackage(plan, coTermDateStatus!, cotermDate!));
    handleContinue();
  };

  const coTermDateStatus = useMemo(() => validateDate(cotermDate), [cotermDate]);
  const newCoTermPrice = useMemo(() => {
    if (coTermDateStatus !== undefined && cotermDate !== undefined) {
      return calculateCoTermPrice(plan, coTermDateStatus, cotermDate);
    }
    return undefined;
  }, [cotermDate, coTermDateStatus, plan]);
  const coTermDiscount =
    newCoTermPrice && calculateDiscount(newCoTermPrice, plan.paymentStrategies[0].netAmount);

  const today = useMemo(() => moment(), []);
  return (
    <div
      onClick={e => {
        // to prevent the card from being opened
        e.stopPropagation();
      }}
    >
      <BaseModalContainer
        trigger={(handleOpen: any) => (
          <PrimaryButton
            content="Top-up quota"
            fluid
            onClick={() => {
              handleOpen();
            }}
            disabled={disabled}
            style={{ marginTop: '2px' }}
          />
        )}
      >
        {handleClose => (
          <>
            <Modal.Header>Planet Top-up quota</Modal.Header>
            <Modal.Content>
              <p>
                Check how{' '}
                <PrimaryBlankLink href="https://www.sentinel-hub.com/faq/#how-the-planetscope-hectares-under-management-works">
                  PlanetScope Area under Management
                </PrimaryBlankLink>{' '}
                model works.
              </p>
              <p>
                If you're in the last three months of the subscription period, you will have to
                subscribe for a whole new year. Otherwise, the proportional part of the price for
                remaining period will be applied.
              </p>
              <label style={{ fontWeight: 'bold' }}>
                Select the initial date of your subscription
              </label>
              <DatePickerContainer
                format={['YYYY-MM-DD']}
                value={cotermDate ? moment(cotermDate) : undefined}
                inputEnabled
                disabledDates={(currentDate: Moment) => currentDate.isSameOrAfter(today)}
                error={false}
                handleChange={handleCotermDateChange}
                timePickerDisabled
                showDateInput={false}
              />
              <div style={{ marginTop: '1rem' }}>{messageFromCoTermStatus(coTermDateStatus)}</div>
              {coTermDiscount && <InfoMessage>Discount: {coTermDiscount.toFixed(2)} %</InfoMessage>}

              <Modal.Actions style={{ marginTop: '1rem' }}>
                <SettingsModal
                  trigger={handleOpen => (
                    <SecondaryButton
                      style={{ marginLeft: '0' }}
                      type="button"
                      onClick={handleSetDate(handleOpen)}
                      disabled={
                        coTermDateStatus === undefined ||
                        coTermDateStatus === CoTermDateStatus['1_YEAR_AGO']
                      }
                    >
                      Proceed
                    </SecondaryButton>
                  )}
                  plan={createCoTermPackage(plan, coTermDateStatus!, cotermDate!)}
                />
              </Modal.Actions>
            </Modal.Content>
          </>
        )}
      </BaseModalContainer>
    </div>
  );
};
const mapDispatchToProps = {
  addAndSetClientPackage,
};

export default connect(null, mapDispatchToProps)(PlanetCoTermModal);
