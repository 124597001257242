import { DatasetId } from '@src/meta/types/wms/Dataset';

export const AWS_EU_CENTRAL_1_COLLECTIONS = new Set([
  DatasetId.S1GRD,
  DatasetId.S2L1C,
  DatasetId.S2L2A,
  DatasetId.DEM,
  DatasetId.BYOC,
]);

export const CODEDE_COLLECTIONS = new Set([
  DatasetId.S2L2A,
  DatasetId.S1GRD,
  DatasetId.S2L1C,
  DatasetId.S3OLCI,
  DatasetId.S3SLSTR,
  DatasetId.S5PL2,
  DatasetId.BYOC,
]);

export const CREODIAS_COLLECTIONS = new Set([
  DatasetId.S3OLCI,
  DatasetId.S3SLSTR,
  DatasetId.S5PL2,
  DatasetId.S2L1C,
  DatasetId.S2L2A,
  DatasetId.BYOC,
]);
