import produce, { Draft } from 'immer';
import { getDefaultStatBatchDeployment } from '@modules/statistical-batch/components/lib';

import * as actionTypes from '../../constants/actionTypes';
import { ISetStatBatchDeployment } from '../../actions/statbatch';

const initialState = Object.freeze({
  selectedDeployment: getDefaultStatBatchDeployment(),
});

export type StatBatchState = typeof initialState;

type StatBatchAction = ISetStatBatchDeployment;

const statbatch = (state: StatBatchState = initialState, action: StatBatchAction) => {
  return produce<StatBatchState>(state, (draft: Draft<StatBatchState>) => {
    switch (action.type) {
      case actionTypes.SET_STAT_BATCH_DEPLOYMENT:
        draft.selectedDeployment = action.deployment;
        return;
      default:
        return;
    }
  });
};

export default statbatch;
