import React, { ReactNode } from 'react';
import { Icon, List } from 'semantic-ui-react';
import styled from 'styled-components';
import { StyledSidebarItem } from '@src/styles/Sidebar';

import TargetBlankLink from '@src/components/common/TargetBlankLink';
import { SidebarListItemContent } from '@src/components/nav/Sidebar/Item/style';
import { SidebarContext } from '@src/components/nav/Sidebar';
import Popup from '@src/components/common/Popup';

type Props = {
  content: string;
  popupContent: string;
  link: string;
  icon: ReactNode;
};

const ExternaLinkSidebarTab = ({ link, content, popupContent, icon }: Props) => {
  return (
    <StyledSidebarItem active={false} className="sidebar-list__item">
      <TargetBlankLink href={link}>
        <SidebarContext.Consumer>
          {({ sidebarCollapsed }) => (
            <Popup
              trigger={
                <SidebarListItemContent active={false} disabled={false} style={{ paddingRight: 0 }}>
                  <span>
                    <StyledIconWrapper className={sidebarCollapsed ? 'collapsed' : ''}>
                      {icon}
                    </StyledIconWrapper>
                  </span>
                  {!sidebarCollapsed ? (
                    <>
                      <List.Content style={{ marginLeft: '8px' }}>{content}</List.Content>
                      <StyledExternalLinkIconWrapper id="external-link-icon-wrapper">
                        <Icon name="external alternate" style={{ cursor: 'pointer' }} />
                      </StyledExternalLinkIconWrapper>
                    </>
                  ) : null}
                </SidebarListItemContent>
              }
              content={popupContent}
              position="right center"
              basic={false}
            />
          )}
        </SidebarContext.Consumer>
      </TargetBlankLink>
    </StyledSidebarItem>
  );
};

export default React.memo(ExternaLinkSidebarTab);

const StyledIconWrapper = styled.i`
  display: inline-block;
  margin: 0 0.25rem 0 0;
  width: 1.18em;
  height: 1em;
  text-align: center;
`;

const StyledExternalLinkIconWrapper = styled.span`
  margin-left: auto;
  padding-left: 0.5em;
`;
