import config from '@config/config';

import api from '../../lib/api';
import { Links, ResourceFetch, SearchBean } from '../../meta/types/api';
import {
  CollectionPolicyDto,
  AccessRestrictionsRecordDto,
  AccountWithCollectionAccess,
} from '../../meta/types/accesscontrol';

const { GET, POST, DELETE } = api;

const ACCESS_CONTROL_URL = `${config.accessControlServiceBasePath}/api/v1/acl`;

interface IAccessControlResource {
  getCollectionPolicy: ResourceFetch<CollectionPolicyDto, { collectionId: string }>;
  acceptCollectionPolicy: ResourceFetch<void, { collectionId: string }>;
  getAccountRestrictions: ResourceFetch<AccessRestrictionsRecordDto, { id: string }>;
  getCollectionAcl: ResourceFetch<boolean, { collectionId: string }>;
  setCollectionAcl: ResourceFetch<void, { collectionId: string; isEnabled: boolean }>;
  getAccountsForCollection: ResourceFetch<
    { data: AccountWithCollectionAccess[]; links: Links },
    { collectionId: string } & SearchBean
  >;
  addAccountToUseCollection: ResourceFetch<
    void,
    { accountId: string; collectionId: string; notes: string }
  >;
  deleteAccountToUseCollection: ResourceFetch<void, { accountId: string; collectionId: string }>;
}

const AccessControlResource: IAccessControlResource = {
  acceptCollectionPolicy: POST(`${ACCESS_CONTROL_URL}/collection/:collectionId/accept`),
  getCollectionPolicy: GET(`${ACCESS_CONTROL_URL}/collection/:collectionId`),
  getAccountRestrictions: GET(`${ACCESS_CONTROL_URL}/account/:id`),
  getCollectionAcl: GET(`${ACCESS_CONTROL_URL}/collection/:collectionId/acl-enabled`),
  setCollectionAcl: POST(`${ACCESS_CONTROL_URL}/collection/:collectionId/acl-enabled/:isEnabled`),
  getAccountsForCollection: GET(
    `${ACCESS_CONTROL_URL}/collection/:collectionId/da?count=:count&viewtoken=:viewtoken`,
  ),
  addAccountToUseCollection: POST(
    `${ACCESS_CONTROL_URL}/collection/:collectionId/da/:accountId/USE?notes=:notes`,
  ),
  deleteAccountToUseCollection: DELETE(
    `${ACCESS_CONTROL_URL}/collection/:collectionId/da/:accountId/USE`,
  ),
};

export default AccessControlResource;
