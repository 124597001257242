import { format, isToday, differenceInDays } from 'date-fns';
import moment from 'moment-timezone';

import { TimeRange, TimeRangeType } from '../../meta/types/wms/Layer';

export function getDaysFromToday(date: Date): number {
  return differenceInDays(date, new Date());
}

export const formatUtcDate = (date: Date): React.ReactNode => {
  return moment.utc(date).format('DD MMMM YYYY, HH:mm (UTC)');
};

export const formatLocalDateTime = (date: Date): string => {
  return format(date, 'DD MMMM  YYYY,  HH:mm');
};

export const formatLocalDate = (date: Date): string => {
  return format(date, 'DD MMMM  YYYY');
};

export const SERVER_ISO_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export function isEndDateBeforeStartDate(timeRange?: TimeRange): boolean {
  if (!timeRange || !timeRange.startTime || !timeRange.endTime) {
    return false;
  }

  const { startTime, endTime } = timeRange;
  if (endTime.type === TimeRangeType.ABSOLUTE) {
    const endDate = moment(endTime.value, SERVER_ISO_FORMAT);
    const startDate = moment(startTime.value, SERVER_ISO_FORMAT);

    if (endDate.isBefore(startDate)) {
      return true;
    }
  }
  return false;
}

export function getHumanReadableDuration(duration: string) {
  if (duration === 'P1M') {
    return 'Monthly';
  }
  return `Every ${moment.duration(duration).humanize()}`;
}

export function getDaysString(dateString: Date | null, withParentheses = true): string | null {
  if (dateString === null) {
    return null;
  }
  if (withParentheses) {
    return `(${getDaysStringBare(dateString)})`;
  }
  return getDaysStringBare(dateString);
}

export function getDaysStringBare(dateString: Date | null): string | null {
  if (dateString === null) {
    return null;
  }
  const nDays = getDaysFromToday(dateString);
  if (nDays === 0) {
    if (isToday(dateString)) {
      return 'today';
    }
    return 'tomorrow';
  }
  if (nDays < 0) {
    if (nDays === -1) {
      return 'yesterday';
    }
    return `${Math.abs(nDays)} days ago`;
  }
  return `in ${nDays} day${nDays > 1 ? 's' : ''}`;
}
