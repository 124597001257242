import React from 'react';
import { Form } from 'semantic-ui-react';

import {
  formattedPaymentAmount,
  periodToHumanReadableText,
  getPrice,
  getPackagesPrice,
  formatNumberToMax2DecimalPlaces,
} from '@src/lib/billing';
import { PaymentStrategy, SalesPackage } from '@src/meta/types/billing/salespackage';
import { PaymentFrequencyType, Currency } from '@src/meta/types/billing/payment';

import FrequencyCardContent from '../../billing/billing-info/PaymentFrequencyTypePicker/FrequencyCardContent';
import {
  FrequencyTypeCard,
  PaymentPickerStyle,
} from '../../billing/billing-info/PaymentFrequencyTypePicker/style';

type Props = {
  plan: SalesPackage;
  selectedPaymentStrategy: number;
  setSelectedPaymentStrategy: (id: number) => void;
  amountFactorPackages: number;
  setAmountFactorPackages: (amount: number) => void;
  currency: Currency;
};

// Since the showcaseNetAmount will be showed (monthly) even if it's a single payment need to show it as monthly (true).
const shouldShowAsRecurring = (paymentStrategy: PaymentStrategy) => {
  if (paymentStrategy.showcaseNetAmount !== undefined) {
    return true;
  }
  return paymentStrategy.paymentFrequencyType === PaymentFrequencyType.RECURRING;
};

const PaymentFrequencyTypePicker = ({
  selectedPaymentStrategy,
  setSelectedPaymentStrategy,
  amountFactorPackages,
  setAmountFactorPackages,
  plan,
  currency,
}: Props) => {
  return (
    <Form.Field className="payment-frequency-type-picker">
      <PaymentPickerStyle>
        {plan.paymentStrategies.map(paymentStrategy => (
          <FrequencyTypeCard
            key={paymentStrategy.id}
            onClick={() => setSelectedPaymentStrategy(paymentStrategy.id)}
            active={selectedPaymentStrategy === paymentStrategy.id}
            className={`frequency-type ${paymentStrategy.title?.toLowerCase()}`}
            noPadding
            asFactor={paymentStrategy.asFactor}
          >
            <FrequencyCardContent
              header={
                paymentStrategy.title
                  ? paymentStrategy.title
                  : periodToHumanReadableText(paymentStrategy.paymentFrequency)
              }
              price={formattedPaymentAmount(
                getPrice(paymentStrategy, amountFactorPackages),
                shouldShowAsRecurring(paymentStrategy),
                currency.code,
                paymentStrategy.showcasePaymentFrequency
                  ? paymentStrategy.showcasePaymentFrequency
                  : paymentStrategy.paymentFrequency,
              )}
              billingType={`Billed ${
                paymentStrategy.paymentFrequencyType === PaymentFrequencyType.SINGLE
                  ? 'once'
                  : periodToHumanReadableText(paymentStrategy.paymentFrequency).toLowerCase()
              } (${getPackagesPrice(paymentStrategy, amountFactorPackages)} ${currency.code})`}
              isOrderingOffer={false}
              savings={formatNumberToMax2DecimalPlaces(paymentStrategy.savings)}
              asFactor={paymentStrategy.asFactor}
              amountFactorPackages={amountFactorPackages}
              setAmountFactorPackages={(amount: number) => setAmountFactorPackages(amount)}
              hasActivePaymentBillingInfo={false}
              currency={currency}
            />
          </FrequencyTypeCard>
        ))}
      </PaymentPickerStyle>
    </Form.Field>
  );
};

export default PaymentFrequencyTypePicker;
