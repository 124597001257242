import { Location } from 'history';

export enum UpgradeStatus {
  UPGRADED = 'true',
  NOT_ELIGIBLE = 'false',
  FAILED = 'failed',
  DUPLICATE = 'duplicate',
}

export const UPGRADE_STATUS_PARAM = 'upgrade';
export const NEW_ACCOUNT_PARAM = 'new_account';

export const SUPPORT_CENTER_LINK =
  'https://support.planet.com/hc/en-us/sections/16417841573789-Getting-Started';
export const PLANET_UNIVERSITY_LINK = 'https://university.planet.com/page/planet-insights-platform';

export function fixUrl(urlParams: URLSearchParams, location: Location) {
  if (
    !location.search &&
    window.location.search &&
    (urlParams.has(UPGRADE_STATUS_PARAM) || urlParams.has(NEW_ACCOUNT_PARAM))
  ) {
    const correctedUrl =
      window.location.origin +
      window.location.pathname +
      window.location.hash +
      window.location.search.replace(window.location.pathname, '');
    console.info(`Redirecting original url "${window.location.href}" to "${correctedUrl}".`);
    window.history.replaceState(null, '', correctedUrl);
  }
}
