import React, { ReactNode, CSSProperties } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import config from '@config/config';

import TargetBlankLink from '../TargetBlankLink';

type Props = {
  href: string | undefined;
  style?: CSSProperties;
} & {
  children: ReactNode;
};

export const SecondaryBlankLink = (props: Props) => {
  return (
    <TargetBlankLink
      {...props}
      style={{ ...props.style, color: config.primaryColor, fontWeight: 'bold' }}
    />
  );
};

export const PrimaryBlankLink = (props: Props) => {
  return (
    <TargetBlankLink
      {...props}
      style={{
        color: config.darkBackgroundColor,
        textDecoration: 'underline',
        ...props.style,
      }}
    />
  );
};

export const SecondaryLink = (props: LinkProps) => {
  return (
    <Link {...props} style={{ ...props.style, color: config.primaryColor, fontWeight: 'bold' }} />
  );
};

export const PrimaryLink = (props: LinkProps) => {
  return (
    <Link
      {...props}
      style={{
        ...props.style,
        color: config.primaryTextColor,
        textDecoration: 'underline',
      }}
    />
  );
};
