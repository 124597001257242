import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import config from '@config/config';

import AsyncLoad from '@src/components/common/AsyncLoad';
import { BaseAuthRoute } from '@src/components/common/Route';
import { withTracker } from '@src/hocs';

import { Props, NewsType } from './types';

export const RouteContainer = posed.div({
  enter: { opacity: 1, beforeChildren: true, delay: 125 },
  exit: { opacity: 0 },
});

const AsyncNewsPage = AsyncLoad({
  loader: () => import('./components/News'),
}) as React.FunctionComponent;
const AsyncNewsPageWithTracker = withTracker(AsyncNewsPage);

function NewsBase({}: Props): NewsType {
  return () => {
    return (
      <PoseGroup className="news-routes-container">
        <RouteContainer key="news-routes-container">
          <BaseAuthRoute exact path={config.NEWS_PATH} component={AsyncNewsPageWithTracker} />
        </RouteContainer>
      </PoseGroup>
    );
  };
}
export * from './types';
export { NewsBase };
export default NewsBase({});
