import React from 'react';
import { Dropdown, DropdownMenuProps } from 'semantic-ui-react';

interface Props extends DropdownMenuProps {
  children: Array<React.ReactElement<any>>;
  dropdownId: string;
}

const DropdownMenuWrapper = ({ children, dropdownId, ...rest }: Props) => {
  return (
    <Dropdown.Menu {...rest}>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          onClick: (e: any) => {
            setTimeout(() => document.getElementById(dropdownId)!.classList.remove('active'), 0);
            if (child.props.onClick) {
              child.props.onClick(e);
            }
          },
        });
      })}
    </Dropdown.Menu>
  );
};

export default React.memo(DropdownMenuWrapper);
