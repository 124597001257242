import { PaymentFrequencyType, PaymentType, CurrencyCode } from './payment';

export type BaseOrder = {
  id: string;
  paymentSystemId?: string;
  paymentType: PaymentType;
  status: OrderStatus;
  userId: string;
  userBillingInfoId: number;
};

export interface ServerOrder extends BaseOrder {
  created: string;
  data: ServerOrderData;
}

export interface Order extends BaseOrder {
  created: Date;
  data: OrderData;
}

export enum OrderStatus {
  PLACED = 'PLACED',
  CANCELED = 'CANCELED',
  APPROVED = 'APPROVED',
  OFFER = 'OFFER',
  SUBSCRIBED = 'SUBSCRIBED',
  REVOKED = 'REVOKED',
}

type BaseOrderData = {
  planId: number;
  name: string;
  description: string;
  currency: CurrencyCode;
  netAmount: number;
  vatAmount: number;
  vatRate?: number;
  grossAmount: number;
  paymentFrequencyType: PaymentFrequencyType;
  paymentFrequency?: string;
  payPalAgreementPlanId?: string;
  paymentOptions?: PaymentType[];
  itemCount?: number;
};

export interface OrderData extends BaseOrderData {
  planetscopeSubscriptionStartDate?: Date;
}

interface ServerOrderData extends BaseOrderData {
  planetscopeSubscriptionStartDate?: string;
}

export function mapOrder(order: ServerOrder): Order {
  let planetscopeSubscriptionStartDate;
  if (order.data.planetscopeSubscriptionStartDate) {
    planetscopeSubscriptionStartDate = new Date(order.data.planetscopeSubscriptionStartDate);
  }
  return {
    ...order,
    created: new Date(order.created),
    data: {
      ...order.data,
      planetscopeSubscriptionStartDate,
    },
  };
}

export function isCancalable(order: Order): boolean {
  return (
    order &&
    (order.status === OrderStatus.APPROVED ||
      order.status === OrderStatus.PLACED ||
      order.status === OrderStatus.OFFER)
  );
}

export type AdyenCreditCardDetails = {
  cardType: string;
  expiryMonth: string;
  expiryYear: string;
  lastFourDigits: string;
};
