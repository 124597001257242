import config from '@config/config';

import api from '../../lib/api';
import { ResourceFetch } from '../../meta/types/api';
import {
  TpdiOrder,
  Delivery,
  DownloadStatusResponse,
  TpdiDeliveryTile,
} from '../../meta/types/tpdi';

import { ContentType } from '../../meta/types/content-type';

export const TPDI_BASE_PATH = `${config.tpdiServiceBasePath}/api/v1/dataimport`;

const { GET, DELETE, POST, HEAD } = api;

export type TpdiQuotaResponse = {
  data: { collectionId: string; id: string; quotaSqkm: number; quotaUsed: number }[];
};

export type TpdiDeliveryTileResponse = {
  data: TpdiDeliveryTile[];
};

export type TpdiGetOrdersResponse = {
  data: TpdiOrder[];
  links: {
    next?: string;
  };
};

type DeliveriesResponse = {
  data: Delivery[];
};

export const DOWNLOAD_DELIVERY_CONFIG = {
  authenticated: true,
  withCredentials: false,
  endpointHeaders: {
    Accept: 'application/octet-stream',
  },
};

export interface TpdiService {
  getQuota: ResourceFetch<TpdiQuotaResponse>;
  getAccountIdQuota: ResourceFetch<TpdiQuotaResponse, { accountId: string }>;
  getCollectionOrders: ResourceFetch<
    TpdiGetOrdersResponse,
    { collectionId?: string; viewtoken?: number; accountId?: string }
  >;
  getDeliveries: ResourceFetch<DeliveriesResponse, { orderId: string }>;
  getDeliveryTiles: ResourceFetch<
    TpdiDeliveryTileResponse,
    { orderId: string; deliveryId: string }
  >;
  deleteOrder: ResourceFetch<void, { orderId: string }>;
  getOrders: ResourceFetch<
    TpdiGetOrdersResponse,
    { viewtoken?: number; search?: string; sort?: string; deleted: boolean; accountId?: string }
  >;
  getSingleOrder: ResourceFetch<TpdiOrder, { id: string }>;
  getArchiveStatus: ResourceFetch<
    DownloadStatusResponse,
    { deliveryId: string; orderOrSubscriptionId: string }
  >;
  requestArchive: ResourceFetch<
    DownloadStatusResponse,
    { deliveryId: string; orderOrSubscriptionId: string }
  >;
  downloadArchive: ResourceFetch<string, { deliveryId: string; orderOrSubscriptionId: string }>;
  listFiles: ResourceFetch<string[], { deliveryId: string; orderOrSubscriptionId: string }>;
  downloadFile: ResourceFetch<
    string,
    { deliveryId: string; orderOrSubscriptionId: string; fileName: string }
  >;
  getPresignedFileUrl: ResourceFetch<
    string,
    { deliveryId: string; orderOrSubscriptionId: string; fileName: string }
  >;
  getPresignedArchiveUrl: ResourceFetch<
    string,
    { deliveryId: string; orderOrSubscriptionId: string }
  >;
  getFileSize: ResourceFetch<
    any,
    { deliveryId: string; orderOrSubscriptionId: string; fileName: string }
  >;
}

const TpdiService: TpdiService = {
  getQuota: GET(`${TPDI_BASE_PATH}/accountquotas`),
  getAccountIdQuota: GET(`${TPDI_BASE_PATH}/accountquotas?accountId=:accountId`),
  getCollectionOrders: GET(
    `${TPDI_BASE_PATH}/orders?collectionId=:collectionId&viewtoken=:viewtoken`,
  ),
  getDeliveries: GET(`${TPDI_BASE_PATH}/orders/:orderId/deliveries`),
  getDeliveryTiles: GET(`${TPDI_BASE_PATH}/orders/:orderId/deliveries/:deliveryId/tiles`),
  deleteOrder: DELETE(`${TPDI_BASE_PATH}/orders/:orderId`),
  getOrders: GET(`${TPDI_BASE_PATH}/orders?viewtoken=:viewtoken`),
  getSingleOrder: GET(`${TPDI_BASE_PATH}/orders/:id`),
  getArchiveStatus: GET(
    `${TPDI_BASE_PATH}/orders/:orderOrSubscriptionId/deliveries/:deliveryId/archive/status`,
  ),
  requestArchive: POST(
    `${TPDI_BASE_PATH}/orders/:orderOrSubscriptionId/deliveries/:deliveryId/archive/create`,
  ),
  downloadArchive: GET(
    `${TPDI_BASE_PATH}/orders/:orderOrSubscriptionId/deliveries/:deliveryId/archive`,
    DOWNLOAD_DELIVERY_CONFIG,
  ),
  getPresignedArchiveUrl: GET(
    `${TPDI_BASE_PATH}/orders/:orderOrSubscriptionId/deliveries/:deliveryId/archive/presign`,
    {
      authenticated: true,
      withCredentials: true,
      contentType: ContentType.TEXT,
    },
  ),
  listFiles: GET(`${TPDI_BASE_PATH}/orders/:orderOrSubscriptionId/deliveries/:deliveryId/files`),
  downloadFile: GET(
    `${TPDI_BASE_PATH}/orders/:orderOrSubscriptionId/deliveries/:deliveryId/files/:fileName`,
    DOWNLOAD_DELIVERY_CONFIG,
  ),
  getPresignedFileUrl: GET(
    `${TPDI_BASE_PATH}/orders/:orderOrSubscriptionId/deliveries/:deliveryId/files/:fileName/presign`,
    {
      authenticated: true,
      withCredentials: true,
      contentType: ContentType.TEXT,
    },
  ),
  getFileSize: HEAD(
    `${TPDI_BASE_PATH}/orders/:orderOrSubscriptionId/deliveries/:deliveryId/files/:fileName`,
    {
      authenticated: true,
      contentType: ContentType.TEXT,
      withCredentials: true,
    },
  ),
};

export const fetchDeliveriesOnOrdersResponse = async (res: TpdiGetOrdersResponse) => {
  const orders = res.data;
  const promises = orders.map(async order => {
    const deliveriesRes = await TpdiService.getDeliveries({ orderId: order.id });
    return {
      ...order,
      deliveries: deliveriesRes.data,
    };
  });
  const resultData = await Promise.all(promises);
  return {
    ...res,
    data: resultData,
  };
};

export default TpdiService;
