import { Moment } from 'moment-timezone';

import { Order } from './order';
import { PaymentFrequencyType, PaymentType, CurrencyCode } from './payment';

export const BILLING_PLAN_MANAGEMENT_OFFER = 'OFFER';
export const BILLING_PLAN_RETRY = 'RETRY';

export type SalesPackageSelector = {
  salesPackageId: string;
  paymentStrategyId: number;
};

export enum SalesPackageItemType {
  EXTRA = 'EXTRA',
}

export type SalesPackageItem = {
  title?: string;
  description?: string;
  type?: SalesPackageItemType;
};

export type PaymentStrategy = {
  id: number;
  title?: string;
  netAmount: number;
  paymentFrequencyType: PaymentFrequencyType;
  paymentFrequency?: string;
  payPalPlanId?: string;
  paymentOptions: PaymentType[];
  savings?: number;
  showcaseNetAmount?: number;
  showcasePaymentFrequency?: string;
  asFactor?: boolean;
  currency?: CurrencyCode;
  description?: string;
  invoiceDescription?: string;
};

export type SalesPackage = {
  id: number;
  name: string;
  planId: string;
  accountType?: number[];
  accountLevel?: number;
  description: string;
  invoiceDescription: string;
  items?: SalesPackageItem[];
  paymentStrategies: PaymentStrategy[];
  shortName: string;
  offer?: Order;
  displayRow: number;
  purchaseAction: string;
  groupId?: string;
  isClientCreatedPackage?: boolean;
  planetscopeSubscriptionStartDate?: Moment;
  providers?: string[];
  packageTypeGroup?: string;
  compact?: boolean;
  priceTitleOverride?: string;
};
