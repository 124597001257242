import React from 'react';
import { Route } from 'react-router-dom';

import { BaseRouteProps } from '../BaseRoute';

const BaseAuthRoute = ({ component: Component, ...rest }: BaseRouteProps) => {
  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default BaseAuthRoute;
