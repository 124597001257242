import produce, { Draft } from 'immer';
import { AccountInfo } from '@modules/auth/components/types/user';
import { RoleAssignmentMap } from '@modules/auth/components/types/user/index';

import { ISetAccount, ISetAccountFetching, ISetAssignmentGroups } from '../../actions/accounts';
import * as actionTypes from '../../constants/actionTypes';

export const initialState = Object.freeze({
  account: (undefined as unknown) as AccountInfo,
  fetched: false as boolean,
  fetching: false as boolean,
  rags: [] as RoleAssignmentMap[],
});

export type AccountState = typeof initialState;

const account = (
  state: AccountState = initialState,
  action: ISetAccount | ISetAccountFetching | ISetAssignmentGroups,
) => {
  return produce<AccountState>(state, (draft: Draft<AccountState>) => {
    switch (action.type) {
      case actionTypes.SET_ACCOUNT:
        draft.fetched = true;
        draft.fetching = false;
        draft.account = action.account;
        return;
      case actionTypes.SET_ACCOUNT_FETCHING:
        draft.fetching = true;
        return;
      case actionTypes.SET_ROLE_ASSIGNMENT_GROUPS:
        draft.rags = action.rags;
        return;
      default:
        return;
    }
  });
};

export default account;
