import baseStyled, { ThemedStyledInterface } from 'styled-components';

export type Theme = {
  primaryTextColor: string;
  secondaryTextColor: string;
  terciaryTextColor: string;

  primaryColor: string;
  terciaryColor: string;

  billingCardsStackEndpoint: string;

  mainContentPadding: string;

  padding1: string;
  padding2: string;
  padding3: string;
  padding4: string;
  padding5: string;
  padding6: string;
  padding7: string;
  padding8: string;
  padding9: string;
  padding10: string;
  padding11: string;
  padding12: string;
  padding13: string;
  padding14: string;
  padding15: string;
  padding16: string;
};

export const styled = baseStyled as ThemedStyledInterface<Theme>;
