import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';

import { DocumentationLink } from '@modules/common/components/docs';
import config from '@config/config';
import WarningMessage from '@src/components/common/banners/WarningMessage';
import AccessControlResource from '@src/services/AccessControl';
import { AccessRestrictions, AccessRestrictionsRecordDto } from '@src/meta/types/accesscontrol';
import { StoreState } from '@src/meta/types/store';
import { PrimaryBlankLink } from '@src/components/common/links';
import SecondaryButton from '@src/components/common/buttons/SecondaryButton';
import InfoMessage from '@src/components/common/banners/InfoMessage';

type Props = {
  accountId: string | undefined;
};

const OutOfRequestsContent = () => {
  return (
    <p>
      Warning: your account has been temporary throttled due to usage exceeding configured volume of
      requests. In case of{' '}
      <PrimaryBlankLink href={DocumentationLink.BILLING_POLICY}>monthly quotas</PrimaryBlankLink>{' '}
      the account will be re-activated at the beginning of the next month.{' '}
      <PrimaryBlankLink href="https://apps.sentinel-hub.com/dashboard/#/account/billing">
        Upgrade your account
      </PrimaryBlankLink>{' '}
      or contact{' '}
      <PrimaryBlankLink href={`mailto:${config.contactLink}`}>
        {config.contactLink}
      </PrimaryBlankLink>{' '}
      if you feel something is wrong with the settings. More information in{' '}
      <PrimaryBlankLink href={DocumentationLink.BILLING}>Billing section</PrimaryBlankLink>.
    </p>
  );
};

const OutOfPusContent = () => {
  return (
    <p>
      Warning: your account has been temporary throttled due to usage exceeding configured volume of
      processing units. In case of{' '}
      <PrimaryBlankLink href={DocumentationLink.BILLING_POLICY}>monthly quotas</PrimaryBlankLink>{' '}
      the account will be re-activated at the beginning of the next month.{' '}
      <PrimaryBlankLink href="https://apps.sentinel-hub.com/dashboard/#/account/billing">
        Upgrade your account
      </PrimaryBlankLink>{' '}
      or contact{' '}
      <PrimaryBlankLink href={`mailto:${config.contactLink}`}>
        {config.contactLink}
      </PrimaryBlankLink>{' '}
      if you feel something is wrong with the settings. More information in{' '}
      <PrimaryBlankLink href={DocumentationLink.BILLING}>Billing section</PrimaryBlankLink>.
    </p>
  );
};

const BannedContent = () => {
  return (
    <p>
      Warning: your account has been temporary throttled due to detected anomaly in usage. Do
      contact{' '}
      <PrimaryBlankLink href={`mailto:${config.contactLink}`}>
        {config.contactLink}
      </PrimaryBlankLink>{' '}
      if you feel something is wrong with the settings.
    </p>
  );
};

const restrictionToContent = (restriction: AccessRestrictions, idx: number) => {
  if (restriction === AccessRestrictions.BANNED) {
    return <WarningMessage content={BannedContent} key={`restriction-${idx}`} />;
  }
  if (restriction === AccessRestrictions.OUT_OF_PUS) {
    return <WarningMessage content={OutOfPusContent} key={`restriction-${idx}`} />;
  }
  if (restriction === AccessRestrictions.OUT_OF_REQUESTS) {
    return <WarningMessage content={OutOfRequestsContent} key={`restriction-${idx}`} />;
  }
  return null;
};

const BannedBanner = ({ accountId }: Props) => {
  const [restrictions, setRestrictions] = useState<AccessRestrictionsRecordDto | undefined>(
    undefined,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchAndSetBannedState = async () => {
      if (!accountId) {
        setRestrictions(undefined);
        return;
      }
      try {
        const res = await AccessControlResource.getAccountRestrictions({ id: accountId });
        if (res.restrictions.length > 0) {
          setRestrictions(res);
          setIsModalOpen(true);
        }
      } catch (err) {
        setRestrictions(undefined);
      }
    };
    fetchAndSetBannedState();
  }, [accountId]);

  if (!restrictions || restrictions.restrictions.length === 0) {
    return null;
  }

  return (
    <Modal open={isModalOpen}>
      <Modal.Header>Your account is currently restricted</Modal.Header>
      <Modal.Content>
        <div style={{ margin: '1rem' }}>{restrictions.restrictions.map(restrictionToContent)}</div>
        {restrictions.userNotes && (
          <InfoMessage
            style={{ margin: '1rem' }}
            content={
              <p style={{ margin: '1rem' }}>
                <b>Notes: </b>
                {restrictions.userNotes}
              </p>
            }
          />
        )}
        <SecondaryButton onClick={() => setIsModalOpen(false)}>Acknowledge</SecondaryButton>
      </Modal.Content>
    </Modal>
  );
};

const mapStateToProps = (state: StoreState) => ({
  accountId: state.account.account.id,
});

export default connect(mapStateToProps)(BannedBanner);
