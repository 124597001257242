import StringUtils from '../../../lib/string';

type BaseOrderPayment = {
  id: number;
  paymentStatus: string;
  paymentSystemId: string;
  paymentType: string;
  orderId: string;
  invoiceStatus: InvoiceStatus;
};

type PaymentData = {
  totalAmount?: number;
  vatAmount?: number;
  currency?: CurrencyCode;
};
export interface ServerOrderPayment extends BaseOrderPayment {
  created: string;
  due: string;
  data: PaymentData;
}

export interface OrderPayment extends BaseOrderPayment {
  created: Date;
  due: Date;
  data: PaymentData;
}

export enum PaymentFrequencyType {
  SINGLE = 'SINGLE',
  RECURRING = 'RECURRING',
}

export type PaymentResponse = {
  paymentID: string;
};

export enum PaymentType {
  PAYPAL = 'PAYPAL',
  WIRETRANSFER = 'WIRETRANSFER',
  ADYEN = 'ADYEN',
}

export enum CurrencyCode {
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
  CAD = 'CAD',
}

export type Currency = {
  code: CurrencyCode;
  symbol: string;
};

export enum InvoiceStatus {
  PENDING = 'PENDING',
  INVOICE_CREATED = 'INVOICE_CREATED',
  OFFER_CREATED = 'OFFER_CREATED',
  RETRIABLE_ERROR = 'RETRIABLE_ERROR',
  ERROR = 'ERROR',
}

export const toNiceString = (pt: PaymentType) => {
  return StringUtils.capitalize(pt.toLowerCase());
};
