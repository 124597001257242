import React from 'react';
import { toast, ToastContent } from 'react-toastify';

const DEFAULT_OPTIONS = {
  position: toast.POSITION.TOP_RIGHT,
  className: 'toast__wrapper',
  bodyClassName: 'toast__body',
  progressClassName: 'toast__progress',
};

const messageToJsx = (message: ToastContent) => {
  if (typeof message === 'string') {
    const splitted = message.split('\n');
    return (
      <>
        {splitted.map((err, idx) => (
          <p style={{ margin: '0.1rem 0' }} key={`alert-error-${idx}`}>
            {err}
          </p>
        ))}
      </>
    );
  }
  return message;
};

export const alertSuccess = (message: ToastContent, opts = DEFAULT_OPTIONS) => {
  toast.success(messageToJsx(message), {
    ...opts,
    className: `${opts.className} success`,
  });
};

export const alertError = (message: ToastContent, opts = DEFAULT_OPTIONS) => {
  toast.error(messageToJsx(message), {
    ...opts,
    className: `${opts.className} error`,
  });
};

export const alertWarn = (message: ToastContent, opts = DEFAULT_OPTIONS) => {
  toast.warn(messageToJsx(message), opts);
};

export const alertInfo = (message: ToastContent, opts = DEFAULT_OPTIONS) => {
  toast.info(messageToJsx(message), opts);
};

export const alert = (message: ToastContent, opts: any) => {
  toast(messageToJsx(message), opts);
};
