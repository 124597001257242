import produce, { Draft } from 'immer';

import { Dictionary } from 'lodash';
import {
  IAddCollection,
  IAddCollectionTile,
  IDeleteCollection,
  IDeleteCollectionTile,
  ISetCollection,
  ISetCollectionFetching,
  ISetCollectionFetchingFailed,
  ISetUserCollections,
  ISetCollectionSearchQuery,
  ISetUserCollectionsFetching,
  ISetUserCollectionsFetchingFailed,
  ISetCollectionTiles,
  ISetCollectionTilesFetching,
  IUpdateCollection,
  IUpdateCollectionTile,
  IAddCollections,
  IUpdateBatchRequestsCollectionsCache,
  IMoveBatchRequestFromCollectionsCache,
  IupdateBatchRequest,
  IRemoveBatchRequestFromCollectionCache,
} from '../../actions/byoc';
import * as actionTypes from '../../constants/actionTypes';
import {
  ByocCollection,
  ByocCollectionsCache,
  ByocTilesCache,
  CollectionCacheRecord,
  FetchedState,
} from '../../meta/types/byoc';

export const initialState = Object.freeze({
  fetchingCollections: false,
  fetched: false,
  collectionsCache: {} as ByocCollectionsCache,
  collectionSearchQuery: '',
  tileCache: {} as ByocTilesCache,
});

export type ByocState = typeof initialState & {
  collectionsPromise?: Promise<ByocCollection[]>;
  collectionFetchingFailed?: boolean;
};

type ByocAction =
  | ISetUserCollectionsFetching
  | ISetUserCollectionsFetchingFailed
  | ISetUserCollections
  | ISetCollectionFetching
  | ISetCollectionFetchingFailed
  | ISetCollection
  | IDeleteCollection
  | IUpdateCollection
  | ISetCollectionSearchQuery
  | ISetCollectionTiles
  | ISetCollectionTilesFetching
  | IAddCollectionTile
  | IUpdateCollectionTile
  | IDeleteCollectionTile
  | IAddCollection
  | IAddCollections
  | IUpdateBatchRequestsCollectionsCache
  | IupdateBatchRequest
  | IMoveBatchRequestFromCollectionsCache
  | IRemoveBatchRequestFromCollectionCache;

const byoc = (state: ByocState = initialState, action: ByocAction) => {
  return produce<ByocState>(state, (draft: Draft<ByocState>) => {
    switch (action.type) {
      case actionTypes.UPDATE_COLLECTION_TILE:
        draft.tileCache[action.collectionId] = {
          ...draft.tileCache[action.collectionId],
          tiles: draft.tileCache[action.collectionId].tiles.map(t =>
            t.id === action.tile.id ? action.tile : t,
          ),
        };
        return;
      case actionTypes.DELETE_COLLECTION_TILE:
        draft.tileCache[action.collectionId] = {
          ...draft.tileCache[action.collectionId],
          tiles: draft.tileCache[action.collectionId].tiles.filter(t => t.id !== action.tileId),
        };
        return;
      case actionTypes.ADD_COLLECTION_TILE:
        draft.tileCache[action.collectionId] = {
          ...draft.tileCache[action.collectionId],
          tiles: [action.tile, ...draft.tileCache[action.collectionId].tiles],
        };
        return;
      case actionTypes.SET_COLLECTION_TILES_FETCHING:
        draft.tileCache[action.collectionId] = {
          fetching: true,
          query: action.query,
          tilesPromise: action.tilesPromise,
          tiles: [],
          links: {},
        };
        return;
      case actionTypes.SET_COLLECTION_TILES:
        draft.tileCache[action.collectionId] = {
          fetching: false,
          tilesPromise: undefined,
          tiles: action.tiles,
          links: action.links,
        };
        return;
      case actionTypes.SET_COLLECTION_SEARCH_QUERY:
        draft.collectionSearchQuery = action.searchQuery;
        return;
      case actionTypes.UPDATE_COLLECTION:
        draft.collectionsCache[action.collection.id] = {
          ...state.collectionsCache[action.collection.id],
          collection: action.collection,
        };
        return;
      case actionTypes.ADD_COLLECTION:
        draft.collectionsCache[action.collection.id] = {
          collection: action.collection,
          fetchedState: FetchedState.FETCHED,
        };
        return;
      case actionTypes.DELETE_COLLECTION:
        delete draft.collectionsCache[action.collectionId];
        return;
      case actionTypes.SET_USER_COLLECTIONS_FETCHING:
        draft.fetchingCollections = true;
        draft.collectionFetchingFailed = false;
        draft.collectionsPromise = action.collectionsPromise;
        return;
      case actionTypes.SET_USER_COLLECTIONS_FETCHING_FAILED:
        draft.fetchingCollections = false;
        draft.collectionsPromise = undefined;
        draft.fetched = false;
        draft.collectionFetchingFailed = true;
        return;
      case actionTypes.SET_USER_COLLECTIONS_FETCHED:
        draft.fetchingCollections = false;
        draft.collectionsPromise = undefined;
        draft.fetched = true;
        return;
      case actionTypes.ADD_COLLECTIONS:
        draft.collectionsCache = {
          ...draft.collectionsCache,
          ...action.collections.reduce((map, obj) => {
            map[obj.id] = {
              collection: obj,
              fetchedState: FetchedState.NOT_FETCHED,
            };
            return map;
          }, {} as Dictionary<CollectionCacheRecord>),
        };
        return;
      case actionTypes.SET_COLLECTION_FETCHING:
        draft.collectionsCache[action.collectionId] = {
          ...draft.collectionsCache[action.collectionId],
          fetchedState: FetchedState.FETCHING,
        };
        return;
      case actionTypes.SET_COLLECTION_FETCHING_FAILED:
        draft.collectionsCache[action.collectionId] = {
          ...state.collectionsCache[action.collectionId],
          fetchedState: FetchedState.FETCHING_FAILED,
          errorMessage: action.errorMessage,
        };
        return;
      case actionTypes.SET_COLLECTION:
        draft.collectionsCache[action.collection.id] = {
          collection: {
            ...state.collectionsCache[action.collection.id].collection,
            ...action.collection,
          },
          fetchedState: FetchedState.FETCHED,
        };
        return;
      case actionTypes.UPDATE_BATCH_COLLECTION_REQUESTS:
        // cache can be undefined to show that requests are being fetched.
        const cache = action.requests?.reduce((acc, req) => {
          acc[req.id] = req;
          return acc;
        }, {} as any);
        draft.collectionsCache[action.collectionId].batchRequestsCollectionCache = cache;
        return;
      case actionTypes.MOVE_BATCH_REQUEST:
        const { fromCollectionId, batchRequestId, toCollectionId, batchRequestMoved } = action;
        if (
          fromCollectionId !== undefined &&
          draft.collectionsCache[fromCollectionId]?.batchRequestsCollectionCache !== undefined
        ) {
          delete draft.collectionsCache[fromCollectionId].batchRequestsCollectionCache![
            batchRequestId
          ];
        }
        if (toCollectionId !== undefined && batchRequestMoved !== undefined) {
          if (draft.collectionsCache[toCollectionId].batchRequestsCollectionCache === undefined) {
            draft.collectionsCache[toCollectionId].batchRequestsCollectionCache = {
              [batchRequestMoved.id]: batchRequestMoved,
            };
          } else {
            draft.collectionsCache[toCollectionId].batchRequestsCollectionCache![
              batchRequestMoved.id
            ] = batchRequestMoved;
          }
        }
        return;
      case actionTypes.REMOVE_BATCH_REQUEST:
        if (
          draft.collectionsCache[action.collectionId]?.batchRequestsCollectionCache !== undefined
        ) {
          delete draft.collectionsCache[action.collectionId].batchRequestsCollectionCache![
            action.batchRequestId
          ];
        }
        return;
      case actionTypes.UPDATE_SINGLE_BATCH_REQUEST:
        draft.collectionsCache[action.collectionId].batchRequestsCollectionCache![
          action.batchRequest.id
        ] = action.batchRequest;
        return;
      default:
        return;
    }
  });
};

export default byoc;
