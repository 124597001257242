import { ServerAccountInfo } from '@modules/auth/components/types/user';
import config from '@config/config';

import api from '../../../lib/api';
import { ResourceFetch } from '../../../meta/types/api';

const { GET } = api;

const ACCOUNT_RESOURCE_BASE_PATH = `${config.identityServiceBasePath}/accounts`;

export interface AccountResource {
  info: ResourceFetch<ServerAccountInfo, { accountId: string }>;
}

const AccountResource: AccountResource = {
  info: GET(`${ACCOUNT_RESOURCE_BASE_PATH}/:accountId/account-info`),
};

export default AccountResource;
