import COLLAPSED_LOGO from '@src/images/logo_collapsed.png';
import SINERGISE_LOGO from '@src/images/sentinelhub_by_planet_logo.svg';

import { CommonStylesConfig } from '../baseAppConfig.types';

const stylesConfig: CommonStylesConfig = {
  primaryTextColor: '#212121',
  secondaryTextColor: '#4A4A4A',
  terciaryTextColor: '#fff',
  quaternaryTextColor: '#212121',

  backgroundColor: '#fff',
  lightBackgroundColor: '#01282f',
  darkBackgroundColor: '#01282f',

  backgroundGrey: '#efefef',
  lightBackgroundGrey: '#fafafa',
  darkBackgroundGrey: '#cdcdd0',

  primaryColor: '#b6bf00',
  secondaryColor: '#01282f',
  terciaryColor: '#F27128',

  sidebarColor: '#01282f',
  selectedSidebarItemBackground: '#fff',

  iconColor: 'red',

  logo: SINERGISE_LOGO,
  collapsedLogo: COLLAPSED_LOGO,

  toastSuccessColor: 'hsl(185, 81%, 29%)',
  toastErrorColor: 'hsl(360, 64%, 55%)',
};
export default stylesConfig;
