import { Payer } from '../../meta/types/billing/billingInfo';
import {
  ExtendedVatResponse,
  VatVerificationResponse,
  CalculateVatRequestDto,
} from '../../meta/types/billing/vat';

import VatResource from '../../services/Dashboard/VatResource';

const EUROPEAN_VAT_COUNTRY_REGEX = /^(SI|AT|DE|HU|HR|IT|BE|BG|CY|CZ|DK|EE|ES|FI|FR|IE|LT|LU|LV|MT|NL|PL|PT|RO|SE|SK|EL|GR)$/;
const MAXAR_COUNTRY_AVAILABILITY_REGEX = /^(AL|DZ|AD|AM|AT|AZ|BY|BE|BA|BG|HR|CY|CZ|DK|EG|EE|FI|FR|DE|GR|HU|IS|IE|IL|IT|LV|LI|LT|LU|MK|MT|MD|MC|ME|MA|NL|NO|OM|PL|PT|RO|RS|SI|SK|ES|SE|CH|TN|TR|UA|GB)$/;
const AIRBUS_SPOT_COUNTRY_AVAILABILITY_REGEX = /^(VA|SM|AD|AU|AT|BE|BA|BG|HR|CY|CZ|DK|EE|FO|FI|FR|DE|GR|HU|IS|IE|IT|LV|LI|LT|LU|MT|MD|MC|ME|NL|NO|PL|PT|RO|RS|SK|SI|ES|SE|CH|GB)$/;
const AIRBUS_PLEIADES_COUNTRY_AVAILABILITY_REGEX = /^(SM|VA|AD|AU|AT|BE|BA|BG|HR|CY|CZ|DK|EE|FO|FI|FR|DE|GR|HU|IS|IE|IT|LV|LI|LT|LU|MT|MD|MC|ME|NL|NO|PL|PT|RO|RS|SK|SI|ES|SE|CH|GB|CA)$/;
export const EUROPEAN_VAT_REGEX = /^([a-zA-Z][a-zA-Z])(.*)$/;
const PLANET_BANNED_COUNTRIES = ['CN', 'CU', 'IR', 'KP', 'SY', 'RU', 'BY'];

export function isEuropeanVatRegistered(countryCode?: string) {
  if (!countryCode) {
    return false;
  }
  return EUROPEAN_VAT_COUNTRY_REGEX.test(countryCode.toUpperCase());
}

export const isMaxarEligible = (countryCode?: string) => {
  if (!countryCode) {
    return false;
  }
  return MAXAR_COUNTRY_AVAILABILITY_REGEX.test(countryCode.toUpperCase());
};

export const isAirbusSpotEligible = (countryCode?: string) => {
  if (!countryCode) {
    return false;
  }
  return AIRBUS_SPOT_COUNTRY_AVAILABILITY_REGEX.test(countryCode.toUpperCase());
};

export const isAirbusPleiadesEligible = (countryCode?: string) => {
  if (!countryCode) {
    return false;
  }
  return AIRBUS_PLEIADES_COUNTRY_AVAILABILITY_REGEX.test(countryCode.toUpperCase());
};

export const isPlanetEligible = (countryCode?: string) => {
  if (!countryCode) {
    return false;
  }
  return !PLANET_BANNED_COUNTRIES.includes(countryCode.toUpperCase());
};

export const extractFieldsFromVatVerificationResponse = (
  isCompany: boolean,
  vatVerificationResponse?: VatVerificationResponse,
) => {
  if (!isCompany) {
    return {
      address1: '',
      postalCode: '',
      city: '',
      companyName: undefined,
    };
  }
  const address1 = vatVerificationResponse ? vatVerificationResponse.address || '' : '';
  const postalCode = vatVerificationResponse ? `${vatVerificationResponse.zip}` || '' : '';
  const city = vatVerificationResponse ? vatVerificationResponse.city || '' : '';
  const companyName = vatVerificationResponse ? vatVerificationResponse.companyName : undefined;

  return { address1, postalCode, city, companyName };
};

export const getVatCalculatePayload = (
  netAmount: number,
  country: string,
  payerType: Payer,
  vatNumber?: string,
): CalculateVatRequestDto => {
  const request = {
    netAmount,
    countryCode: country.toUpperCase(),
    payerType,
  } as CalculateVatRequestDto;

  if (vatNumber) {
    if (isEuropeanVatRegistered(country) && !EUROPEAN_VAT_REGEX.exec(vatNumber)) {
      request.vatNumber = `${request.countryCode}${vatNumber}`;
    } else {
      request.vatNumber = vatNumber;
    }
  }

  return request;
};
export async function getVATCalculations(
  vatNumber: string,
  netAmount: number,
  paymentCountry: string,
  payerType: Payer,
): Promise<ExtendedVatResponse> {
  const vatCalculationPayload = getVatCalculatePayload(
    netAmount,
    paymentCountry,
    payerType,
    vatNumber,
  );

  const resp = await VatResource.calculate(vatCalculationPayload);
  return { ...resp, netAmount };
}
