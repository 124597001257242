export interface NewsEntryBase {
  id: number;
  title: string;
  message: string;
  clickToAction?: string;
  link?: string;
  stickyCategory?: StickyCategory;
  stickyDueDate?: Date;
}

export enum StickyCategory {
  NONE = 'NONE',
  ALL = 'ALL',
  DASHBOARD = 'DASHBOARD',
  CONFIGURATIONS = 'CONFIGURATIONS',
  COLLECTIONS = 'COLLECTIONS',
  BATCH = 'BATCH',
  STATAPIBATCH = 'STATAPIBATCH',
  TPDI = 'TPDI',
  USAGE = 'USAGE',
  BILLING = 'BILLING',
  SETTINGS = 'SETTINGS',
}
export interface NewsEntry extends NewsEntryBase {
  created: Date;
}

export interface ServerNewsEntry extends NewsEntryBase {
  created: string;
}

export function mapNewsEntry(newsEntry: ServerNewsEntry): NewsEntry {
  return {
    ...newsEntry,
    created: new Date(newsEntry.created),
  };
}
