import AccountResource from '@src/services/Identity/AccountResource';
import { StoreState } from '@src/meta/types/store';

import {
  Claims,
  RoleAssignmentMap,
  SentinelUser,
  AccountInfo,
} from '@modules/auth/components/types/user';
import { alertError } from '@src/lib/alert';
import { ApiError, getMessageFromApiError } from '@src/lib/api';
import { ErrorResponse } from '@src/meta/types/api';

import { mapAccountinfo } from '../../types/account';

export const getAccount = async (accountId: string): Promise<AccountInfo | null> => {
  try {
    const resp = await AccountResource.info({ accountId });
    return mapAccountinfo(resp);
  } catch (err) {
    console.error(err);
    const errMsg = getMessageFromApiError(err as ApiError<ErrorResponse<any>>);
    alertError(errMsg);
    return null;
  }
};

export function getRoleAssignmentMap(
  state: StoreState<SentinelUser, Claims>,
): RoleAssignmentMap | undefined {
  return state.account.account?.roleAssignments;
}
