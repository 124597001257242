import * as actionTypes from '../../constants/actionTypes';
import { SidebarTab } from '../../constants/paths/internal';
import { NestedBreadcrumb } from '../../meta/types/ui';

export interface ISetTab {
  type: actionTypes.SET_TAB;
  menuTab: SidebarTab;
}

export const setMenuTab = (menuTab: SidebarTab): ISetTab => ({
  type: actionTypes.SET_TAB,
  menuTab,
});

export type SetMenuTab = typeof setMenuTab;

export interface ISetSidebarCollapsed {
  type: actionTypes.SET_SIDEBAR_COLLAPSED;
  collapsed: boolean;
}

export const setSidebarCollapsed = (collapsed: boolean): ISetSidebarCollapsed => ({
  type: actionTypes.SET_SIDEBAR_COLLAPSED,
  collapsed,
});

export interface ISetBreadcrumb {
  type: actionTypes.SET_BREADCRUMB;
  breadcrumb: NestedBreadcrumb[];
}

export interface IAppendBreadcrumb {
  type: actionTypes.APPEND_BREADCRUMB;
  breadcrumb: NestedBreadcrumb;
}

export const setBreadcrumb = (breadcrumb: NestedBreadcrumb[]): ISetBreadcrumb => ({
  type: actionTypes.SET_BREADCRUMB,
  breadcrumb,
});

export const appendBreadcrumb = (breadcrumb: NestedBreadcrumb): IAppendBreadcrumb => ({
  type: actionTypes.APPEND_BREADCRUMB,
  breadcrumb,
});
