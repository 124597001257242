import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { ROOT_PATH } from '../../../../constants/paths/internal';
import { StoreState } from '../../../../meta/types/store';

export interface BaseRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

type PropsFromState = {
  isLoggedIn: boolean;
};

type Props = PropsFromState & BaseRouteProps;

const BaseRoute = ({ isLoggedIn, component: Component, ...rest }: Props) => {
  if (isLoggedIn) {
    return <Redirect to={ROOT_PATH} />;
  }
  return <Route {...rest} render={props => <Component {...props} />} />;
};

function mapStateToProps(state: StoreState): PropsFromState {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  };
}

const connected = connect(mapStateToProps, null)(BaseRoute);

export default connected;
