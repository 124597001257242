import { SH_SUPPORT_EMAIL } from '@src/constants/paths/internal';
import { AWS_EU_CENTRAL_1 } from '@src/meta/types/byoc/const';
import { AWS_EU_CENTRAL_1_COLLECTIONS } from '@src/lib/skin/const';

import baseAppConfig from '../baseAppConfig';
import stylesConfig from './stylesConfig';
import AppConfig from '../baseAppConfig.types';

const SINERGISE_HELP_LINKS = [
  { text: 'Documentation', link: 'https://docs.sentinel-hub.com' },
  { text: 'Forum', link: 'https://forum.sentinel-hub.com' },
  { text: 'FAQ', link: 'https://www.sentinel-hub.com/faq/' },
];

const config: AppConfig = {
  ...baseAppConfig,
  ...stylesConfig,
  displayTopPageBanners: true,
  helpLinks: SINERGISE_HELP_LINKS,
  contactLink: `mailto:${SH_SUPPORT_EMAIL}`,
  showMarketingPermissions: true,
  showValidTo: true,
  defaultByocLocation: AWS_EU_CENTRAL_1,
  getSupportedDeployedCollections: () => AWS_EU_CENTRAL_1_COLLECTIONS,
  oAuthClientsExpiryEnabled: true,
  showLinkToBrowserTab: true,
};
export default config;
