import config from '@config/config';

import api from '../../../lib/api';
import { ResourceFetch } from '../../../meta/types/api';
import { ServerNewsEntry } from '../../../meta/types/news';

const { GET } = api;

const RESOURCE_BASE_PATH = `${config.dashboardServiceBasePath}/dashboard/v1/news`;

export interface NewsResource {
  getNews: ResourceFetch<NewsResponse>;
}

export type NewsResponse = {
  member: ServerNewsEntry[];
};

const NewsResource: NewsResource = {
  getNews: GET(`${RESOURCE_BASE_PATH}`),
};

export default NewsResource;
