import React, { CSSProperties, ReactNode } from 'react';

type Props = {
  href: string | undefined;
  style?: CSSProperties;
} & {
  children: ReactNode;
};

const TargetBlankLink = ({ href, style, children }: Props) => {
  return (
    <a href={href} rel="noopener noreferrer" style={{ ...style }} target="_blank">
      {children}
    </a>
  );
};

export default TargetBlankLink;
