import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as Fathom from 'fathom-client';

import config from '@config/config';

export const withTracker = <P extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<P>,
) => {
  const trackPage = (page: string) => {
    if (config.analytics.enable) {
      Fathom.trackPageview({
        url: page,
      });
    }
  };

  return (props: P) => {
    useEffect(() => {
      trackPage(props.location.pathname + props.location.search);
      // eslint-disable-next-line
    }, [props.location.pathname, props.location.search]);

    return <WrappedComponent {...props} />;
  };
};

export default withTracker;
