import { useState, useEffect } from 'react';

type WindowSize = {
  width: number | undefined;
  height: number | undefined;
};

function useWindowSize(onlyOnMount = false) {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    if (!onlyOnMount) {
      window.addEventListener('resize', handleResize);
    }

    handleResize();

    return () => {
      if (!onlyOnMount) {
        window.removeEventListener('resize', handleResize);
      }
    };
    // eslint-disable-next-line
  }, []);

  return windowSize;
}

export default useWindowSize;
