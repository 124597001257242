import { css } from 'styled-components';

import config from '@config/config';

import DashboardCard from '../../../../../../../../components/common/DashboardCard';
import { styled } from '../../../../../../../../styles/Theme';

export const PaymentPickerStyle = styled.div`
  display: flex;

  section:not(:first-child) {
    margin-left: 25px !important;

    @media (max-width: 1200px) {
      margin-left: 0px !important;
      margin-top: 5rem;
    }
  }

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`;

export const Discount = styled.div`
  width: 90px;
  height: 90px;
  position: absolute;
  top: -45px;
  right: -45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: ${config.secondaryColor};
  font-weight: bold;
  font-size: 1.1rem;
  background: ${props => props.theme.primaryColor};
`;

type PaymentCardStyleProps = {
  active: boolean;
  asFactor?: boolean;
};

export const FrequencyTypeCard = styled(DashboardCard)<PaymentCardStyleProps>`
  width: 250px;
  height: ${props => (props.asFactor === true ? '230px' : '150px')};
  text-align: center;
  color: black;
  transition: transform 0.5s ease;
  position: relative;
  background-color: ${config.backgroundColor};
  border: 2px solid ${config.backgroundGrey};

  .Info {
    font-size: 1rem !important;
    color: ${props => props.theme.primaryTextColor};
  }

  .Price {
    font-size: 1.2rem;
    margin: 0px;
  }

  .Header {
    padding: 0.75rem 0;
    background-color: ${config.darkBackgroundGrey};
  }

  ${props => (props.active ? SelectedStyle : NonSelectedStyle)}

  :hover {
    cursor: pointer;
  }
`;

const SelectedStyle = css`
  border: 2px solid ${props => props.theme.primaryColor};
`;

const NonSelectedStyle = css`
  :hover {
    background: #ddd;
  }
`;
