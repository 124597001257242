import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import config from '@config/config';

import AsyncLoad from '@src/components/common/AsyncLoad';
import { BaseAuthRoute } from '@src/components/common/Route';
import { withTracker } from '@src/hocs';

import { Props, TpdiType } from './types';

export const RouteContainer = posed.div({
  enter: { opacity: 1, beforeChildren: true, delay: 125 },
  exit: { opacity: 0 },
});

const AsyncTpdiPage = AsyncLoad({
  loader: () => import('./components/TpdiPage'),
}) as React.FunctionComponent;
const AsyncTpdiPageWithTracker = withTracker(AsyncTpdiPage);

function TpdiBase({}: Props): TpdiType {
  return () => {
    return (
      <PoseGroup className="tpdi-routes-container">
        <RouteContainer key="tpdi-routes-container">
          <BaseAuthRoute path={config.TPDI_PATH} component={AsyncTpdiPageWithTracker} />
        </RouteContainer>
      </PoseGroup>
    );
  };
}
export * from './types';
export { TpdiBase };
export default TpdiBase({});
