import { styled } from '../../../styles/Theme';

export default styled.span`
  color: #4183c4;
  font-weight: 500;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
