import { parse } from 'querystring';
import { v4 as uuidv4 } from 'uuid';

import {
  isSubscriptionPlan,
  isQuotaPlan,
} from '@modules/billing/components/billing/subscriptions/BillingPlanTypes/const';
import { PaypalEnv } from '@src/meta/types/billing/paypal';
import config from '@config/config';
import { TOP_UP_QUOTA_SUFFIX } from '@src/config/default/modules/Billing/components/billing/subscriptions/BillingTypePlanCard/PlanetCoTermModal';
import { UserBillingInfoFormErrors } from '@src/config/default/modules/Billing/components/billing/user-billing-info/UserBillingInfoForm';
import { getIneligibleItems } from '@src/config/default/modules/Billing/components/Checkout/CheckoutAccordion/utils';
import { BillingInfoDto, Payer, isPayerTypeCompany } from '@src/meta/types/billing/billingInfo';

import { CartItem } from '../../meta/types/billing/cart';
import { PaymentType, PaymentFrequencyType, CurrencyCode } from '../../meta/types/billing/payment';
import {
  BILLING_PLAN_MANAGEMENT_OFFER,
  SalesPackage,
  PaymentStrategy,
  SalesPackageSelector,
  BILLING_PLAN_RETRY,
} from '../../meta/types/billing/salespackage';
import { Order } from '../../meta/types/billing/order';
import { formatNumber } from '../ui';
import { alertError } from '../alert/index';
import { isEmailValid } from '../form';
import { isEuropeanVatRegistered } from '../vat';

export const MANAGEMENT_OFFER_QUERY_STRING = 'management-offer';
export const PAYPAL_ENV = config.paypalEnv || PaypalEnv.SANDBOX;

export function getActiveSalesPackageFromLocation(
  location = window.location,
): SalesPackageSelector | undefined {
  const search = location.href.split('?')[1];
  const queryPlan = parse(search).plan;
  return queryPlan
    ? {
        salesPackageId:
          queryPlan === MANAGEMENT_OFFER_QUERY_STRING
            ? BILLING_PLAN_MANAGEMENT_OFFER
            : Array.isArray(queryPlan)
            ? queryPlan[0]
            : queryPlan,
        paymentStrategyId: 0,
      }
    : undefined;
}

export function getSelectedPlanFromUrl(location = window.location): string | undefined {
  const search = location.href.split('?')[1];
  const queryPlan = parse(search).plan;
  if (typeof queryPlan === 'string') {
    return queryPlan;
  }
}

export function getCurrencyFromUrl(location = window.location): CurrencyCode | undefined {
  const search = location.href.split('?')[1];
  const currency = parse(search).currency;
  if (
    typeof currency === 'string' &&
    Object.values(CurrencyCode).includes(currency as CurrencyCode)
  ) {
    return currency as CurrencyCode;
  }
}

export function getCartFromUrl(): CartItem[] {
  const plan = getSelectedPlanFromUrl();
  if (plan) {
    return [
      {
        id: uuidv4(),
        salesPackageId: plan,
        paymentStrategyId: 0,
        numberOfPackages: 1,
      },
    ];
  }
  return [];
}

export function getFilterPlansFromUrl(): string | undefined {
  const search = location.href.split('?')[1];
  const filter = parse(search).filter_plans;
  if (typeof filter === 'string') {
    return filter;
  }
}

export function getPlansTabFromUrl(): string | undefined {
  const search = location.href.split('?')[1];
  const filter = parse(search).plans_tab;
  if (typeof filter === 'string') {
    return filter;
  }
}

function constructPaymentStrategy(
  paymentFrequency: string | undefined,
  paymentFrequencyType: PaymentFrequencyType,
  netAmount: number,
  paymentOptions: PaymentType[] | undefined,
  payPalAgreementPlanId: string | undefined,
  currency?: CurrencyCode,
): PaymentStrategy {
  const paymentStrategy: PaymentStrategy = {
    id: 0,
    title: periodToHumanReadableText(paymentFrequency),
    netAmount,
    paymentFrequencyType,
    paymentFrequency,
    paymentOptions: paymentOptions || [],
    currency,
  };
  if (payPalAgreementPlanId) {
    paymentStrategy.payPalPlanId = payPalAgreementPlanId;
  }
  return paymentStrategy;
}

function constructSalesPackage(
  name: string,
  description: string,
  planId: string,
  paymentStrategies: PaymentStrategy[],
  offer?: Order,
) {
  const subscriptionPlan: SalesPackage = {
    id: 0,
    planId,
    name,
    shortName: name,
    description,
    invoiceDescription: description,
    paymentStrategies,
    displayRow: 0,
    purchaseAction: 'Upgrade',
  };
  if (offer) {
    subscriptionPlan.offer = offer;
  }
  return subscriptionPlan;
}

export const existingOrderToSubscriptionPlan = (order: Order, isRetry?: boolean): SalesPackage => {
  let actualNetAmount;
  if (order.data.itemCount && order.data.itemCount > 1) {
    actualNetAmount = order.data.netAmount / order.data.itemCount;
  } else {
    actualNetAmount = order.data.netAmount;
  }
  const paymentStrategy: PaymentStrategy = constructPaymentStrategy(
    order.data.paymentFrequency,
    order.data.paymentFrequencyType,
    actualNetAmount,
    order.data.paymentOptions,
    order.data.payPalAgreementPlanId,
    order.data.currency,
  );
  return constructSalesPackage(
    order.data.name,
    order.data.description,
    isRetry ? BILLING_PLAN_RETRY : BILLING_PLAN_MANAGEMENT_OFFER,
    [paymentStrategy],
    order,
  );
};

const PERIOD_PATTERN = /^P([0-9]+).*$/;

function paymentFrequencyText(period?: string): string {
  if (!period) {
    return '';
  }
  const match = period.match(PERIOD_PATTERN);
  if (!match) {
    return '/month';
  }
  const months = parseInt(match[1], 10);

  return months <= 1 ? '/month' : `/${months} months`;
}

export function periodToHumanReadableText(period?: string): string {
  if (!period) {
    return 'Single';
  }
  const match = period.match(PERIOD_PATTERN);
  if (!match) {
    return 'Single';
  }
  const months = parseInt(match[1], 10);

  switch (months) {
    case 1:
      return 'Monthly';
    case 3:
      return 'Quarterly';
    case 6:
      return 'Half-yearly';
    case 12:
      return 'Yearly';
    default:
      return `/${months} months`;
  }
}

export function formattedPaymentAmount(
  value: number,
  isRecurring: boolean,
  currencyCode: CurrencyCode,
  period?: string,
): string {
  const netAmount = `${formatNumber(value, 2, true)} ${currencyCode}`;
  return isRecurring ? `${netAmount}${paymentFrequencyText(period)}` : netAmount;
}

export function supportsPaymentOption(
  paymentPlan: PaymentStrategy | undefined,
  orderPaymentOptions: PaymentType[] | undefined,
  paymentType: PaymentType,
): boolean {
  const subscriptionPlanOptions = paymentPlan ? paymentPlan.paymentOptions : [];
  return (
    subscriptionPlanOptions.includes(paymentType) &&
    (orderPaymentOptions ? orderPaymentOptions.includes(paymentType) : true)
  );
}

export function getPrice(paymentStrategy: PaymentStrategy, amountFactorPackages: number): number {
  return paymentStrategy.showcaseNetAmount
    ? paymentStrategy.showcaseNetAmount
    : paymentStrategy.asFactor === true
    ? paymentStrategy.netAmount * amountFactorPackages
    : paymentStrategy.netAmount;
}

export function getPackagesPrice(
  paymentStrategy: PaymentStrategy,
  amountFactorPackages: number,
): string {
  return formatNumber(
    paymentStrategy.asFactor === true
      ? paymentStrategy.netAmount * amountFactorPackages
      : paymentStrategy.netAmount,
    2,
    true,
  );
}

class InvalidCartError extends Error {
  constructor() {
    super('Invalid cart!');
    this.name = this.constructor.name;
  }
}

function getPaymentStrategy(cartItem: CartItem, salesPackages: SalesPackage[]): PaymentStrategy {
  const salesPackage = salesPackages.find(p => p.planId === cartItem.salesPackageId);
  if (!salesPackage) {
    throw new InvalidCartError();
  }
  const paymentStrategy = salesPackage.paymentStrategies.find(
    s => s.id === cartItem.paymentStrategyId,
  );
  if (!paymentStrategy) {
    throw new InvalidCartError();
  }
  return paymentStrategy;
}

export function calculateTotalNetAmount(cart: CartItem[], salesPackages: SalesPackage[]): number {
  let total = 0;
  for (const item of cart) {
    const paymentStrategy = getPaymentStrategy(item, salesPackages);
    if (!paymentStrategy) {
      throw new Error('Invalid cart!');
    }
    total += paymentStrategy.netAmount * item.numberOfPackages;
  }
  return total;
}

export function getPaymentFrequencyInfo(cart: CartItem[], salesPackages: SalesPackage[]) {
  let paymentFrequencyType;
  let paymentFrequency;

  for (const item of cart) {
    const paymentStrategy = getPaymentStrategy(item, salesPackages);
    const {
      paymentFrequencyType: itemPaymentFrequencyType,
      showcasePaymentFrequency,
    } = paymentStrategy;

    if (!paymentFrequencyType) {
      paymentFrequencyType = itemPaymentFrequencyType;
    } else if (itemPaymentFrequencyType !== paymentFrequencyType) {
      throw new Error();
    }

    if (!paymentFrequency) {
      paymentFrequency = showcasePaymentFrequency;
    } else if (showcasePaymentFrequency !== paymentFrequency) {
      throw new Error();
    }
  }
  return { paymentFrequencyType, paymentFrequency };
}

// Caution: This will affect the invoice name.
export const getOrderNameByPackage = (salesPackage: SalesPackage): string => {
  if (isSubscriptionPlan(salesPackage.planId)) {
    return `Sentinel Hub - ${salesPackage.name} subscription`;
  }
  if (isQuotaPlan(salesPackage.planId)) {
    return `${salesPackage.name} quota`;
  }
  return salesPackage.name;
};

export type PurchaseCheckoutPayload = {
  userId?: string;
  paymentType: PaymentType;
  userBillingInfoId: number;
  forceApplyVat?: boolean;
  offerId?: string;
  existingOrderId?: string;
  orderItems?: Array<OrderItem>;
};

export type OrderItem = {
  salesPackageId: string;
  itemCount: number;
  paymentStrategyId: number;
  additionalData?: AdditionalData;
};

export type AdditionalData = {
  planetscopeSubscriptionStartDate?: string;
};

export function getPurchasePayload(
  paymentType: PaymentType,
  userBillingInfoId: number,
  salesPackage: SalesPackage,
  paymentStrategyId: number,
  numberOfPackages: number,
  isOffer: boolean,
  vatVerificationError: boolean,
  userId: string,
): PurchaseCheckoutPayload {
  const purchasePayload: PurchaseCheckoutPayload = {
    userId,
    paymentType,
    userBillingInfoId,
    orderItems: [],
  };

  if (isOffer) {
    purchasePayload.offerId = salesPackage.offer!.id;
  } else if (salesPackage.planId === BILLING_PLAN_RETRY) {
    purchasePayload.existingOrderId = salesPackage.offer!.id;
  } else {
    const orderItem: OrderItem = {
      salesPackageId: salesPackage.planId,
      itemCount: numberOfPackages,
      paymentStrategyId,
    };
    if (salesPackage.planId.endsWith(TOP_UP_QUOTA_SUFFIX)) {
      if (!salesPackage.planetscopeSubscriptionStartDate) {
        alertError(
          'There was a problem constructing Top-up quota order! No subscription start date.',
        );
        return purchasePayload;
      }
      orderItem.additionalData = {
        planetscopeSubscriptionStartDate: salesPackage.planetscopeSubscriptionStartDate
          .utc()
          .startOf('day')
          .toISOString(),
      };
      orderItem.salesPackageId = orderItem.salesPackageId.replace(TOP_UP_QUOTA_SUFFIX, '');
    }
    purchasePayload.orderItems = [orderItem];
  }
  if (vatVerificationError) {
    purchasePayload.forceApplyVat = true;
  }
  return purchasePayload;
}

export function getValidity(paymentFrequencyType: PaymentFrequencyType) {
  switch (paymentFrequencyType) {
    case PaymentFrequencyType.RECURRING:
      return 'Until revoked';
    case PaymentFrequencyType.SINGLE:
      return '1 year';
    default:
      return;
  }
}

export function formatNumberToMax2DecimalPlaces(number?: number): string | undefined {
  if (number === undefined) {
    return undefined;
  }
  if ((number * 100) % 100 === 0) {
    return number.toString();
  }
  return number.toFixed(2);
}

export function validateCartItemsEligible(
  countryCode: string | undefined,
  cart: CartItem[],
): string[] {
  if (!countryCode) {
    return [] as string[];
  }
  const items = getIneligibleItems(cart, countryCode);
  return items;
}

export function validateBillingInfo(
  billingInfo: BillingInfoDto,
  validateCart: (countryCode: string) => string[],
) {
  const errors: UserBillingInfoFormErrors = {};
  if (!billingInfo.email || billingInfo.email === '') {
    errors.email = 'Required';
  } else if (!isEmailValid(billingInfo.email)) {
    errors.email = 'Invalid email address';
  }

  if (!billingInfo.address1 || billingInfo.address1 === '') {
    errors.address1 = 'Required';
  }

  if (!billingInfo.name || billingInfo.name === '') {
    errors.name = 'Required';
  }

  if (!billingInfo.postalCode || billingInfo.postalCode === '') {
    errors.postalCode = 'Required';
  }

  if (!billingInfo.defaultCurrency || billingInfo.defaultCurrency === '') {
    errors.defaultCurrency = 'Required';
  }

  if (!billingInfo.city || billingInfo.city === '') {
    errors.city = 'Required';
  }

  if (!billingInfo.industry || billingInfo.industry === '') {
    errors.industry = 'Required';
  }
  if (!billingInfo.subIndustry || billingInfo.subIndustry === '') {
    errors.subIndustry = 'Required';
  }
  if (!billingInfo.countryCode) {
    errors.countryCode = 'Required';
  } else if (validateCart(billingInfo.countryCode).length !== 0) {
    errors.countryCode = `Not available for ${validateCart(billingInfo.countryCode).join(',')}`;
  }

  if (isPayerTypeCompany(billingInfo.type)) {
    if (!billingInfo.companyName || billingInfo.companyName === '') {
      errors.companyName = 'Required';
    }
    if (
      billingInfo.type === Payer.VAT_REGISTERED_COMPANY &&
      (!billingInfo.vatNumber || billingInfo.vatNumber === '') &&
      isEuropeanVatRegistered(billingInfo.countryCode)
    ) {
      errors.vatNumber = 'Required';
    }
  }

  return errors;
}
