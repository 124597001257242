import { isAfter } from 'date-fns';

import { AccountInfo, ServerAccountInfo } from '../user';

export enum Account {
  FREE_ACCOUNT = 1,
  EO_BROWSER = 10000,
  ROOT = 20000,
  SH_SERVICE = 21000,
  TRIAL = 11000,
  EXPLORATION = 12000,
  BASIC = 13000,
  ENTERPRISE = 14000,
  ENTERPRISE_S = 14001,
  ENTERPRISE_L = 14002,
  PLANET_TRIAL = 11001,
}

export function mapAccountinfo(account: ServerAccountInfo): AccountInfo {
  return {
    ...account,
    validTo: new Date(account.validTo),
  };
}

export function isAccountValid(account?: AccountInfo): boolean {
  if (!account) {
    return false;
  }
  return isAfter(account.validTo, new Date());
}

export function isFreeAccount(type: Account) {
  return type === Account.FREE_ACCOUNT;
}
