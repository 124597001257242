import { AccountInfo } from '@modules/auth/components/types/user';
import { RoleAssignmentMap } from '@modules/auth/components/types/user/index';

import * as actionTypes from '../../constants/actionTypes';

export interface ISetAccount {
  type: actionTypes.SET_ACCOUNT;
  account: AccountInfo;
}

export const setAccount = (account: AccountInfo): ISetAccount => ({
  type: actionTypes.SET_ACCOUNT,
  account,
});

export interface ISetAccountFetching {
  type: actionTypes.SET_ACCOUNT_FETCHING;
  fetching: boolean;
}

export const setAccountFetching = (fetching: boolean): ISetAccountFetching => ({
  type: actionTypes.SET_ACCOUNT_FETCHING,
  fetching,
});

export interface ISetAssignmentGroups {
  type: actionTypes.SET_ROLE_ASSIGNMENT_GROUPS;
  rags: RoleAssignmentMap[];
}

export const setAssignmentGroups = (rags: RoleAssignmentMap[]): ISetAssignmentGroups => ({
  type: actionTypes.SET_ROLE_ASSIGNMENT_GROUPS,
  rags,
});
