import moment from 'moment-timezone';

import { Account, isFreeAccount } from '@modules/auth/components/types/account';
import { SalesPackage } from '@src/meta/types/billing/salespackage';
import { AccountInfo } from '@modules/auth/components/types/user';
import { isTrialUser } from '@modules/auth/components/constants/accounts';

import { TOP_UP_QUOTA_SUFFIX } from '../BillingTypePlanCard/PlanetCoTermModal';

export const PLANET_SCOPE_GROUP_ID = 'PLANET_SCOPE';
export const PLANET_SCOPE_1 = 'PLANET_SCOPE_1';
export const PLANET_SCOPE_2 = 'PLANET_SCOPE_2';
export const PLANET_SCOPE_3 = 'PLANET_SCOPE_3';
export const AIRBUS_SPOT_1 = 'AIRBUS_SPOT_1';
export const AIRBUS_PLEIADES_1 = 'AIRBUS_PLEIADES_1';
export const EUSI_MAXAR_1 = 'EUSI_MAXAR_1';
export const PLANET_SKYSAT = 'PLANET_SKYSAT';

export const PLANET_SCOPE_3_BASIC_1 = 'PLANET_SCOPE_3_BASIC_1';
export const PLANET_SCOPE_3_BASIC_2 = 'PLANET_SCOPE_3_BASIC_2';
export const PLANET_SCOPE_2_BASIC_1 = 'PLANET_SCOPE_2_BASIC_1';
export const PLANET_SCOPE_2_BASIC_2 = 'PLANET_SCOPE_2_BASIC_2';
export const PLANET_SCOPE_1_BASIC_1 = 'PLANET_SCOPE_1_BASIC_1';
export const PLANET_SCOPE_1_BASIC_2 = 'PLANET_SCOPE_1_BASIC_2';
export const PLANET_SCOPE_3_ENTERPRISE_S_1 = 'PLANET_SCOPE_3_ENTERPRISE_S_1';
export const PLANET_SCOPE_3_ENTERPRISE_S_2 = 'PLANET_SCOPE_3_ENTERPRISE_S_2';
export const PLANET_SCOPE_2_ENTERPRISE_S_1 = 'PLANET_SCOPE_2_ENTERPRISE_S_1';
export const PLANET_SCOPE_2_ENTERPRISE_S_2 = 'PLANET_SCOPE_2_ENTERPRISE_S_2';
export const PLANET_SCOPE_1_ENTERPRISE_S_1 = 'PLANET_SCOPE_1_ENTERPRISE_S_1';
export const PLANET_SCOPE_1_ENTERPRISE_S_2 = 'PLANET_SCOPE_1_ENTERPRISE_S_2';

const TOP_UP_PUS = 'TOP_UP_PUS';
const EXPLORATION = 'EXPLORATION';
const BASIC = 'BASIC';
const ENTERPRISE_S = 'ENTERPRISE_S';
const ENTERPRISE_L = 'ENTERPRISE_L';

const NON_TRIAL_PLANS = new Set([
  AIRBUS_SPOT_1,
  AIRBUS_PLEIADES_1,
  EUSI_MAXAR_1,
  PLANET_SCOPE_1,
  PLANET_SCOPE_2,
  PLANET_SCOPE_3,
  PLANET_SKYSAT,
  TOP_UP_PUS,
]);

const SUBSCRIPTION_PLANS = new Set([EXPLORATION, BASIC, ENTERPRISE_L, ENTERPRISE_S]);

const QUOTA_PLANS = new Set([
  AIRBUS_SPOT_1,
  EUSI_MAXAR_1,
  PLANET_SCOPE_1,
  AIRBUS_PLEIADES_1,
  PLANET_SCOPE_2,
  PLANET_SCOPE_3,
  PLANET_SKYSAT,
]);

export const isNonTrialPlan = (planId: string): boolean => {
  return NON_TRIAL_PLANS.has(planId);
};

export const isSubscriptionPlan = (planId: string): boolean => {
  return SUBSCRIPTION_PLANS.has(planId);
};

export const isQuotaPlan = (planId: string): boolean => {
  return QUOTA_PLANS.has(planId);
};

export const isPlanetPackage = (planId: string): boolean => {
  switch (planId) {
    case PLANET_SKYSAT:
    case PLANET_SCOPE_1:
    case PLANET_SCOPE_2:
    case PLANET_SCOPE_3:
    case PLANET_SCOPE_1 + TOP_UP_QUOTA_SUFFIX:
    case PLANET_SCOPE_2 + TOP_UP_QUOTA_SUFFIX:
    case PLANET_SCOPE_3 + TOP_UP_QUOTA_SUFFIX:
    case PLANET_SCOPE_1_BASIC_1:
    case PLANET_SCOPE_1_BASIC_2:
    case PLANET_SCOPE_1_ENTERPRISE_S_1:
    case PLANET_SCOPE_1_ENTERPRISE_S_2:
    case PLANET_SCOPE_3_BASIC_1:
    case PLANET_SCOPE_3_BASIC_2:
    case PLANET_SCOPE_3_ENTERPRISE_S_1:
    case PLANET_SCOPE_3_ENTERPRISE_S_2:
    case PLANET_SCOPE_2_BASIC_1:
    case PLANET_SCOPE_2_BASIC_2:
    case PLANET_SCOPE_2_ENTERPRISE_S_1:
    case PLANET_SCOPE_2_ENTERPRISE_S_2:
      return true;
    default:
      return false;
  }
};

export const isTopupDisabledForAccount = (account?: Account): boolean => {
  switch (account) {
    case Account.BASIC:
    case Account.ENTERPRISE:
    case Account.ENTERPRISE_S:
    case Account.ENTERPRISE_L:
      return false;
    default:
      return true;
  }
};

export const isTopUp = (planId: string): boolean => {
  return planId === TOP_UP_PUS;
};

export const isPlanDisabledForAccount = (planId: string, account?: Account): boolean => {
  if ((isTrialUser(account) || isFreeAccount(account!)) && isNonTrialPlan(planId)) {
    return true;
  }
  if (isTopUp(planId) && isTopupDisabledForAccount(account)) {
    return true;
  }
  return false;
};

export const getActionText = (
  plan: SalesPackage,
  shAccount: AccountInfo | undefined,
  existingPlan: SalesPackage | undefined,
  isPlanNotAvailable: boolean,
): string => {
  if (shAccount && shAccount.type) {
    if (plan.accountType?.includes(shAccount.type)) {
      if (isPlanNotAvailable) {
        return 'ACTIVE';
      }
      return 'EXTEND';
    }

    if (plan.accountType !== undefined) {
      if (shAccount.type === Account.ENTERPRISE) {
        return 'BUY';
      }

      if (existingPlan?.accountLevel !== undefined) {
        if (plan.accountLevel! < existingPlan.accountLevel) {
          return 'DOWNGRADE';
        }
        if (plan.accountLevel! > existingPlan.accountLevel) {
          return 'UPGRADE';
        }
      }
    }
  }
  return plan.purchaseAction.toLocaleUpperCase();
};

export const PLANET_SCOPE_1_AVAILABLE_COUNTRIES = [
  'Australia',
  'Canada',
  'Cayman Islands',
  'Cook Islands',
  'Coral Sea Islands',
  'Heard Island and McDonald Islands',
  'Japan',
  'New Zealand',
  'Niue',
  'Norfolk Island',
  'Puerto Rico',
  'Singapore',
  'Minor Outlying Islands United States of America',
  'United States',
  'United States Virgin Islands',
  'US Naval Base Guantanamo Bay',
  'Vatican',
];
export const PLANET_SCOPE_2_AVAILABLE_COUNTRIES = [
  'Andorra',
  'Anguilla',
  'Argentina',
  'Aruba',
  'Austria',
  'Belgium',
  'Bermuda',
  'Brazil',
  'British Indian Territory',
  'British Virgin Islands',
  'China',
  'Clipperton Island',
  'Colombia',
  'Curaçao',
  'Cyprus',
  'Denmark',
  'Dhekelia Sovereign Base Area',
  'Faroe Islands',
  'Finland',
  'France',
  'Germany',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Hong Kong S.A.R',
  'Iceland',
  'Ireland',
  'Italy',
  'Liechtenstein',
  'Luxembourg',
  'Malta',
  'Monaco',
  'Montserrat',
  'Netherlands',
  'North Korea',
  'Norway',
  'Pitcairn Islands',
  'Portugal',
  'Saint Barthelemy',
  'Saint Helena',
  'Saint Martin',
  'Saint Pierre and Miquelon',
  'San Marino',
  'Sint Maarten',
  'South Korea',
  'Spain',
  'Sweden',
  'Switzerland',
  'Taiwan',
  'United Kingdom',
  'Wallis and Futuna',
];
export const PLANET_SCOPE_3_AVAILABLE_COUNTRIES = [
  'Afghanistan',
  'Akrotiri Sovereign Base Area',
  'Aland',
  'Albania',
  'Algeria',
  'American Samoa',
  'Angola',
  'Antarctica',
  'Antigua and Barbuda',
  'Armenia',
  'Ashmore and Cartier Islands',
  'Azerbaijan',
  'Bahrain',
  'Bajo Nuevo Bank (Petrel Is.)',
  'Bangladesh',
  'Barbados',
  'Baykonur Cosmodrome',
  'Belarus',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Cape Verde',
  'Central African Republic',
  'Chad',
  'Chile',
  'Comoros',
  'Cook Islands',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Cyprus No Mans Area',
  'Czech Republic',
  'Democratic Republic of the Congo',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equitorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands',
  'Federated States of Micronesia',
  'Fiji',
  'French Polynesia',
  'French Southern and Antarctic Lands',
  'Gabon',
  'Gambia',
  'Georgia',
  'Ghana',
  'Grenada',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hungary',
  'India',
  'Indian Ocean Territories',
  'Indonesia',
  'Iran',
  'Iraq',
  'Isle of Man',
  'Israel',
  'Ivory Coast',
  'Jamaica',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Lithuania',
  'Macao S.A.R',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Marshall Islands',
  'Mauritania',
  'Mauritus',
  'Mexico',
  'Moldova',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'New Caledonia',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Northern Cyprus',
  'Northern Mariana Islands',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Qatar',
  'Republic of Congo',
  'Republic of Serbia',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Scarborough Reef',
  'Senegal',
  'Serranilla Bank',
  'Seychelles',
  'Siachen Glacier',
  'Sierra Leone',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'Somaliland',
  'South Africa',
  'South Georgia and South Sandwich Islands',
  'South Sudan',
  'Spratly Islands',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Syria',
  'Tajikistan',
  'Thailand',
  'The Bahamas',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Republic of Tanzania',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
];

export const MAXAR_WORLDVIEW_AVAILABLE_COUNTRIES = [
  'Albania',
  'Algeria',
  'Andorra',
  'Armenia',
  'Austria',
  'Azerbaijan',
  'Belarus',
  'Belgium',
  'Bosnia and Herzegovina',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Egypt',
  'Estonia',
  'Finland',
  'France',
  'Georgia',
  'Germany',
  'Greece',
  'Hungary',
  'Iceland',
  'Ireland',
  'Israel',
  'Italy',
  'Kosovo',
  'Latvia',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macedonia',
  'Malta',
  'Moldova',
  'Monaco',
  'Montenegro',
  'Morocco',
  'Netherlands',
  'Norway',
  'Poland',
  'Portugal',
  'Romania',
  'San Marino',
  'Serbia',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
  'Switzerland',
  'Tunisia',
  'Turkey',
  'Ukraine',
  'United Kingdom',
  'Vatican City',
];
