import styled, { css } from 'styled-components';
import config from '@config/config';

export type ListItemProps = {
  disabled: boolean;
  active: boolean;
};

export const SidebarListItemContent = styled.div<ListItemProps>`
  display: flex;
  align-items: center;
  padding: 12px 18px;
  color: ${props => (props.active ? config.primaryTextColor : config.terciaryTextColor)};
  white-space: nowrap;

  #external-link-icon-wrapper {
    visibility: hidden;
  }

  :hover {
    background: ${() => config.selectedSidebarItemBackground};
    color: ${() => config.primaryTextColor} ${props => !props.disabled && ActiveStyle};

    #external-link-icon-wrapper {
      visibility: visible;
    }
  }

  i.collapsed {
    height: auto;
    width: 100%;
    margin: 0px;
    padding: 0px;
  }
`;

export const ActiveStyle = css`
  cursor: pointer;
`;
