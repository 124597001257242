import { CartItem } from '@src/meta/types/billing/cart';
import { SalesPackage } from '@src/meta/types/billing/salespackage';
import { Account } from '@modules/auth/components/types/account';

import {
  isMaxarEligible,
  isAirbusSpotEligible,
  isAirbusPleiadesEligible,
  isPlanetEligible,
} from '@src/lib/vat';
import {
  EUSI_MAXAR_1,
  AIRBUS_SPOT_1,
  AIRBUS_PLEIADES_1,
  isPlanDisabledForAccount,
  isPlanetPackage,
} from '../../billing/subscriptions/BillingPlanTypes/const';

export function getIneligibleItems(cart: CartItem[], countryCode: string): string[] {
  const ineligibleItems = [];
  for (const item of cart) {
    if (
      (item.salesPackageId === EUSI_MAXAR_1 && !isMaxarEligible(countryCode)) ||
      (item.salesPackageId === AIRBUS_SPOT_1 && !isAirbusSpotEligible(countryCode)) ||
      (item.salesPackageId === AIRBUS_PLEIADES_1 && !isAirbusPleiadesEligible(countryCode)) ||
      (isPlanetPackage(item.salesPackageId) && !isPlanetEligible(countryCode))
    ) {
      ineligibleItems.push(item.salesPackageId);
    }
  }
  return ineligibleItems;
}

export function checkIsCartValid(cart: CartItem[], salesPackages: SalesPackage[]): boolean {
  return cart.every(cartItem => !!salesPackages.find(p => p.planId === cartItem.salesPackageId));
}

export function areCartItemsAvailableForAccount(cart: CartItem[], account?: Account): boolean {
  return cart.every(cartItem => !isPlanDisabledForAccount(cartItem.salesPackageId, account));
}
