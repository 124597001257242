import config from '@config/config';

import { StickyCategory } from '../../meta/types/news';
import { ROOT_PATH } from '../../constants/paths/internal';

export const pathToStickyCategory = (pathname: string): StickyCategory | undefined => {
  if (pathname === ROOT_PATH) {
    return StickyCategory.DASHBOARD;
  }
  if (pathname.includes(config.CONFIGURATIONS_UTILITY_PATH)) {
    return StickyCategory.CONFIGURATIONS;
  }
  if (pathname.includes(config.BYOC_PATH)) {
    return StickyCategory.COLLECTIONS;
  }
  if (pathname.includes(config.BATCH_PATH)) {
    return StickyCategory.BATCH;
  }
  if (pathname.includes(config.STATAPI_BATCH_PATH)) {
    return StickyCategory.STATAPIBATCH;
  }
  if (pathname.includes(config.TPDI_PATH)) {
    return StickyCategory.TPDI;
  }
  if (pathname.includes(config.USAGE_PATH)) {
    return StickyCategory.USAGE;
  }
  if (pathname.includes(config.BILLING_PATH)) {
    return StickyCategory.BILLING;
  }
  if (pathname.includes(config.USER_SETTINGS_PATH)) {
    return StickyCategory.SETTINGS;
  }
  return undefined;
};
