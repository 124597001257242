import {
  SalesPackage,
  BILLING_PLAN_MANAGEMENT_OFFER,
  BILLING_PLAN_RETRY,
} from '@src/meta/types/billing/salespackage';
import { CurrencyCode, Currency } from '@src/meta/types/billing/payment';
import { CartItem } from '@src/meta/types/billing/cart';

export function getSalesPackagesWithCurrency(
  salespackages: SalesPackage[],
  currency: CurrencyCode,
): SalesPackage[] {
  return salespackages
    .filter(s =>
      s.paymentStrategies.some(
        ps =>
          ps.currency === currency ||
          [BILLING_PLAN_MANAGEMENT_OFFER, BILLING_PLAN_RETRY].includes(s.planId),
      ),
    )
    .map(s => ({
      ...s,
      paymentStrategies: s.paymentStrategies.filter(
        ps =>
          ps.currency === currency ||
          [BILLING_PLAN_MANAGEMENT_OFFER, BILLING_PLAN_RETRY].includes(s.planId),
      ),
    }));
}

export function getCurrencyFromCode(
  selectedCurrency: CurrencyCode,
  currencies: Currency[],
): Currency {
  return currencies.find(c => c.code === selectedCurrency)!;
}

export function getCurrencyString(amount: number | string, currency: Currency): string {
  return `${amount} ${currency.code}`;
}

export function getSalesPackageAndPaymentStrategyFromCartItem(
  cartItem: CartItem,
  salesPackages: SalesPackage[],
) {
  const salesPackage = salesPackages.find(p => p.planId === cartItem.salesPackageId)!;
  const paymentStrategy = salesPackage.paymentStrategies.find(
    s => s.id === cartItem.paymentStrategyId,
  )!;
  return { salesPackage, paymentStrategy };
}

export function getAndEnsureCartCurrency(
  cart: CartItem[],
  salesPackages: SalesPackage[],
): CurrencyCode {
  let currencyCode: CurrencyCode | undefined;
  for (const cartItem of cart) {
    const { paymentStrategy } = getSalesPackageAndPaymentStrategyFromCartItem(
      cartItem,
      salesPackages,
    );
    if (
      paymentStrategy.currency &&
      currencyCode !== undefined &&
      paymentStrategy.currency !== currencyCode
    ) {
      console.error('Mixed currencies in cart!');
      throw new Error('Invalid cart!');
    } else {
      currencyCode = paymentStrategy.currency;
    }
  }
  if (currencyCode === undefined) {
    return CurrencyCode.EUR;
  }
  return currencyCode;
}
