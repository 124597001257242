import produce, { Draft } from 'immer';

import { Dictionary } from 'lodash';
import {
  IAddInstance,
  IAddLayer,
  IDeleteInstance,
  IDeleteLayer,
  ISelectInstance,
  ISetInstances,
  ISetInstancesFetching,
  ISetLayerDataProduct,
  ISetLayers,
  ISetTemplateInstances,
  ISetTemplateInstancesFetching,
  IUpdateInstance,
  IUpdateLayer,
  ISetTemplateInstanceLayers,
} from '../../actions/instances';
import * as actionTypes from '../../constants/actionTypes';
import { Instance } from '../../meta/types/wms/Instance';

export const initialState = Object.freeze({
  instances: {} as Dictionary<Instance>,
  instancesLoaded: false,
  instancesFetching: false,
  templateInstances: [] as Instance[],
  templateInstancesFetching: false,
  templateInstancesLoaded: false,
});

export type InstancesState = typeof initialState & {
  selectedInstance?: Instance;
};

type InstancesAction =
  | ISetTemplateInstancesFetching
  | ISetTemplateInstanceLayers
  | ISetTemplateInstances
  | ISetInstances
  | ISetInstancesFetching
  | IAddInstance
  | IUpdateInstance
  | ISelectInstance
  | IAddLayer
  | IDeleteLayer
  | ISetLayerDataProduct
  | IUpdateLayer
  | IDeleteInstance
  | ISetLayers;

const instances = (state = initialState, action: InstancesAction) => {
  return produce<InstancesState>(state, (draft: Draft<InstancesState>) => {
    switch (action.type) {
      case actionTypes.UPDATE_LAYER:
        draft.instances[action.instanceId].layers = draft.instances[
          action.instanceId
        ].layers!.map(l => (l.id === action.layer.id ? action.layer : l));
        return;
      case actionTypes.SET_LAYER_DATA_PRODUCT:
        draft.instances[draft.selectedInstance!.id].layers!.find(
          l => l.id === action.layerId,
        )!.styles[0].dataProduct = action.dataProduct;
        return;
      case actionTypes.DELETE_INSTANCE:
        delete draft.instances[action.id];
        return;
      case actionTypes.DELETE_LAYER:
        draft.instances[draft.selectedInstance!.id].layers = draft.instances[
          draft.selectedInstance!.id
        ].layers!.filter(l => l.id !== action.layerId);
        return;
      case actionTypes.SELECT_INSTANCE:
        draft.selectedInstance = action.instance;
        return;
      case actionTypes.UPDATE_INSTANCE:
        draft.instances[action.instance.id] = {
          ...action.instance,
          layers: draft.instances[action.instance.id].layers,
        };
        return;
      case actionTypes.ADD_INSTANCE:
        draft.instances[action.instance.id] = action.instance;
        return;
      case actionTypes.SET_INSTANCES:
        draft.instancesFetching = false;
        draft.instancesLoaded = true;
        for (const instance of action.instances) {
          draft.instances[instance.id] = instance;
        }
        return;
      case actionTypes.SET_INSTANCES_FETCHING:
        draft.instancesFetching = action.instancesFetching;
        return;
      case actionTypes.SET_TEMPLATE_INSTANCES_FETCHING:
        draft.templateInstancesFetching = action.templateInstancesFetching;
        return;
      case actionTypes.SET_TEMPLATE_INSTANCES:
        draft.templateInstancesFetching = false;
        draft.templateInstancesLoaded = true;
        draft.templateInstances = action.templateInstances.map(instance => ({
          ...instance,
          layers: undefined,
        }));
        return;
      case actionTypes.SET_TEMPLATE_INSTANCE_LAYERS:
        const templateInstanceToUpdate = draft.templateInstances.find(
          instance => instance.id === action.instanceId,
        );
        if (templateInstanceToUpdate !== undefined) {
          templateInstanceToUpdate.layers = action.layers;
        }
        return;
      case actionTypes.SET_LAYERS:
        draft.instances[action.instanceId].layers = action.layers;
        return;
      case actionTypes.ADD_LAYER:
        draft.instances[draft.selectedInstance!.id].layers!.push(action.layer);
        return;
      default:
        return;
    }
  });
};

export default instances;
