import { Dictionary } from 'lodash';
import { Polygon, Geometry } from 'geojson';
import config from '@config/config';

import { CollectionTilesResponse, SearchQuery } from '../../../services/Byoc/TileResource';
import { Links } from '../api';
import { BatchRequest } from '../batch';
import { ByocLayerSubtype } from '../wms/Layer';
import { AreaOfInterest } from '../geo-json';
import {
  AWS_EU_CENTRAL_1,
  AWS_US_WEST_2,
  SGS_HQ,
  CREODIAS,
  CODE_DE,
  EO_LAB,
  CDSE,
  GCP_US_CENTRAL_1,
  CDSE_WAW3_2,
} from './const';

export interface ByocCollectionIdentifiable {
  id: string;
}

export type CollectionTypeResponse = ByocLayerSubtype | undefined;

export interface NewByocCollection {
  name: string;
  s3Bucket: string;
  noData?: number;
  crs?: string;
  path?: string;
  isConfigured?: boolean;
  additionalData?: ByocCollectionAdditionalData;
  accountId: string;
  storageIdentifier?: string;
}

export type UpdateByocCollection = NewByocCollection & {
  id: string;
};

export type ByocCollection = ByocCollectionIdentifiable &
  NewByocCollection & {
    userId: string;
    created?: Date;
    additionalData?: ByocCollectionAdditionalData;
    type?: CollectionTypeResponse;
    crs?: string;
    requiresMetadataUpdate?: boolean;
  };

export type ByocCollectionWithLocation = ByocCollection & {
  location: string;
};

export type GlobalCollection = {
  id: string;
  userId: string;
  name: string;
  s3Bucket: string;
  location: string;
  created?: Date;
};

export interface ByocCollectionTilesIdentifiable {
  collectionId: string;
}

export type ByocCollectionTileIdenfitiable = ByocCollectionTilesIdentifiable &
  ByocCollectionIdentifiable;

export interface ByocCollectionAdditionalData {
  bands?: Bands | null;
  maxMetersPerPixel?: number;
  extent?: AreaOfInterest;
  fromSensingTime?: Date;
  toSensingTime?: Date;
  hasSensingTimes?: 'YES' | 'PARTIALLY' | 'NO';
  storageIdentifier?: string;
}

export type Bands = Dictionary<BandFormat>;

export type ByocBandSpec = Dictionary<ByocBandFormat>;

export interface BandFormat {
  bitDepth: number;
  aliases?: string[];
}

export enum SampleFormat {
  'UINT' = 'UINT',
  'INT' = 'INT',
  'FLOAT' = 'FLOAT',
}
export interface ByocBandFormat {
  bitDepth: number;
  bandIndex?: number;
  source?: string;
  noData?: number;
  sampleFormat?: SampleFormat;
  aliases?: string[];
}

interface ByocTileBase {
  id: string;
  path: string;
  tileGeometry: Polygon;
  coverGeometry: AreaOfInterest;
  status: string;
  additionalData: ByocTileAdditionalData;
}

export interface ByocTile extends ByocTileBase {
  sensingTime?: Date;
  created: Date;
}

export interface ByocServerTile extends ByocTileBase {
  sensingTime?: Date;
  created: Date;
}

export interface ByocTileAdditionalData {
  failedIngestionCause?: string;
  warnings?: string;
}

export type NewByocTile = ByocCollectionTilesIdentifiable & {
  path: string;
  sensingTime?: string;
};

export type CollectionTilesCacheValue = {
  fetching: boolean;
  query?: SearchQuery;
  tilesPromise?: Promise<CollectionTilesResponse>;
  tiles: ByocTile[];
  links: Links;
};

export type ByocTilesCache = Dictionary<CollectionTilesCacheValue>;

export type ByocCollectionsCache = {
  [index: string]: CollectionCacheRecord;
};

export type BatchRequestsCollectionCache = {
  [index: string]: BatchRequest;
};

export type CollectionCacheRecord = {
  fetchedState: FetchedState;
  errorMessage?: string;
  collection: ByocCollectionWithLocation;
  geometry?: Geometry;
  batchRequestsCollectionCache?: BatchRequestsCollectionCache;
};

export enum FetchedState {
  NOT_FETCHED = 'NOT_FETCHED',
  FETCHING = 'FETCHING',
  FETCHING_FAILED = 'FETCHING_FAILED',
  FETCHED = 'FETCHED',
}

export const LOCATION_NAMES: { [s: string]: string } = {
  [AWS_EU_CENTRAL_1]: 'AWS EU (Frankfurt)',
  [AWS_US_WEST_2]: 'AWS US West (Oregon)',
  [SGS_HQ]: 'Sinergise Test / AWS EU (Frankfurt)',
  [GCP_US_CENTRAL_1]: 'GCP US Central',
  [CREODIAS]: 'CREODIAS',
  [CODE_DE]: 'CODE-DE',
  [EO_LAB]: 'EO Lab',
  [CDSE]: 'WAW3-1',
  [CDSE_WAW3_2]: 'WAW3-2',
};

export const BATCH_LOCATION_CONFIG: { [s: string]: string | undefined } = {
  [AWS_EU_CENTRAL_1]: config.byocEuCentralServiceBasePath,
  [AWS_US_WEST_2]: config.byocUsWestServiceBasePath,
  [CREODIAS]: config.byocCreodiasServiceBasePath,
};

export const locationToBasePath = (locationConfig: {
  [s: string]: string | undefined;
}): { [s: string]: string } => {
  const locationToBasePath: { [s: string]: string } = {};

  for (const location in locationConfig) {
    if (locationConfig[location] !== undefined) {
      locationToBasePath[location] = locationConfig[location]!;
    }
  }

  return locationToBasePath;
};

export function mapByocTile(tile: ByocServerTile): ByocTile {
  return {
    ...tile,
    sensingTime: tile.sensingTime,
  };
}
