import produce, { Draft } from 'immer';
import { v4 as uuidv4 } from 'uuid';

import { CountryListType } from '@src/constants/countries';
import {
  IAddPurchaseOrder,
  IAddUserBillingInfo,
  ICancelPaypalOrder,
  ICloseManagementOrderPopup,
  IConfirmPurchaseOrder,
  ISetActiveSalesPackage,
  ISetCountries,
  ISetCountriesFetching,
  ISetFetchingSubscriptionPlans,
  ISetManagementIssuedOrder,
  ISetManagementIssuedOrderFetching,
  ISetPayerType,
  ISetPayerVatNumber,
  ISetPayerVatRegistered,
  ISetPaymentBillingInfo,
  ISetPaymentCountry,
  ISetPurchaseOrders,
  ISetPurchaseOrdersFetching,
  ISetPurchasingManagementIssuedOrder,
  ISetSubscriptionPlans,
  ISetUserBillingInfo,
  ISetUserBillingInfoFetching,
  ISetVatCalculations,
  ISetVatVerificationResponse,
  IUpdatePurchaseOrder,
  IUpdateUserBillingInfo,
  ISetAmountFactorPackages,
  ISetClientPackage,
  IAddToCart,
  IRemoveFromCart,
  ISetSelectedUserBillingInfo,
  ISetRetryOrder,
  ISetSupportedCurrencies,
  ISetSelectedCurrency,
} from '../../actions/billing';
import * as actionTypes from '../../constants/actionTypes';
import {
  existingOrderToSubscriptionPlan,
  getActiveSalesPackageFromLocation,
  getCartFromUrl,
  getCurrencyFromUrl,
} from '../../lib/billing';
import { Payer, UserBillingInfoDto } from '../../meta/types/billing/billingInfo';
import { CartItem } from '../../meta/types/billing/cart';
import { Order, OrderStatus } from '../../meta/types/billing/order';
import { CurrencyCode, Currency } from '../../meta/types/billing/payment';
import {
  BILLING_PLAN_MANAGEMENT_OFFER,
  SalesPackage,
  BILLING_PLAN_RETRY,
} from '../../meta/types/billing/salespackage';
import {
  CountryDto,
  VatVerificationResponse,
  ExtendedVatResponse,
} from '../../meta/types/billing/vat';

export const initialState = Object.freeze({
  countries: {} as Record<CountryListType, CountryDto[]>,
  fetchingCountries: false,

  purchaseOrders: [] as Order[],
  fetchingPurchaseOrders: false,
  purchaseOrdersFetched: false,

  fetchingSubscriptionPlans: true,
  salesPackages: [] as SalesPackage[],

  fetchingUserBillingInfo: false,
  userBillingInfoFetched: false,
  userBillingInfo: [] as UserBillingInfoDto[],
  selectedUserBillingInfo: undefined as number | undefined,

  fetchingManagementIssuedOrder: false,
  managementOrderPopupClosed: false,
  amountFactorPackages: 1,

  // Billing flow,
  activeSalesPackage: getActiveSalesPackageFromLocation(),
  payerVatRegistered: true,
  payerVatNumber: '',
  isPurchasingManagementIssuedOrder: false,

  cart: getCartFromUrl(),

  hasSetInitialCurrency: false,
  supportedCurrencies: [] as Currency[],
  selectedCurrency: getCurrencyFromUrl() || CurrencyCode.EUR,
});

export type BillingState = typeof initialState & {
  managementIssuedOrder?: Order;
  payerType?: Payer;
  paymentCountry?: string;
  vatVerificationResponse?: VatVerificationResponse;
  paymentBillingInfo?: UserBillingInfoDto;
  vatCalculations?: ExtendedVatResponse;
  cart: CartItem[];
};

type BillingAction =
  | ISetUserBillingInfo
  | ISetUserBillingInfoFetching
  | IAddUserBillingInfo
  | IAddPurchaseOrder
  | IConfirmPurchaseOrder
  | ICancelPaypalOrder
  | ISetManagementIssuedOrderFetching
  | ISetManagementIssuedOrder
  | ICloseManagementOrderPopup
  | IUpdateUserBillingInfo
  | ISetCountries
  | ISetCountriesFetching
  | ISetPurchaseOrders
  | ISetPurchaseOrdersFetching
  | ISetFetchingSubscriptionPlans
  | IUpdatePurchaseOrder
  | ISetSubscriptionPlans
  | ISetActiveSalesPackage
  | ISetClientPackage
  | ISetPaymentCountry
  | ISetPayerVatRegistered
  | ISetPayerVatNumber
  | ISetVatVerificationResponse
  | ISetPaymentBillingInfo
  | ISetVatCalculations
  | ISetPurchasingManagementIssuedOrder
  | ISetAmountFactorPackages
  | ISetPayerType
  | IAddToCart
  | IRemoveFromCart
  | ISetSelectedUserBillingInfo
  | ISetRetryOrder
  | ISetSupportedCurrencies
  | ISetSelectedCurrency;

const billing = (state: BillingState = initialState, action: BillingAction) => {
  return produce<BillingState>(state, (draft: Draft<BillingState>) => {
    switch (action.type) {
      case actionTypes.SET_PURCHASING_MANAGEMENT_ISSUED_ORDER:
        if (state.managementIssuedOrder) {
          draft.isPurchasingManagementIssuedOrder = action.purchasingManagementIssuedOrder;
          draft.activeSalesPackage = {
            salesPackageId: BILLING_PLAN_MANAGEMENT_OFFER,
            paymentStrategyId: 0,
          };
          draft.managementOrderPopupClosed = true;
        } else {
          draft.isPurchasingManagementIssuedOrder = false;
        }
        return;
      case actionTypes.SET_VAT_CALCULATIONS:
        draft.vatCalculations = action.vatCalculations;
        return;
      case actionTypes.SET_PAYMENT_BILLING_INFO:
        const ubi = action.userBillingInfo;
        if (ubi) {
          if (!state.userBillingInfo.find(existingUbi => existingUbi.id === ubi.id)) {
            draft.userBillingInfo = [...state.userBillingInfo, ubi];
          }

          if (ubi.billingInfo.vatNumber && ubi.billingInfo.type !== undefined) {
            draft.payerType = ubi.billingInfo.type;
            draft.payerVatNumber = ubi.billingInfo.vatNumber;
            draft.payerVatRegistered = true;
          } else {
            draft.payerType = Payer.INDIVIDUAL;
            draft.payerVatNumber = '';
            draft.payerVatRegistered = false;
          }
          draft.paymentCountry = ubi.billingInfo.countryCode;
        }
        draft.paymentBillingInfo = action.userBillingInfo;
        return;
      case actionTypes.SET_SELECTED_USER_BILLING_INFO:
        draft.selectedUserBillingInfo = action.id;
        return;
      case actionTypes.SET_VAT_VERIFICATION_RESPONSE:
        draft.vatVerificationResponse = action.vatVerification;
        return;
      case actionTypes.SET_PAYER_VAT_NUMBER:
        draft.payerVatNumber = action.vatNumber;
        return;
      case actionTypes.SET_PAYER_VAT_REGISTERED:
        draft.payerVatRegistered = action.vatRegistered;
        return;
      case actionTypes.SET_PAYMENT_COUNTRY:
        draft.paymentCountry = action.paymentCountry;
        return;
      case actionTypes.SET_PAYER_TYPE:
        draft.payerType = action.payerType;
        return;
      case actionTypes.SET_ACTIVE_SALES_PACKAGE:
        draft.activeSalesPackage = action.selector;
        if (action.selector === undefined) {
          // reset tpdi packages and client created packages (co-term)
          draft.amountFactorPackages = 1;
          draft.salesPackages = draft.salesPackages.filter(pack => !pack.isClientCreatedPackage);
        }
        return;
      case actionTypes.SET_CLIENT_PACKAGE:
        draft.salesPackages.push(action.clientPackage);
        return;
      case actionTypes.CONFIRM_PURCHASE_ORDER:
        draft.managementIssuedOrder = undefined;
        draft.purchaseOrders = state.purchaseOrders.map(o =>
          o.id === action.order.id ? action.order : o,
        );
        return;
      case actionTypes.UPDATE_PURCHASE_ORDER:
        draft.purchaseOrders = state.purchaseOrders.map(o =>
          o.id === action.order.id ? action.order : o,
        );
        return;
      case actionTypes.SET_COUNTRIES:
        draft.countries[action.listType] = action.countries;
        return;
      case actionTypes.SET_COUNTRIES_FETCHING:
        draft.fetchingCountries = action.fetching;
        return;
      case actionTypes.SET_PURCHASE_ORDERS:
        draft.purchaseOrders = action.orders;
        draft.fetchingPurchaseOrders = false;
        draft.purchaseOrdersFetched = true;
        return;
      case actionTypes.SET_PURCHASE_ORDERS_FETCHING:
        draft.fetchingPurchaseOrders = action.fetching;
        return;
      case actionTypes.CANCEL_PAYPAL_ORDER:
        draft.purchaseOrders = draft.purchaseOrders.map(o =>
          o.paymentSystemId === action.paypalPaymentId ? { ...o, status: OrderStatus.CANCELED } : o,
        );
        return;
      case actionTypes.ADD_PURCAHSE_ORDER:
        draft.purchaseOrders = [action.order, ...draft.purchaseOrders];
        return;
      case actionTypes.SET_MANAGEMENT_ISSUED_ORDER:
        if (action.order) {
          const subscriptionPlans = [
            ...state.salesPackages,
            existingOrderToSubscriptionPlan(action.order),
          ];
          draft.salesPackages = subscriptionPlans;
        }
        draft.fetchingManagementIssuedOrder = false;
        draft.managementIssuedOrder = action.order;
        return;
      case actionTypes.SET_SUBSCRIPTION_PLANS:
        draft.salesPackages = [
          ...draft.salesPackages.filter(
            p => p.planId === BILLING_PLAN_RETRY || p.planId === BILLING_PLAN_MANAGEMENT_OFFER,
          ),
          ...action.plans,
        ];
        draft.fetchingSubscriptionPlans = false;
        return;
      case actionTypes.SET_FETCHING_SUBSCRIPTION_PLANS:
        draft.fetchingSubscriptionPlans = action.fetching;
        return;
      case actionTypes.CLOSE_MANAGEMENT_ORDER_POPUP:
        draft.managementOrderPopupClosed = true;
        return;
      case actionTypes.SET_MANAGEMENT_ISSUED_ORDER_FETCHING:
        return {
          ...state,
          fetchingManagementIssuedOrder: action.fetching,
        };
      case actionTypes.UPDATE_USER_BILLING_INFO:
        draft.userBillingInfo = [...draft.userBillingInfo, action.userBillingInfo];
        draft.selectedUserBillingInfo = action.userBillingInfo.id;
        return;
      case actionTypes.SET_USER_BILLING_INFO:
        draft.userBillingInfo = action.userBillingInfo;
        draft.userBillingInfoFetched = true;
        draft.fetchingUserBillingInfo = false;
        return;
      case actionTypes.ADD_USER_BILLING_INFO:
        draft.userBillingInfo = [...state.userBillingInfo, action.userBillingInfo];
        return;
      case actionTypes.SET_USER_BILLING_INFO_FETCHING:
        draft.fetchingUserBillingInfo = action.fetching;
        return;
      case actionTypes.SET_AMOUNT_FACTOR_PACKAGES:
        draft.amountFactorPackages = action.amount;
        return;
      case actionTypes.ADD_TO_CART:
        draft.cart = [{ ...action.item, id: uuidv4() }]; // Currently only a single item in cart is supported
        return;
      case actionTypes.REMOVE_FROM_CART:
        draft.cart = draft.cart.filter(i => i.id !== action.id);
        return;
      case actionTypes.SET_RETRY_ORDER:
        if (action.order) {
          const subscriptionPlans = [
            ...state.salesPackages.filter(p => p.planId !== BILLING_PLAN_RETRY),
            existingOrderToSubscriptionPlan(action.order, true),
          ];
          draft.salesPackages = subscriptionPlans;
        }
        return;
      case actionTypes.SET_SUPPORTED_CURRENCIES:
        draft.supportedCurrencies = action.currencies;
        return;
      case actionTypes.SET_SELECTED_CURRENCY:
        if (action.isInitialSelect) {
          draft.hasSetInitialCurrency = true;
        }
        draft.selectedCurrency = action.currency;
        return;
      default:
        return;
    }
  });
};

export default billing;
