import React, { createContext } from 'react';
import { connect } from 'react-redux';

import { StoreState } from '@src/meta/types/store';
import { RoleAssignmentMap, Role, RoleAssignmentTarget } from '@modules/auth/components/types/user';
import { getRoleAssignmentMap } from '@modules/auth/components/lib/account';
import { isMaintenanceInProgress } from '@src/utils/maintenance';

export type BillingContextProps = {
  readonly: boolean;
  maintenanceInProgress: boolean;
};

export const BillingContext = createContext<BillingContextProps>({
  readonly: false,
  maintenanceInProgress: false,
});

type Props = {
  children: React.ReactNode;
};

type PropsFromStore = {
  roleAssignments: RoleAssignmentMap | undefined;
};

function hasReadonlyAccess(RoleAssignmentMap: RoleAssignmentMap | undefined) {
  return Boolean(
    (RoleAssignmentMap && RoleAssignmentMap.dbd === Role.APPLICATION_RO) ||
      isMaintenanceInProgress(RoleAssignmentTarget.DASHBOARD),
  );
}

function BillingContextProvider({ children, roleAssignments }: Props & PropsFromStore) {
  return (
    <BillingContext.Provider
      value={{
        readonly: hasReadonlyAccess(roleAssignments),
        maintenanceInProgress: isMaintenanceInProgress(RoleAssignmentTarget.DASHBOARD),
      }}
    >
      {children}
    </BillingContext.Provider>
  );
}

const mapStateToProps = (state: StoreState): PropsFromStore => {
  return {
    roleAssignments: getRoleAssignmentMap(state),
  };
};

export default connect(mapStateToProps)(BillingContextProvider);
