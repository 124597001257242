import { styled } from '../../../../styles/Theme';

const FlexAlignedStart = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexAlignedEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
`;

export default FlexAlignedStart;
