import config from '@config/config';

import api, { DEFAULT_ENDPOINT_CONFIG } from '../../../lib/api';
import { ResourceFetch } from '../../../meta/types/api';
import { ContentType } from '../../../meta/types/content-type';
import { OrderStatus, ServerOrder } from '../../../meta/types/billing/order';
import { ServerOrderPayment } from '../../../meta/types/billing/payment';

const { GET, PUT } = api;

const ORDER_RESOURCE_BASE_PATH = `${config.dashboardServiceBasePath}/dashboard/v1/purchase/orders`;

type OptionalUserId = { userId?: string };
export interface OrderResource {
  getOrders: ResourceFetch<OrdersResponse, OptionalUserId>;
  getOrder: ResourceFetch<ServerOrder, { orderId: string }>;
  getPayments: ResourceFetch<OrderPaymentResourceResponse, { orderId: string }>;
  getPaymentInvoice: ResourceFetch<Blob, { orderId: string; paymentId: number }>;
  getPendingWireTransferOrder: ResourceFetch<ServerOrder, OptionalUserId>;
  getManagementOffer: ResourceFetch<ServerOrder, OptionalUserId>;
  getAvailableSalesPackages: ResourceFetch<string[]>;
  getImpersonatedAvailableSalesPackages: ResourceFetch<
    string[],
    { userId: string; accountId: string }
  >;
  changeStatus: ResourceFetch<
    ServerOrder,
    { orderId: string; status: OrderStatus; message: string }
  >;
  getNextPaymentDate: ResourceFetch<string, { orderId: string }>;
}

export type OrderPaymentResourceResponse = {
  member: ServerOrderPayment[];
};

export type OrdersResponse = {
  member: ServerOrder[];
};

const OrderResource: OrderResource = {
  getOrders: GET(ORDER_RESOURCE_BASE_PATH),
  getOrder: GET(`${ORDER_RESOURCE_BASE_PATH}/:orderId`),
  getPayments: GET(`${ORDER_RESOURCE_BASE_PATH}/:orderId/payments`),
  getPaymentInvoice: GET(
    `${ORDER_RESOURCE_BASE_PATH}/:orderId/payments/:paymentId/invoice`,
    DEFAULT_ENDPOINT_CONFIG,
    ContentType.PDF,
  ),
  getPendingWireTransferOrder: GET(`${ORDER_RESOURCE_BASE_PATH}/pendingWireTransferOrder`),
  changeStatus: PUT(`${ORDER_RESOURCE_BASE_PATH}/:orderId/status/:status?message=:message`),
  getManagementOffer: GET(`${ORDER_RESOURCE_BASE_PATH}/offer`),
  getNextPaymentDate: GET(`${ORDER_RESOURCE_BASE_PATH}/:orderId/nextPayment`),
  getAvailableSalesPackages: GET(`${ORDER_RESOURCE_BASE_PATH}/purchase-options`),
  getImpersonatedAvailableSalesPackages: GET(
    `${ORDER_RESOURCE_BASE_PATH}/purchase-options?userId=:userId&accountId=:accountId`,
  ),
};

export default OrderResource;
