import React from 'react';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';

const FullPageLoader = () => {
  return (
    <StyledDiv>
      <Loader active>Loading...</Loader>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  p {
    font-weight: bold;
  }
`;

export default FullPageLoader;
