import jwt_dec from 'jwt-decode';
import * as React from 'react';
import { connect } from 'react-redux';
import { ReactKeycloakProvider } from '@react-keycloak/web';

import { setLoggedIn } from '@src/actions/auth';
import UserResource from '@src/services/Identity/UserResource';
import { alertSuccess } from '@src/lib/alert';
import { setAccount } from '@src/actions/accounts';
import config from '@config/config';

import { Claims, SentinelUser, toInternalUser } from './components/types/user';
import { keycloak } from './components/lib';
import { AuthorizationProps as AuthorizationProps_ } from './types';
import FullPageLoader from './components/FullPageLoader';
import { getAccount } from './components/lib/account';
import AuthenticationContextProvider from './components/context';
import {
  getImpersonationParams,
  updateClaimsForImpersonation,
  checkIsImpersonating,
  saveImpersonationParams,
} from './components/lib/impersonation';

type State = {
  authorizationFail: boolean;
};

type FullAuthorizationProps = AuthorizationProps_ & State;

const withAuthorization = (WrappedComponent: React.ComponentClass<FullAuthorizationProps>) => {
  const Authorized = class extends React.Component<FullAuthorizationProps> {
    state: State = { authorizationFail: false };

    login(user: SentinelUser, claims: Claims, impersonating: boolean) {
      const { setLoggedIn } = this.props;
      setLoggedIn(user, claims, impersonating);
      return true;
    }

    render() {
      return (
        <ReactKeycloakProvider
          LoadingComponent={<FullPageLoader />}
          initOptions={{
            pkceMethod: 'S256',
            flow: 'standard',
            checkLoginIframe: true,
            onLoad: 'login-required',
            enableLogging: true,
          }}
          authClient={keycloak}
          onEvent={async ev => {
            if (ev === 'onAuthError' || ev === 'onAuthRefreshError') {
              console.error('ERROR fail', ev);
              this.setState({ authorizationFail: true });
            } else if (ev === 'onAuthSuccess' || ev === 'onReady') {
              const claims: Claims = jwt_dec(keycloak.token!);
              try {
                let userId: string = claims.sub;
                let accountId: string = claims[config.accountClaimsKey];
                const { impersonatedUserId, impersonatedAccountId } = getImpersonationParams();
                const isImpersonating = checkIsImpersonating(
                  impersonatedUserId,
                  impersonatedAccountId,
                );
                if (isImpersonating) {
                  userId = impersonatedUserId!;
                  accountId = impersonatedAccountId!;
                }
                const user = toInternalUser(await UserResource.get({ userId }));
                const account = await getAccount(accountId);
                if (account === null) {
                  return;
                }
                this.props.setAccount(account);
                if (isImpersonating) {
                  updateClaimsForImpersonation(claims, impersonatedUserId!, impersonatedAccountId!);
                  saveImpersonationParams(impersonatedUserId!, impersonatedAccountId!);
                }
                this.login(user, claims, isImpersonating);
                if (isImpersonating) {
                  alertSuccess(`Managing: ${impersonatedAccountId}`);
                }
              } catch (err) {
                console.error('ERROR', err);
                this.setState({ authorizationFail: true });
              }
            }
          }}
        >
          <AuthenticationContextProvider>
            <WrappedComponent {...this.props} {...this.state} />
          </AuthenticationContextProvider>
        </ReactKeycloakProvider>
      );
    }
  };

  return connect(null, mapDispatchToProps)(Authorized as any);
};

const mapDispatchToProps = {
  setLoggedIn,
  setAccount,
};

export default withAuthorization;
export type AuthorizationProps = FullAuthorizationProps;
