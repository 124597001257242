import config from '@config/config';
import { Currency } from '@src/meta/types/billing/payment';

import api from '../../../lib/api';
import { ResourceFetch } from '../../../meta/types/api';
import { SalesPackage } from '../../../meta/types/billing/salespackage';

const { GET } = api;

const SALESPACKAGE_RESOURCE_BASE_PATH = `${config.dashboardServiceBasePath}/dashboard/v1/purchase/salespackages`;

export interface SalesPackageResource {
  getPlans: ResourceFetch<SalesPackage[]>;
  getAvailableCurrencies: ResourceFetch<Currency[]>;
}

const SalesPackageResource: SalesPackageResource = {
  getPlans: GET(SALESPACKAGE_RESOURCE_BASE_PATH),
  getAvailableCurrencies: GET(`${SALESPACKAGE_RESOURCE_BASE_PATH}/currencies`),
};

export default SalesPackageResource;
