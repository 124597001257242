import config from '@config/config';

import api from '../../../lib/api';
import { ResourceFetch } from '../../../meta/types/api';

const REQUEST_STATISTICS_RESOURCE_BASE_PATH = `${config.dashboardServiceBasePath}/dashboard/v1/statistics/requests`;

const { GET } = api;

type UserIdStatisticsParams = {
  userId?: string;
  timestamp_gte?: string | null;
};

interface InstanceStatisticsParams extends UserIdStatisticsParams {
  instanceId?: string;
}

export interface RequestStatisticsResource {
  getRateLimitedRequestsCount: ResourceFetch<{ data: number }, InstanceStatisticsParams>;
}

const RequestStatisticsResource: RequestStatisticsResource = {
  getRateLimitedRequestsCount: GET(`${REQUEST_STATISTICS_RESOURCE_BASE_PATH}/rate_limited`),
};

export default RequestStatisticsResource;
