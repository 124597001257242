import React, { useEffect, useMemo } from 'react';
import { Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { useLocation } from 'react-router-dom';

import { StoreState } from '@src/meta/types/store';
import { NewsEntry, StickyCategory } from '@src/meta/types/news';
import { fetchNews } from '@src/actions/dashboard';
import InfoMessage from '@src/components/common/banners/InfoMessage';
import { pathToStickyCategory } from '@src/lib/news';
import { SecondaryBlankLink } from '@src/components/common/links';
import { styled } from '@src/styles/Theme';
import config from '@config/config';

import { getMdLinkFunction } from '../../../Billing/components/BillingPage/Salespackages/utils';

type Props = {
  news: NewsEntry[] | undefined;
  fetchNews: () => void;
};

const StickyNews = ({ news, fetchNews }: Props) => {
  const location = useLocation();

  useEffect(() => {
    fetchNews();
  }, [fetchNews]);

  const stickyNews = useMemo(() => {
    const currentPath = location.pathname;
    const stickyCat = pathToStickyCategory(currentPath);
    return news
      ?.filter(news => news.stickyCategory !== undefined)
      .filter(
        news => news.stickyCategory! === StickyCategory.ALL || news.stickyCategory! === stickyCat,
      )
      .filter(
        news =>
          news.stickyDueDate === undefined ||
          (news.stickyDueDate !== undefined && new Date(news.stickyDueDate) > new Date()),
      );
  }, [location.pathname, news]);

  if (!stickyNews || stickyNews.length === 0) {
    return null;
  }
  return (
    <StyledNewsWidget>
      {stickyNews.map(news => (
        <InfoMessage
          content={
            <div>
              <Icon name="newspaper outline" />
              News! <br />
              <StyledWrapper>
                <ReactMarkdown linkTarget="_blank" components={{ a: getMdLinkFunction(MdLink) }}>
                  {news.message}
                </ReactMarkdown>
                <StyledActionWrapper>
                  <SecondaryBlankLink
                    style={{
                      color: config.primaryColor,
                      textDecoration: 'none',
                      fontWeight: 'bold',
                      marginTop: 'auto',
                    }}
                    href={news.link}
                  >
                    {news.clickToAction}
                  </SecondaryBlankLink>
                </StyledActionWrapper>
              </StyledWrapper>
            </div>
          }
        />
      ))}
    </StyledNewsWidget>
  );
};

const mapStateToProps = (state: StoreState) => ({
  news: state.dashboard.news,
});

const mapDispatchToProps = {
  fetchNews,
};
export default connect(mapStateToProps, mapDispatchToProps)(StickyNews);

const StyledNewsWidget = styled.div`
  margin: 1rem;
  font-size: 1.1rem;
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -1em;

  a {
    color: ${config.primaryColor};
  }
`;

const StyledActionWrapper = styled.div`
  white-space: nowrap;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.75rem;
`;

const MdLink = styled.span`
  color: ${config.primaryColor};
  font-weight: 500;

  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
