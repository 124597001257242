export function parseBoolean(str: string | null): boolean | null {
  switch (str) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return null;
  }
}
