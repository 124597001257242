import React, { createContext } from 'react';
import { connect } from 'react-redux';

import { StoreState } from '@src/meta/types/store';
import { RoleAssignmentMap, Role } from '@modules/auth/components/types/user';
import { getRoleAssignmentMap } from '@modules/auth/components/lib/account';
import { isMaintenanceInProgress } from '@src/utils/maintenance';
import { RoleAssignmentTarget } from '../types/user';

export type AuthenticationContextProps = {
  readonly: boolean;
  maintenanceInProgress: boolean;
};

export const AuthenticationContext = createContext<AuthenticationContextProps>({
  readonly: false,
  maintenanceInProgress: false,
});

type Props = {
  children: React.ReactNode;
};

type PropsFromStore = {
  roleAssignments: RoleAssignmentMap | undefined;
};

function hasReadonlyAccess(RoleAssignmentMap: RoleAssignmentMap | undefined) {
  return Boolean(
    (RoleAssignmentMap && RoleAssignmentMap.idn === Role.APPLICATION_RO) ||
      isMaintenanceInProgress(RoleAssignmentTarget.IDENTITY),
  );
}

function AuthenticationContextProvider({ children, roleAssignments }: Props & PropsFromStore) {
  return (
    <AuthenticationContext.Provider
      value={{
        readonly: hasReadonlyAccess(roleAssignments),
        maintenanceInProgress: isMaintenanceInProgress(RoleAssignmentTarget.IDENTITY),
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
}

const mapStateToProps = (state: StoreState): PropsFromStore => {
  return {
    roleAssignments: getRoleAssignmentMap(state),
  };
};

export default connect(mapStateToProps)(AuthenticationContextProvider);
