import Keycloak, { KeycloakLoginOptions } from 'keycloak-js';

import config from '@config/config';

const KEYCLOAK_CONFIG = {
  clientId: config.clientId,
  realm: config.keycloakRealm,
  url: config.keycloakAuthUrl,
};

export const keycloak = new Keycloak(KEYCLOAK_CONFIG);
const kcLogin = keycloak.login;
keycloak.login = (options?: KeycloakLoginOptions | undefined) => {
  const urlParams = new URLSearchParams(window.location.search);
  const idpHint = urlParams.get('kc_idp_hint')?.toLowerCase();
  if (idpHint) {
    urlParams.delete('kc_idp_hint');
    const urlObj = new URL(window.location.href);
    urlObj.search = urlParams.toString();
    history.replaceState(null, '', urlObj as any);
  }
  return kcLogin({ ...options, idpHint });
};

export const getAccessToken = () => {
  return keycloak.token!;
};

export const doLogout = () => {
  keycloak.logout();
};
// eslint-disable-next-line
export const redirectToOAuth2Provider = (state: string) => {};
// eslint-disable-next-line
export const redirectToLogin = (state: string) => {};
