import produce, { Draft } from 'immer';

import { ISetDatasets, ISetDatasetsFetching } from '../../actions/datasets';
import * as actionTypes from '../../constants/actionTypes';
import { Dataset } from '../../meta/types/wms/Dataset';

export const initialState = Object.freeze({
  datasetsFetched: false,
  datasetsLoading: false,
});

export type DatasetState = typeof initialState & {
  datasets?: Dataset[];
};

const datasets = (
  state: DatasetState = initialState,
  action: ISetDatasetsFetching | ISetDatasets,
) => {
  return produce<DatasetState>(state, (draft: Draft<DatasetState>) => {
    switch (action.type) {
      case actionTypes.SET_DATASETS_FETCHING:
        draft.datasetsLoading = action.datasetsFetching;
        return;
      case actionTypes.SET_DATASETS:
        draft.datasets = action.datasets;
        draft.datasetsFetched = true;
        draft.datasetsLoading = false;
        return;
      default:
        return;
    }
  });
};

export default datasets;
