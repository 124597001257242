import 'nprogress/nprogress.css';

import nprogress from 'nprogress';
import React from 'react';
import { LoadingComponentProps } from 'react-loadable';
import { Loader } from 'semantic-ui-react';

interface Props extends LoadingComponentProps {
  content?: string;
}

class AsyncLoading extends React.Component<Props> {
  componentWillMount() {
    nprogress.start();
  }

  componentWillUnmount() {
    nprogress.done();
  }

  render() {
    const { content = 'Loading' } = this.props;
    return (
      <Loader active content={content} style={{ transform: 'translateY(50px) translateZ(0px)' }} />
    );
  }
}

export default AsyncLoading;
