import config from '@config/config';

import { styled } from '../Theme';
import { COLLAPSED_SIDEBAR_WIDTH, EXTENDED_SIDEBAR_WIDTH, NAVBAR_HEIGHT } from '../Sidebar';

type Props = {
  sidebarCollapsed: boolean;
};

export const NavbarStyle = styled.nav<Props>`
  min-height: ${NAVBAR_HEIGHT};
  max-height: ${NAVBAR_HEIGHT};
  z-index: 200;
  background-color: ${() => config.backgroundColor};
  padding: 0 16px 0 0;
  display: flex;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .title {
    display: flex;
    flex: 1 1 auto;
    min-height: 100%;
  }

  .title > * {
    padding: 2px;
  }

  img.logo {
    height: 100%;
    width: auto;
    max-width: 100%;
    padding: 10px;
  }

  .navbar > .title > .navbar-logo:hover {
    cursor: pointer;
  }

  .navbar-active-tab {
    display: flex;
    align-items: center;
    margin-left: calc(
      ${props => (props.sidebarCollapsed ? COLLAPSED_SIDEBAR_WIDTH : EXTENDED_SIDEBAR_WIDTH)} + 25px
    );
    font-weight: bold;
    color: ${() => config.primaryTextColor};
    font-size: 18px;
  }

  .navbar-nav {
    display: flex;
    margin-left: auto;
    flex-direction: row;
  }

  .navbar-nav > li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-left: 1em;
  }

  .menu.transition {
    min-width: 200px;
  }

  .navbar-username {
    display: flex;
    align-items: center;
    height: 100%;
    color: ${() => config.primaryTextColor} !important;
    font-size: 14px;
    font-weight: bold;
  }

  i.right.angle {
    color: ${() => config.primaryTextColor} !important;
  }
`;
