import React from 'react';
import styled from 'styled-components';

import { ReactComponent as EOBLogo } from '@src/images/EOB_logo.svg';
import config from '@config/config';

import ExternalLinkSidebarTab from '../ExternalLinkSidebarTab';

const BrowserSidebarLink = () => {
  return (
    <ExternalLinkSidebarTab
      content="EO Browser"
      popupContent="Open EO Browser"
      icon={<StyledEOBIcon />}
      link={`${config.appsBaseUrl}eo-browser`}
    />
  );
};

export default React.memo(BrowserSidebarLink);

const StyledEOBIcon = styled(EOBLogo)`
  height: 1.16em;
`;
