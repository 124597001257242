import React from 'react';
import config from '@config/config';
import { Environment } from '@src/config/baseAppConfig';

import WarningMessage from '../common/banners/WarningMessage';

const EnvironmentBanner = () => {
  if (config.environment === Environment.PRODUCTION) {
    return null;
  }
  return (
    <div style={{ margin: '1rem' }}>
      <WarningMessage
        warning
        content={`You are in ${config.environment?.toLowerCase()} environment!`}
      />
    </div>
  );
};

export default EnvironmentBanner;
