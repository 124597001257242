import { createGlobalStyle } from 'styled-components';
import config from '@config/config';

const GlobalStyle = createGlobalStyle`

body {
  margin: 0;
  padding: 0;
  height: auto !important;
  background: ${() => config.backgroundColor} !important;
}

.content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.toast__wrapper {
  font-family: Myriad Pro, sans-serif;
  border-radius: 30px;
  padding: 10px 20px;
}

.toast__wrapper.success {
  background: ${config.toastSuccessColor};
}

.toast__wrapper.error {
  background: ${config.toastErrorColor};
}

.hoverable:hover {
  cursor: pointer;
}

/* fix for fullscreen modal which is positioned on left instead of being centered
 * issue: https://github.com/Semantic-Org/Semantic-UI-React/issues/3345
 */
.ui.fullscreen.modal, .ui.fullscreen.scrolling.modal { 
  left: unset !important;
  margin: unset !important;
}

.primary-table {
  background-color: ${config.backgroundGrey} !important;
  border: none !important;
  font-size: 1rem !important;

  @media (max-width:767px) {
    tr {
      padding-top: 0 !important;
      padding-bottom: 0 !important;

      td > button, td > a > button, td > div.ui.dropdown {
        padding: 0.4rem !important;
      }

      td {
        border-bottom: 2px solid black;
      }

      .ellipsed-row {
        max-width: 300px;
        overflow: hidden !important;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
  tbody {
    tr > td {
      border-bottom: 1px solid white !important;
      border-top: 1px solid white !important;
      border-left: none !important;
      border-right: none !important;
      overflow: visible !important;
    }
  }

  &__header {
    th {
      background-color: ${config.darkBackgroundGrey} !important;
      color:  ${config.quaternaryTextColor} !important;
      border: none !important;
      border-radius: 0 !important;
    }

    tr {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }

    &--selected {
      text-decoration: underline;
    }
  }

  div.dropdown,
  input[type="text"] {
    border-radius: 0 !important;
  }


}
`;

export default GlobalStyle;
