import config from '@config/config';

import api from '../../../lib/api';
import { ResourceFetch } from '../../../meta/types/api';
import { UserData } from '../../../meta/types/userdata';

const { GET, PUT, PATCH } = api;

const RESOURCE_BASE_PATH = `${config.dashboardServiceBasePath}/dashboard/v1/userdata`;

export interface UserDataResource {
  get: ResourceFetch<UserDataResponse>;
  update: ResourceFetch<void, UserData>;
  patch: ResourceFetch<void, UserData>;
}

export type UserDataResponse = {
  data: UserData;
};

const UserDataResource: UserDataResource = {
  get: GET(`${RESOURCE_BASE_PATH}`),
  update: PUT(`${RESOURCE_BASE_PATH}`),
  patch: PATCH(`${RESOURCE_BASE_PATH}`),
};

export default UserDataResource;
