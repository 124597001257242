import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import config from '@config/config';

import AppComponent from './components/App';
import configureStore from './store';

const store = configureStore();

function renderApp(App: typeof AppComponent) {
  if (window.location.search.includes('fromConfigurator=true')) {
    handleConfiguratorRedirect();
  }

  const ConnectedApp = (
    <Provider store={store}>
      <App />
    </Provider>
  );

  render(ConnectedApp, document.getElementById('root'));
}
renderApp(AppComponent);

if (module.hot) {
  module.hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default;
    renderApp(NextApp);
  });
}

function handleConfiguratorRedirect() {
  const hash =
    window.location.hash === '' ? `#${config.CONFIGURATIONS_UTILITY_PATH}` : window.location.hash;

  const pathname =
    window.location.pathname.slice(-1) === '/'
      ? window.location.pathname
      : `${window.location.pathname}/`;

  window.history.replaceState({}, document.title, window.location.origin + pathname + hash);
}
