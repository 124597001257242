import config from '@config/config';

const getTheme = () => {
  return {
    primaryTextColor: config.primaryTextColor,
    secondaryTextColor: config.secondaryTextColor,
    terciaryTextColor: config.terciaryTextColor,

    primaryColor: config.primaryColor,
    terciaryColor: config.terciaryColor,

    billingCardsStackEndpoint: '768px',

    mainContentPadding: '12px',

    padding1: '4px',
    padding2: '8px',
    padding3: '12px',
    padding4: '16px',
    padding5: '24px',
    padding6: '32px',
    padding7: '48px',
    padding8: '64px',
    padding9: '96px',
    padding10: '128px',
    padding11: '192px',
    padding12: '256px',
    padding13: '384px',
    padding14: '512px',
    padding15: '640px',
    padding16: '768px',
  };
};
export default getTheme;
