import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'semantic-ui-react';

import { getClaimsDisplayedName, getDisplayedName } from '@modules/auth/components/lib/user';
import config from '@config/config';

import { NavbarContext } from '..';
import { SidebarTab } from '../../../../constants/paths/internal';
import { VERSION_INFO } from '../../../../version';
import DropdownMenuWrapper from '../../../common/DropdownMenuWrapper';
import SecondaryButton from '../../../common/buttons/SecondaryButton';

const VERSION_CONTENT = VERSION_INFO.tag
  ? `Version: ${VERSION_INFO.tag}, ${VERSION_INFO.date}`
  : VERSION_INFO.branch
  ? `${VERSION_INFO.branch} [${VERSION_INFO.commit.substring(0, 8)}], ${VERSION_INFO.date}`
  : 'Local Build';

const AccountDropdownItem = () => {
  return (
    <NavbarContext.Consumer>
      {({ activeTab, logout, configurableUser, rootManaging, authenticatedUser }) => {
        const username = getDisplayedName(configurableUser!);

        const userContent = rootManaging
          ? `${getClaimsDisplayedName(authenticatedUser)} (as ${username})`
          : username;

        return (
          <Dropdown
            trigger={<UserNameTrigger rootManaging={rootManaging} username={username} />}
            pointing="top right"
            icon={null}
            simple
            id="navbar-account-dropdown"
          >
            <DropdownMenuWrapper
              dropdownId="navbar-account-dropdown"
              position="right"
              style={{ marginTop: '0px' }}
            >
              <Dropdown.Header content={userContent} icon="user" />
              <Dropdown.Divider style={{ marginBottom: '0px' }} />
              <Dropdown.Item
                text="Settings"
                icon="settings"
                as={Link}
                to={config.USER_SETTINGS_PATH}
                active={activeTab === SidebarTab.ACCOUNT_SETTINGS}
                data-testid="settings"
              />
              <Dropdown.Item
                text="Sign out"
                icon="sign out"
                onClick={logout}
                data-testid="sign-out"
              />
              <Dropdown.Divider style={{ marginBottom: '0px' }} />
              <Dropdown.Header content={VERSION_CONTENT} icon="info circle" />
            </DropdownMenuWrapper>
          </Dropdown>
        );
      }}
    </NavbarContext.Consumer>
  );
};

const UserNameTrigger = ({
  rootManaging,
  username,
}: {
  rootManaging: boolean;
  username: string;
}) => {
  return (
    <SecondaryButton
      className="navbar-username"
      style={{ marginBottom: '0', cursor: 'auto' }}
      icon={rootManaging ? 'spy' : 'triangle down'}
      content={`${rootManaging ? 'Managing - ' : ''}${username}`}
    />
  );
};

export default React.memo(AccountDropdownItem);
