import React from 'react';
import { Message } from 'semantic-ui-react';
import config from '@config/config';

import { styled } from '../../../styles/Theme';

const BaseMessage = styled(({ styleAsContainerMessage, withMargin, ...rest }) => (
  <Message {...rest} />
))`
  ${props => {
    const { primaryColor, backgroundGrey, terciaryTextColor, lightBackgroundColor } = config;
    return props.styleAsContainerMessage
      ? () => {
          return `
    background-color: ${backgroundGrey} !important;
    color: black;
    border: none;
    a {
      color: ${primaryColor} !important;
      font-weight: bold;
    }
    `;
        }
      : () => {
          return `
    background-color: ${lightBackgroundColor} !important;
    color: ${terciaryTextColor} !important;
    a {
      color: ${primaryColor} !important;
    }
    `;
        };
  }}
  border-radius: 0 !important;
  box-shadow: none !important;
  ${props => (props.withMargin ? 'margin: 1rem !important' : '')};
`;

export default BaseMessage;
