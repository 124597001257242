// ------------ AUTH ------------

export const LOGOUT = 'LOGOUT';
export type LOGOUT = typeof LOGOUT;

export const SET_CONFIGURABLE_USER = 'SET_CONFIGURABLE_USER';
export type SET_CONFIGURABLE_USER = typeof SET_CONFIGURABLE_USER;

export const SET_TOKEN_CHECKED = 'SET_TOKEN_CHECKED';
export type SET_TOKEN_CHECKED = typeof SET_TOKEN_CHECKED;

export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export type SET_LOGGED_IN = typeof SET_LOGGED_IN;

export const SET_USER_ALREADY_HAD_TRIAL = 'SET_USER_ALREADY_HAD_TRIAL';
export type SET_USER_ALREADY_HAD_TRIAL = typeof SET_USER_ALREADY_HAD_TRIAL;

// ------------ CONFIGURATION UTILITY ------------

export const SET_DATASETS_FETCHING = 'SET_DATASETS_FETCHING';
export type SET_DATASETS_FETCHING = typeof SET_DATASETS_FETCHING;

export const SET_DATASETS = 'SET_DATASETS';
export type SET_DATASETS = typeof SET_DATASETS;

export const SET_TEMPLATE_INSTANCES_FETCHING = 'SET_TEMPLATE_INSTANCES_FETCHING';
export type SET_TEMPLATE_INSTANCES_FETCHING = typeof SET_TEMPLATE_INSTANCES_FETCHING;

export const SET_TEMPLATE_INSTANCES = 'SET_TEMPLATE_INSTANCES';
export type SET_TEMPLATE_INSTANCES = typeof SET_TEMPLATE_INSTANCES;

export const SET_TEMPLATE_INSTANCE_LAYERS = 'SET_TEMPLATE_INSTANCE_LAYERS';
export type SET_TEMPLATE_INSTANCE_LAYERS = typeof SET_TEMPLATE_INSTANCE_LAYERS;

export const SET_INSTANCES_FETCHING = 'SET_INSTANCES_FETCHING';
export type SET_INSTANCES_FETCHING = typeof SET_INSTANCES_FETCHING;

export const SET_INSTANCES = 'SET_INSTANCES';
export type SET_INSTANCES = typeof SET_INSTANCES;

export const ADD_INSTANCE = 'ADD_INSTANCE';
export type ADD_INSTANCE = typeof ADD_INSTANCE;

export const SET_LAYERS = 'SET_LAYERS';
export type SET_LAYERS = typeof SET_LAYERS;

export const UPDATE_INSTANCE = 'UPDATE_INSTANCE';
export type UPDATE_INSTANCE = typeof UPDATE_INSTANCE;

export const SELECT_INSTANCE = 'SELECT_INSTANCE';
export type SELECT_INSTANCE = typeof SELECT_INSTANCE;

export const DELETE_INSTANCE = 'DELETE_INSTANCE';
export type DELETE_INSTANCE = 'DELETE_INSTANCE';

export const ADD_LAYER = 'ADD_LAYER';
export type ADD_LAYER = 'ADD_LAYER';

export const DELETE_LAYER = 'DELETE_LAYER';
export type DELETE_LAYER = 'DELETE_LAYER';

export const SET_LAYER_DATA_PRODUCT = 'SET_LAYER_DATA_PRODUCT';
export type SET_LAYER_DATA_PRODUCT = 'SET_LAYER_DATA_PRODUCT';

export const ADD_DATA_PRODUCT_TO_CACHE = 'ADD_DATA_PRODUCT_TO_CACHE';
export type ADD_DATA_PRODUCT_TO_CACHE = 'ADD_DATA_PRODUCT_TO_CACHE';

export const ADD_DATA_PRODUCTS_TO_CACHE = 'ADD_DATA_PRODUCTS_TO_CACHE';
export type ADD_DATA_PRODUCTS_TO_CACHE = 'ADD_DATA_PRODUCTS_TO_CACHE';

export const IS_FETCHING_DATAPRODUCTS = 'IS_FETCHING_DATAPRODUCTS';
export type IS_FETCHING_DATAPRODUCTS = 'IS_FETCHING_DATAPRODUCTS';

export const UPDATE_LAYER = 'UPDATE_LAYER';
export type UPDATE_LAYER = 'UPDATE_LAYER';

// ------------ UI ------------

export const SET_TAB = 'SET_TAB';
export type SET_TAB = typeof SET_TAB;

export const SET_SIDEBAR_COLLAPSED = 'SET_SIDEBAR_COLLAPSED';
export type SET_SIDEBAR_COLLAPSED = 'SET_SIDEBAR_COLLAPSED';

export const SET_BREADCRUMB = 'SET_BREADCRUMB';
export type SET_BREADCRUMB = 'SET_BREADCRUMB';

export const APPEND_BREADCRUMB = 'APPEND_BREADCRUMB';
export type APPEND_BREADCRUMB = 'APPEND_BREADCRUMB';

// ------------ BILLING ------------

export const SET_COUNTRIES = 'SET_COUNTRIES';
export type SET_COUNTRIES = 'SET_COUNTRIES';

export const SET_COUNTRIES_FETCHING = 'SET_COUNTRIES_FETCHING';
export type SET_COUNTRIES_FETCHING = 'SET_COUNTRIES_FETCHING';

export const SET_PURCHASE_ORDERS_FETCHING = 'SET_PURCHASE_ORDERS_FETCHING';
export type SET_PURCHASE_ORDERS_FETCHING = 'SET_PURCHASE_ORDERS_FETCHING';

export const SET_PURCHASE_ORDERS = 'SET_PURCHASE_ORDERS';
export type SET_PURCHASE_ORDERS = 'SET_PURCHASE_ORDERS';

export const CANCEL_PAYPAL_ORDER = 'CANCEL_PAYPAL_ORDER';
export type CANCEL_PAYPAL_ORDER = 'CANCEL_PAYPAL_ORDER';

export const SET_FETCHING_SUBSCRIPTION_PLANS = 'SET_FETCHING_SUBSCRIPTION_PLANS';
export type SET_FETCHING_SUBSCRIPTION_PLANS = 'SET_FETCHING_SUBSCRIPTION_PLANS';

export const SET_SUBSCRIPTION_PLANS = 'SET_SUBSCRIPTION_PLANS';
export type SET_SUBSCRIPTION_PLANS = 'SET_SUBSCRIPTION_PLANS';

export const SET_CLIENT_PACKAGE = 'SET_CLIENT_PACKAGE';
export type SET_CLIENT_PACKAGE = 'SET_CLIENT_PACKAGE';

export const ADD_PURCAHSE_ORDER = 'ADD_PURCAHSE_ORDER';
export type ADD_PURCAHSE_ORDER = 'ADD_PURCAHSE_ORDER';

export const CONFIRM_PURCHASE_ORDER = 'CONFIRM_PURCHASE_ORDER';
export type CONFIRM_PURCHASE_ORDER = 'CONFIRM_PURCHASE_ORDER';

export const UPDATE_PURCHASE_ORDER = 'UPDATE_PURCHASE_ORDER';
export type UPDATE_PURCHASE_ORDER = 'UPDATE_PURCHASE_ORDER';

export const CLOSE_MANAGEMENT_ORDER_POPUP = 'CLOSE_MANAGEMENT_ORDER_POPUP';
export type CLOSE_MANAGEMENT_ORDER_POPUP = 'CLOSE_MANAGEMENT_ORDER_POPUP';

export const SET_MANAGEMENT_ISSUED_ORDER = 'SET_MANAGEMENT_ISSUED_ORDER';
export type SET_MANAGEMENT_ISSUED_ORDER = 'SET_MANAGEMENT_ISSUED_ORDER';

export const SET_MANAGEMENT_ISSUED_ORDER_FETCHING = 'SET_MANAGEMENT_ISSUED_ORDER_FETCHING';
export type SET_MANAGEMENT_ISSUED_ORDER_FETCHING = 'SET_MANAGEMENT_ISSUED_ORDER_FETCHING';

export const SET_USER_BILLING_INFO = 'SET_USER_BILLING_INFO';
export type SET_USER_BILLING_INFO = 'SET_USER_BILLING_INFO';

export const SET_USER_BILLING_INFO_FETCHING = 'SET_USER_BILLING_INFO_FETCHING';
export type SET_USER_BILLING_INFO_FETCHING = 'SET_USER_BILLING_INFO_FETCHING';

export const ADD_USER_BILLING_INFO = 'ADD_USER_BILLING_INFO';
export type ADD_USER_BILLING_INFO = 'ADD_USER_BILLING_INFO';

export const REMOVE_USER_BILLING_INFO = 'REMOVE_USER_BILLING_INFO';
export type REMOVE_USER_BILLING_INFO = 'REMOVE_USER_BILLING_INFO';

export const UPDATE_USER_BILLING_INFO = 'UPDATE_USER_BILLING_INFO';
export type UPDATE_USER_BILLING_INFO = 'UPDATE_USER_BILLING_INFO';

export const SET_AMOUNT_FACTOR_PACKAGES = 'SET_AMOUNT_FACTOR_PACKAGES';
export type SET_AMOUNT_FACTOR_PACKAGES = 'SET_AMOUNT_FACTOR_PACKAGES';

export const ADD_TO_CART = 'ADD_TO_CART';
export type ADD_TO_CART = 'ADD_TO_CART';

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export type REMOVE_FROM_CART = 'REMOVE_FROM_CART';

export const SET_RETRY_ORDER = 'SET_RETRY_ORDER';
export type SET_RETRY_ORDER = 'SET_RETRY_ORDER';

export const SET_SUPPORTED_CURRENCIES = 'SET_SUPPORTED_CURRENCIES';
export type SET_SUPPORTED_CURRENCIES = 'SET_SUPPORTED_CURRENCIES';

export const SET_SELECTED_CURRENCY = 'SET_SELECTED_CURRENCY';
export type SET_SELECTED_CURRENCY = 'SET_SELECTED_CURRENCY';

/* Billing flow */

export const SET_ACTIVE_SALES_PACKAGE = 'SET_ACTIVE_PURCHASE_PLAN';
export type SET_ACTIVE_SALES_PACKAGE = 'SET_ACTIVE_PURCHASE_PLAN';

export const SET_PAYER_TYPE = 'SET_PAYER_TYPE';
export type SET_PAYER_TYPE = 'SET_PAYER_TYPE';

export const SET_PAYMENT_COUNTRY = 'SET_PAYMENT_COUNTRY';
export type SET_PAYMENT_COUNTRY = 'SET_PAYMENT_COUNTRY';

export const SET_PAYER_VAT_REGISTERED = 'SET_PAYER_VAT_REGISTERED';
export type SET_PAYER_VAT_REGISTERED = 'SET_PAYER_VAT_REGISTERED';

export const SET_PAYER_VAT_NUMBER = 'SET_PAYER_VAT_NUMBER';
export type SET_PAYER_VAT_NUMBER = 'SET_PAYER_VAT_NUMBER';

export const SET_VAT_VERIFICATION_RESPONSE = 'SET_VAT_VERIFICATION_RESPONSE';
export type SET_VAT_VERIFICATION_RESPONSE = 'SET_VAT_VERIFICATION_RESPONSE';

export const SET_PAYMENT_BILLING_INFO = 'SET_PAYMENT_BILLING_INFO';
export type SET_PAYMENT_BILLING_INFO = 'SET_PAYMENT_BILLING_INFO';

export const SET_SELECTED_USER_BILLING_INFO = 'SET_SELECTED_USER_BILLING_INFO';
export type SET_SELECTED_USER_BILLING_INFO = 'SET_SELECTED_USER_BILLING_INFO';

export const SET_VAT_CALCULATIONS = 'SET_VAT_CALCULATIONS';
export type SET_VAT_CALCULATIONS = 'SET_VAT_CALCULATIONS';

export const SET_PURCHASING_MANAGEMENT_ISSUED_ORDER = 'SET_PURCHASING_MANAGEMENT_ISSUED_ORDER';
export type SET_PURCHASING_MANAGEMENT_ISSUED_ORDER = 'SET_PURCHASING_MANAGEMENT_ISSUED_ORDER';

// ------------ ACCOUNTS ------------

export const SET_ACCOUNT = 'SET_ACCOUNT';
export type SET_ACCOUNT = 'SET_ACCOUNT';

export const SET_ACCOUNT_FETCHING = 'SET_ACCOUNT_FETCHING';
export type SET_ACCOUNT_FETCHING = 'SET_ACCOUNT_FETCHING';

export const SET_ROLE_ASSIGNMENT_GROUPS = 'SET_ROLE_ASSIGNMENT_GROUPS';
export type SET_ROLE_ASSIGNMENT_GROUPS = 'SET_ROLE_ASSIGNMENT_GROUPS';

// ------------ NEWSLETTER ------------

export const SET_NEWSLETTER_CONTACT_FETCHING = 'SET_NEWSLETTER_CONTACT_FETCHING';
export type SET_NEWSLETTER_CONTACT_FETCHING = 'SET_NEWSLETTER_CONTACT_FETCHING';

// ------------ BYOC ------------

/* Collections */

export const SET_USER_COLLECTIONS_FETCHING = 'SET_USER_COLLETIONS_FETCHING';
export type SET_USER_COLLETIONS_FETCHING = 'SET_USER_COLLETIONS_FETCHING';

export const SET_USER_COLLECTIONS_FETCHING_FAILED = 'SET_USER_COLLETIONS_FETCHING_FAILED';
export type SET_USER_COLLETIONS_FETCHING_FAILED = 'SET_USER_COLLETIONS_FETCHING_FAILED';

export const SET_USER_COLLECTIONS_FETCHED = 'SET_USER_COLLECTIONS_FETCHED';
export type SET_USER_COLLECTIONS_FETCHED = 'SET_USER_COLLECTIONS_FETCHED';

export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export type DELETE_COLLECTION = 'DELETE_COLLECTION';

export const ADD_COLLECTION = 'ADD_COLLECTION';
export type ADD_COLLECTION = 'ADD_COLLECTION';

export const ADD_COLLECTIONS = 'ADD_COLLECTIONS';
export type ADD_COLLECTIONS = 'ADD_COLLECTIONS';

export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export type UPDATE_COLLECTION = 'UPDATE_COLLECTION';

export const SET_COLLECTION_FETCHING = 'SET_COLLECTION_FETCHING';
export type SET_COLLECTION_FETCHING = 'SET_COLLECTION_FETCHING';

export const SET_COLLECTION_FETCHING_FAILED = 'SET_COLLECTION_FETCHING_FAILED';
export type SET_COLLECTION_FETCHING_FAILED = 'SET_COLLECTION_FETCHING_FAILED';

export const SET_COLLECTION = 'SET_COLLECTION';
export type SET_COLLECTION = 'SET_COLLECTION';

export const SET_COLLECTION_SEARCH_QUERY = 'SET_COLLECTION_SEARCH_QUERY';
export type SET_COLLECTION_SEARCH_QUERY = 'SET_COLLECTION_SEARCH_QUERY';

export const UPDATE_BATCH_COLLECTION_REQUESTS = 'UPDATE_BATCH_COLLECTION_REQUESTS';
export type UPDATE_BATCH_COLLECTION_REQUESTS = 'UPDATE_BATCH_COLLECTION_REQUESTS';

export const UPDATE_SINGLE_BATCH_REQUEST = 'UPDATE_SINGLE_BATCH_REQUEST';
export type UPDATE_SINGLE_BATCH_REQUEST = 'UPDATE_SINGLE_BATCH_REQUEST';

export const MOVE_BATCH_REQUEST = 'MOVE_BATCH_REQUEST';
export type MOVE_BATCH_REQUEST = 'MOVE_BATCH_REQUEST';

export const REMOVE_BATCH_REQUEST = 'REMOVE_BATCH_REQUEST';
export type REMOVE_BATCH_REQUEST = 'REMOVE_BATCH_REQUEST';

/* Tiles */

export const SET_COLLECTION_TILES_FETCHING = 'SET_COLLECTION_TILES_FETCHING';
export type SET_COLLECTION_TILES_FETCHING = 'SET_COLLECTION_TILES_FETCHING';

export const SET_COLLECTION_TILES = 'SET_COLLECTION_TILES';
export type SET_COLLECTION_TILES = 'SET_COLLECTION_TILES';

export const ADD_COLLECTION_TILE = 'ADD_COLLECTION_TILE';
export type ADD_COLLECTION_TILE = 'ADD_COLLECTION_TILE';

export const DELETE_COLLECTION_TILE = 'DELETE_COLLECTION_TILE';
export type DELETE_COLLECTION_TILE = 'DELETE_COLLECTION_TILE';

export const UPDATE_COLLECTION_TILE = 'UPDATE_COLLECTION_TILE';
export type UPDATE_COLLECTION_TILE = 'UPDATE_COLLECTION_TILE';

// ------------ DASHBOARD ------------

// ------------ REQUEST STATISTICS ------------

export const SET_STATISTICS_TIME_RANGE = 'SET_STATISTICS_TIME_RANGE';
export type SET_STATISTICS_TIME_RANGE = 'SET_STATISTICS_TIME_RANGE';

export const SET_ARCHIVE_TIME_RANGE = 'SET_ARCHIVE_TIME_RANGE';
export type SET_ARCHIVE_TIME_RANGE = 'SET_ARCHIVE_TIME_RANGE';

export const SET_SELECT_ARCHIVE_TIME_RANGE = 'SET_SELECT_ARCHIVE_TIME_RANGE';
export type SET_SELECT_ARCHIVE_TIME_RANGE = 'SET_SELECT_ARCHIVE_TIME_RANGE';

/* Rate limited request count */

export const SET_RATE_LIMITED_REQUESTS_COUNT_FETCHING = 'SET_RATE_LIMITED_REQUESTS_COUNT_FETCHING';
export type SET_RATE_LIMITED_REQUESTS_COUNT_FETCHING = 'SET_RATE_LIMITED_REQUESTS_COUNT_FETCHING';

export const SET_RATE_LIMITED_REQUESTS_COUNT = 'SET_RATE_LIMITED_REQUESTS_COUNT';
export type SET_RATE_LIMITED_REQUESTS_COUNT = 'SET_RATE_LIMITED_REQUESTS_COUNT';

export const SET_RATE_LIMITED_REQUESTS_ERROR = 'SET_RATE_LIMITED_REQUESTS_ERROR';
export type SET_RATE_LIMITED_REQUESTS_ERROR = 'SET_RATE_LIMITED_REQUESTS_ERROR';

/* Statistics */
export const SET_STATISTICS_FETCHING = 'SET_STATISTICS_FETCHING';
export type SET_STATISTICS_FETCHING = 'SET_STATISTICS_FETCHING';

export const SET_STATISTICS = 'SET_STATISTICS';
export type SET_STATISTICS = 'SET_STATISTICS';

export const SET_STATISTICS_ERROR = 'SET_STATISTICS_ERROR';
export type SET_STATISTICS_ERROR = 'SET_STATISTICS_ERROR';

/* News */

export const SET_NEWS_FETCHING = 'SET_NEWS_FETCHING';
export type SET_NEWS_FETCHING = 'SET_NEWS_FETCHING';

export const SET_NEWS = 'SET_NEWS';
export type SET_NEWS = 'SET_NEWS';

export const SET_NEWS_ERROR = 'SET_NEWS_ERROR';
export type SET_NEWS_ERROR = 'SET_NEWS_ERROR';

/* User data */

export const SET_USER_DATA_FETCHING = 'SET_USER_DATA_FETCHING';
export type SET_USER_DATA_FETCHING = 'SET_USER_DATA_FETCHING';

export const SET_USER_DATA = 'SET_USER_DATA';
export type SET_USER_DATA = 'SET_USER_DATA';

export const PATCH_USER_DATA = 'PATCH_USER_DATA';
export type PATCH_USER_DATA = 'PATCH_USER_DATA';

export const SET_USER_DATA_ERROR = 'SET_USER_DATA_ERROR';
export type SET_USER_DATA_ERROR = 'SET_USER_DATA_ERROR';

// BATCH

export const SET_FETCHING_BATCH_REQUEST = 'SET_FETCHING_BATCH_REQUEST';
export type SET_FETCHING_BATCH_REQUEST = 'SET_FETCHING_BATCH_REQUEST';

export const SET_BATCH_REQUESTS = 'SET_BATCH_REQUESTS';
export type SET_BATCH_REQUESTS = 'SET_BATCH_REQUESTS';

export const SET_BATCH_PAGE = 'SET_BATCH_PAGE';
export type SET_BATCH_PAGE = 'SET_BATCH_PAGE';

export const SET_BATCH_SEARCH_RESULTS = 'SET_BATCH_SEARCH_RESULTS';
export type SET_BATCH_SEARCH_RESULTS = 'SET_BATCH_SEARCH_RESULTS';

export const SET_BATCH_ERROR = 'SET_BATCH_ERROR';
export type SET_BATCH_ERROR = 'SET_BATCH_ERROR';

export const RESET_BATCH_SEARCH_RESULTS = 'RESET_BATCH_SEARCH_RESULTS';
export type RESET_BATCH_SEARCH_RESULTS = 'RESET_BATCH_SEARCH_RESULTS';

/* TPDI */

export const SET_TPDI_QUOTA_FETCHING = 'SET_TPDI_QUOTA_FETCHING';
export type SET_TPDI_QUOTA_FETCHING = 'SET_TPDI_QUOTA_FETCHING';

export const SET_TPDI_QUOTA = 'SET_TPDI_QUOTA';
export type SET_TPDI_QUOTA = 'SET_TPDI_QUOTA';

export const SET_TPDI_QUOTA_ERROR = 'SET_TPDI_QUOTA_ERROR';
export type SET_TPDI_QUOTA_ERROR = 'SET_TPDI_QUOTA_ERROR';

export const SET_TPDI_ORDERS = 'SET_TPDI_ORDERS';
export type SET_TPDI_ORDERS = 'SET_TPDI_ORDERS';

export const SET_FETCHING_TPDI_ORDERS = 'SET_FETCHING_TPDI_ORDERS';
export type SET_FETCHING_TPDI_ORDERS = 'SET_FETCHING_TPDI_ORDERS';

export const SET_TPDI_PAGE = 'SET_TPDI_PAGE';
export type SET_TPDI_PAGE = 'SET_TPDI_PAGE';

export const SET_TPDI_ERROR = 'SET_TPDI_ERROR';
export type SET_TPDI_ERROR = 'SET_TPDI_ERROR';

/* BATCH */

export const SET_BATCH_DEPLOYMENT = 'SET_BATCH_DEPLOYMENT';
export type SET_BATCH_DEPLOYMENT = 'SET_BATCH_DEPLOYMENT';

// STAT BATCH

export const SET_STAT_BATCH_DEPLOYMENT = 'SET_STAT_BATCH_DEPLOYMENT';
export type SET_STAT_BATCH_DEPLOYMENT = 'SET_STAT_BATCH_DEPLOYMENT';
