import config from '@config/config';

import api from '../../../lib/api';
import { ResourceFetch } from '../../../meta/types/api';
import { UserBillingInfoDto } from '../../../meta/types/billing/billingInfo';

const { GET, POST, DELETE, PUT } = api;

const BILLING_INFO_RESOURCE_BASE_PATH = `${config.dashboardServiceBasePath}/dashboard/v1/purchase/billinginfo`;

export interface BillingInfoResource {
  listBillingInfo: ResourceFetch<UserBillingInfoResponse, { userId: string }>;
  createBillingInfo: ResourceFetch<UserBillingInfoDto, UserBillingInfoDto>;
  deleteBillingInfo: ResourceFetch<void, { id: number }>;
  updateUserBillingInfo: ResourceFetch<UserBillingInfoDto, UserBillingInfoDto>;
  updateUserBillingInfoForOrder: ResourceFetch<
    UserBillingInfoDto,
    UserBillingInfoDto & { orderId: string }
  >;
  getBillingInfo: ResourceFetch<UserBillingInfoDto, { id: number }>;
}

export type UserBillingInfoResponse = {
  member: UserBillingInfoDto[];
};

const BillingInfoResource: BillingInfoResource = {
  listBillingInfo: GET(BILLING_INFO_RESOURCE_BASE_PATH),
  updateUserBillingInfo: PUT(BILLING_INFO_RESOURCE_BASE_PATH),
  updateUserBillingInfoForOrder: PUT(`${BILLING_INFO_RESOURCE_BASE_PATH}?orderId=:orderId`),
  createBillingInfo: POST(BILLING_INFO_RESOURCE_BASE_PATH),
  deleteBillingInfo: DELETE(`${BILLING_INFO_RESOURCE_BASE_PATH}/:id`),
  getBillingInfo: GET(`${BILLING_INFO_RESOURCE_BASE_PATH}/:id`),
};

export default BillingInfoResource;
