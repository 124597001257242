import * as Sentry from 'raven-js';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { localLogout } from '../actions/auth';
import api from '../lib/api';
import * as reducers from '../reducers';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const middleware = [thunk];

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancers = composeEnhancers(applyMiddleware(...middleware));

export default function initStore(initialState: object = {}) {
  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  const rootReducer = combineReducers<any, any>({
    ...reducers,
  });

  const store = createStore(rootReducer, initialState, enhancers);

  api.on('401', () => {
    Sentry.captureBreadcrumb({
      message: '401 UNAUTHORIZED received from API',
      category: 'auth',
      level: 'info',
    });
    // store.dispatch(localLogout());
  });

  return store;
}
