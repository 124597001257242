import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import TargetBlankLink from '@src/components/common/TargetBlankLink';
import SuccessMessage from '@src/components/common/banners/SuccessMessage';
import WarningMessage from '@src/components/common/banners/WarningMessage';
import { parseBoolean } from '@src/utils/parsing';
import { styled } from '@src/styles/Theme';

import {
  NEW_ACCOUNT_PARAM,
  PLANET_UNIVERSITY_LINK,
  SUPPORT_CENTER_LINK,
  UPGRADE_STATUS_PARAM,
  UpgradeStatus,
  fixUrl,
} from './const';

function AccountLinkingBanner() {
  const [isNewAccount, setIsNewAccount] = useState<boolean | null>(null);
  const [upgradeStatus, setUpgradeStatus] = useState<UpgradeStatus | null>(null);

  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search || location.search);
    setUpgradeStatus(urlParams.get(UPGRADE_STATUS_PARAM) as UpgradeStatus | null);
    setIsNewAccount(parseBoolean(urlParams.get(NEW_ACCOUNT_PARAM)));
    fixUrl(urlParams, location);
    urlParams.delete(UPGRADE_STATUS_PARAM);
    urlParams.delete(NEW_ACCOUNT_PARAM);
  }, [location]);

  if (isNewAccount === null || upgradeStatus === null) {
    return null;
  }

  if (isNewAccount) {
    // UpgradeStatus status is always "false" in this case, because it's a new account
    return (
      <StyledWrapper>
        <SuccessMessage>
          Success! You have created your Sentinel Hub account and unlocked access to 6 month trial
          for Planet users. Learn more on our{' '}
          <TargetBlankLink href={SUPPORT_CENTER_LINK}>Support Center</TargetBlankLink> and{' '}
          <TargetBlankLink href={PLANET_UNIVERSITY_LINK}>Planet University</TargetBlankLink>.
        </SuccessMessage>
      </StyledWrapper>
    );
  }

  if (isNewAccount === false && upgradeStatus === UpgradeStatus.UPGRADED) {
    return (
      <StyledWrapper>
        <SuccessMessage>
          Success! Your user has been linked and you have upgraded your access to Sentinel Hub.
          Learn more on our{' '}
          <TargetBlankLink href={SUPPORT_CENTER_LINK}>Support Center</TargetBlankLink> and{' '}
          <TargetBlankLink href={PLANET_UNIVERSITY_LINK}>Planet University</TargetBlankLink>.
        </SuccessMessage>
      </StyledWrapper>
    );
  }

  if (isNewAccount === false && upgradeStatus === UpgradeStatus.NOT_ELIGIBLE) {
    return (
      <StyledWrapper>
        <WarningMessage>
          Your user has been linked, but you are not eligible for the Sentinel Hub Trial for Planet
          customers. Please visit the{' '}
          <TargetBlankLink href={SUPPORT_CENTER_LINK}>Support Center</TargetBlankLink> to learn more
          about eligibility.
        </WarningMessage>
      </StyledWrapper>
    );
  }

  return null;
}

export default AccountLinkingBanner;

const StyledWrapper = styled.div`
  margin: 1rem;
`;
