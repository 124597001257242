import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { redirectToLogin } from '@modules/auth/components/lib';
import { StoreState } from '../../../../meta/types/store';
import {
  COLLAPSED_SIDEBAR_WIDTH,
  EXTENDED_SIDEBAR_WIDTH,
  NAVBAR_HEIGHT,
} from '../../../../styles/Sidebar';
import { styled } from '../../../../styles/Theme';
import NavbarContainer from '../../../nav/Navbar';
import SidebarContainer from '../../../nav/Sidebar';

type PropsFromState = {
  sidebarCollapsed: boolean;
  isLoggedIn: boolean;
};

type Props = PropsFromState & {
  children: React.ReactNode;
};

const AuthRoutes = ({ isLoggedIn, children, sidebarCollapsed }: Props) => {
  const location = useLocation();

  if (!isLoggedIn) {
    redirectToLogin(location.pathname + location.search);
    return null;
  }

  return (
    <>
      <NavbarContainer />
      <section className="main-section">
        <MainSectionWithSidebarStyle
          className="main-section__with-sidebar"
          sidebarCollapsed={sidebarCollapsed}
        >
          <SidebarContainer />
          <section style={{ width: '100%' }}>{children}</section>
        </MainSectionWithSidebarStyle>
      </section>
    </>
  );
};

type StyleProps = {
  sidebarCollapsed: boolean;
};

const MainSectionWithSidebarStyle = styled.section<StyleProps>`
  padding-left: ${props =>
    props.sidebarCollapsed ? COLLAPSED_SIDEBAR_WIDTH : EXTENDED_SIDEBAR_WIDTH};
  margin-top: ${NAVBAR_HEIGHT};
  transition: padding 0.3s;
`;

function mapStateToProps(state: StoreState): PropsFromState {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    sidebarCollapsed: state.ui.sidebarCollapsed,
  };
}

const enhance = connect(mapStateToProps);

export default enhance(AuthRoutes);
