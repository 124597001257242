import React from 'react';
import { Link } from 'react-router-dom';
import { List } from 'semantic-ui-react';
import config from '@config/config';

import {
  hasAccessToByoc,
  hasAccessToBatch,
  hasAccessToBatchStatistical,
  hasAccessToTPDI,
} from '@modules/auth/components/constants/accounts/access';
import BrowserSidebarLink from '@modules/common/components/Sidebar/BrowserSidebarLink';

import { SidebarContext } from '.';
import { ROOT_PATH, SidebarTab } from '../../../constants/paths/internal';
import {
  SidebarStyle,
  COLLAPSED_SIDEBAR_WIDTH,
  EXTENDED_SIDEBAR_WIDTH,
} from '../../../styles/Sidebar';
import SidebarListItem from './Item';

type Props = {
  setSidebarCollapsed: (collapsed: boolean) => void;
};

const LOGO_STYLE = {
  background: `${() => config.sidebarColor} !important`,
  transition: 'width 0.3s, left 0.3s',
};

const Sidebar = ({ setSidebarCollapsed }: Props) => {
  return (
    <SidebarContext.Consumer>
      {({ sidebarCollapsed, activeTab, rootManaging, hasUnseenNews, shAccount }) => {
        const hasActiveAccount = shAccount !== undefined;
        const shouldShowByocTab = hasAccessToByoc(shAccount?.roleAssignments);
        const shouldShowBatchTab = hasAccessToBatch(shAccount?.roleAssignments);
        const shouldShowBatchStatisticalTab = hasAccessToBatchStatistical(
          shAccount?.roleAssignments,
        );
        const shouldShowTPDITab = hasAccessToTPDI(shAccount?.roleAssignments);

        return (
          <SidebarStyle sidebarCollapsed={sidebarCollapsed} className="sidebar">
            <List className="sidebar-list">
              <div
                className="sidebar-logo content-center"
                style={{
                  ...LOGO_STYLE,
                  width: sidebarCollapsed ? COLLAPSED_SIDEBAR_WIDTH : EXTENDED_SIDEBAR_WIDTH,
                }}
              >
                <Link to={ROOT_PATH} style={{ height: '100%' }}>
                  <img
                    src={sidebarCollapsed ? config.collapsedLogo : config.logo}
                    className="logo"
                    alt="Logo"
                  />
                </Link>
              </div>

              <SidebarListItem
                icon="grid layout"
                content="Dashboard"
                active={activeTab === SidebarTab.DASHBOARD}
                to={ROOT_PATH}
                popupContent={sidebarCollapsed ? 'Dashboard' : undefined}
              />

              {config.configurationUtilityEnabled && (
                <SidebarListItem
                  icon="cubes"
                  content="Configuration Utility"
                  active={activeTab === SidebarTab.CONFIGURATOR}
                  to={config.CONFIGURATIONS_UTILITY_PATH}
                  disabled={rootManaging ? false : !hasActiveAccount}
                  popupContent={
                    !hasActiveAccount
                      ? 'Your account is invalid. You are not allowed to manage your configurations.'
                      : 'Configuration Utility'
                  }
                />
              )}

              {config.collectionsEnabled && shouldShowByocTab && (
                <SidebarListItem
                  icon="map outline"
                  content="My Collections"
                  active={activeTab === SidebarTab.BYOC}
                  to={config.BYOC_PATH}
                  popupContent={
                    !hasActiveAccount
                      ? 'Your account is invalid. You are not allowed to bring your own configurations.'
                      : 'Bring your own COG'
                  }
                />
              )}

              {config.usageEnabled && (
                <SidebarListItem
                  icon="chart bar"
                  content="Usage"
                  active={activeTab === SidebarTab.STATISTICS}
                  to={config.USAGE_PATH}
                  popupContent={sidebarCollapsed ? 'Usage' : undefined}
                />
              )}

              {config.batchEnabled && shouldShowBatchTab && (
                <SidebarListItem
                  icon="world"
                  content="Batch"
                  active={activeTab === SidebarTab.BATCH}
                  to={config.BATCH_PATH}
                />
              )}

              {config.statisticalBatchEnabled && shouldShowBatchStatisticalTab && (
                <SidebarListItem
                  icon="world"
                  content="Batch Statistical"
                  active={activeTab === SidebarTab.STATAPI_BATCH}
                  to={config.STATAPI_BATCH_PATH}
                />
              )}

              {config.tpdiEnabled && shouldShowTPDITab && (
                <SidebarListItem
                  icon="cloud download"
                  content="Commercial Data"
                  active={activeTab === SidebarTab.TPDI}
                  to={config.TPDI_PATH}
                />
              )}

              {config.showLinkToBrowserTab && <BrowserSidebarLink />}
            </List>

            <div className="sidebar__bottom">
              <List>
                <SidebarListItem
                  icon="settings"
                  content="User settings"
                  active={activeTab === SidebarTab.ACCOUNT_SETTINGS}
                  to={config.USER_SETTINGS_PATH}
                  popupContent={sidebarCollapsed ? 'User settings' : undefined}
                />

                {config.billingEnabled && (
                  <>
                    <SidebarListItem
                      icon="dropbox"
                      content="Plans"
                      active={activeTab === SidebarTab.PLANS}
                      to={config.BILLING_PATH}
                      popupContent={sidebarCollapsed ? 'Plans' : undefined}
                    />

                    {config.billingHistoryEnabled && (
                      <SidebarListItem
                        icon="credit card outline"
                        content="Billing"
                        active={activeTab === SidebarTab.BILLING}
                        to={config.ORDER_HISTORY}
                        popupContent={sidebarCollapsed ? 'Billing' : undefined}
                      />
                    )}
                  </>
                )}

                {config.helpEnabled && (
                  <SidebarListItem
                    icon="question"
                    active={activeTab === SidebarTab.HELP}
                    content="Help"
                    to={config.HELP_PAGE_PATH}
                    popupContent={sidebarCollapsed ? 'Help' : undefined}
                  />
                )}
                {config.newsEnabled && (
                  <SidebarListItem
                    icon="bullhorn"
                    additionalIcon={
                      hasUnseenNews ? (
                        <i
                          className="circle top right corner icon"
                          style={{ color: 'palevioletred' }}
                        />
                      ) : undefined
                    }
                    active={activeTab === SidebarTab.NEWS}
                    content="What's new"
                    to={config.NEWS_PATH}
                    popupContent={sidebarCollapsed ? "What's new" : undefined}
                  />
                )}
                <div className="sidebar-collapse-item">
                  <SidebarListItem
                    icon={sidebarCollapsed ? 'angle double right' : 'angle double left'}
                    active={false}
                    content="Collapse Sidebar"
                    onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
                    popupContent={sidebarCollapsed ? 'Expand sidebar' : undefined}
                  />
                </div>
              </List>
            </div>
          </SidebarStyle>
        );
      }}
    </SidebarContext.Consumer>
  );
};

export default React.memo(Sidebar);
