import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import BaseModal from '@src/components/common/BaseModal';
import { SalesPackage } from '@src/meta/types/billing/salespackage';
import { PaymentFrequencyType, Currency } from '@src/meta/types/billing/payment';
import PrimaryButton from '@src/components/common/buttons/PrimaryButton';
import SecondaryButton from '@src/components/common/buttons/SecondaryButton';
import { styled } from '@src/styles/Theme';
import config from '@config/config';
import { IAddToCart, addToCart } from '@src/actions/billing';
import { CartItemPayload } from '@src/meta/types/billing/cart';
import { StoreState } from '@src/meta/types/store';

import PaymentFrequencyTypePicker from './PaymentFrequencyTypePicker';
import { getCurrencyFromCode } from '../../utils';

type Props = {
  trigger: (handleOpen: () => void) => React.ReactNode;
  plan: SalesPackage;
  numberOfPackages?: number;
  isEditing?: boolean;
};

type PropsFromStore = {
  currency: Currency;
};

type PropsFromDispatch = {
  addToCart: (item: CartItemPayload) => IAddToCart;
};

const SettingsModal = ({
  trigger,
  plan,
  numberOfPackages,
  history,
  addToCart,
  currency,
  isEditing = false,
}: Props & RouteComponentProps & PropsFromDispatch & PropsFromStore) => {
  const [selectedPaymentStrategy, setSelectedPaymentStrategy] = useState(
    getPreferredStrategy(plan),
  );
  const [amountFactorPackages, setAmountFactorPackages] = useState(numberOfPackages || 1);

  useEffect(() => {
    setSelectedPaymentStrategy(getPreferredStrategy(plan));
  }, [plan]);

  function getPreferredStrategy(plan: SalesPackage) {
    // We try to find a non-recurring payment strategy. Backend should probably set the preferred strategy.
    return (
      plan.paymentStrategies.find(s => s.paymentFrequencyType === PaymentFrequencyType.SINGLE)
        ?.id ||
      plan.paymentStrategies[0]?.id ||
      0
    );
  }

  function addSelectedToCart(): void {
    addToCart({
      salesPackageId: plan.planId,
      paymentStrategyId: selectedPaymentStrategy,
      numberOfPackages: amountFactorPackages,
    });
  }

  function goToCheckout(): void {
    addSelectedToCart();
    history.push(config.CHECKOUT_PATH);
  }

  return (
    <BaseModal
      style={{ width: 'min-content', height: 'min-content' }}
      trigger={trigger}
      size="large"
      closeOnDimmerClick={false}
    >
      {handleClose => (
        <StyledModal>
          <StyledHeader>
            <div className="title">Package Type</div>
          </StyledHeader>
          <StyledModalContent>
            <PaymentFrequencyTypePicker
              plan={plan}
              selectedPaymentStrategy={selectedPaymentStrategy}
              setSelectedPaymentStrategy={setSelectedPaymentStrategy}
              amountFactorPackages={amountFactorPackages}
              setAmountFactorPackages={setAmountFactorPackages}
              currency={currency}
            />
            <StyledButtonHolder>
              {isEditing ? (
                <StyledPrimaryButton
                  content="Confirm"
                  icon="save"
                  onClick={() => {
                    addSelectedToCart();
                    handleClose();
                  }}
                />
              ) : (
                <StyledPrimaryButton content="Checkout" onClick={goToCheckout} />
              )}
              <SecondaryButton content="Cancel" onClick={handleClose} icon="remove" size="medium" />
            </StyledButtonHolder>
          </StyledModalContent>
        </StyledModal>
      )}
    </BaseModal>
  );
};

function mapStateToProps(state: StoreState): PropsFromStore {
  return {
    currency: getCurrencyFromCode(
      state.billing.selectedCurrency,
      state.billing.supportedCurrencies,
    ),
  };
}

const mapDispatchToProps: PropsFromDispatch = {
  addToCart,
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SettingsModal));

export const StyledModal = styled.div`
  background: ${config.backgroundGrey};
`;

export const StyledHeader = styled.div`
  padding: ${props => props.theme.padding3};
  background: ${config.primaryColor};
  text-align: center;
  position: relative;

  .title {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
  }

  .close-button {
    border-radius: 0;
    padding: ${props => props.theme.padding1} !important;
    position: absolute;
    right: 8px;
    top: 7px;
  }
`;

export const StyledButtonHolder = styled.div`
  margin-top: ${props => props.theme.padding6};
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  font-size: 1rem !important;
  font-weight: bold !important;
  margin-right: 1rem !important;
`;

export const StyledModalContent = styled.div`
  padding: ${props => `${props.theme.padding8} ${props.theme.padding8} ${props.theme.padding6}`};
`;
