import config from '@config/config';

import api from '../../../lib/api';
import { ResourceFetch } from '../../../meta/types/api';
import { ServerOrder } from '../../../meta/types/billing/order';
import {
  VatVerificationResponse,
  CalculateVatRequestDto,
  CalculateVatResponseDto,
} from '../../../meta/types/billing/vat';

const PURCHASE_RESOURCE_BASE_PATH = `${config.dashboardServiceBasePath}/dashboard/v1/purchase`;

const { GET, POST } = api;

export interface VatResource {
  verify: ResourceFetch<VatVerificationResponse, { countryCode: string; vatNumber: string }>;
  calculate: ResourceFetch<CalculateVatResponseDto, CalculateVatRequestDto>;
}

export interface PurchaseResponse {
  order: ServerOrder;
}

const VatResource: VatResource = {
  calculate: POST(`${PURCHASE_RESOURCE_BASE_PATH}/vat/calculate`),
  verify: GET(`${PURCHASE_RESOURCE_BASE_PATH}/vat/verify`),
};

export default VatResource;
