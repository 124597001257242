import React from 'react';
import posed, { PoseGroup } from 'react-pose';
import config from '@config/config';

import AsyncLoad from '@src/components/common/AsyncLoad';
import { BaseAuthRoute } from '@src/components/common/Route';
import { withTracker } from '@src/hocs';

import { Props, BillingType } from './types';
import BillingContextProvider from './components/context';

export const RouteContainer = posed.div({
  enter: { opacity: 1, beforeChildren: true, delay: 125 },
  exit: { opacity: 0 },
});

const AsyncBillingPage = AsyncLoad({
  loader: () => import('./components/BillingPage'),
}) as React.ComponentClass<any>;
const AsyncBillingPageWithTracker = withTracker(AsyncBillingPage);

function BillingBase({}: Props): BillingType {
  return () => {
    return (
      <PoseGroup className="billing-routes-container">
        <RouteContainer key="billing-routes-container">
          <BillingContextProvider>
            <BaseAuthRoute path={config.BILLING_PATH} component={AsyncBillingPageWithTracker} />
          </BillingContextProvider>
        </RouteContainer>
      </PoseGroup>
    );
  };
}
export * from './types';
export { BillingBase };
export default BillingBase({});
