import React from 'react';

import { AccountInfo } from '@modules/auth/components/types/user';
import UpgradeAccountButton from '@modules/billing/widgets/UpgradeAccountButton';

import { SidebarTab } from '../../../constants/paths/internal';
import { NavbarStyle } from '../../../styles/Navbar';
import AccountDropdownItem from './AccountDropdownItem';
import NavbarBreadcrumb from './NavbarBreadcrumb';
import StopImpersonatingButton from './StopImpersonatingButton';

type Props = {
  sidebarCollapsed: boolean;
  rootManaging: boolean;
  account?: AccountInfo;
  activeTab: SidebarTab;
};

const Navbar = ({ sidebarCollapsed, rootManaging, account, activeTab }: Props) => {
  return (
    <NavbarStyle className="navbar" sidebarCollapsed={sidebarCollapsed}>
      <div className="navbar-active-tab">
        <NavbarBreadcrumb />
      </div>
      <ul className="navbar-nav">
        <StopImpersonatingButton isImpersonating={rootManaging} />
        <UpgradeAccountButton rootManaging={rootManaging} account={account} />
        <li>
          <AccountDropdownItem />
        </li>
      </ul>
    </NavbarStyle>
  );
};

export default React.memo(Navbar);
