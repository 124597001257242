import StringUtils from '@src/lib/string';

import { Claims, SentinelUser } from '@modules/auth/components/types/user';

export function canUserManageConfigurations(claims: Claims) {
  return true;
}

function getFullName(user: SentinelUser) {
  return `${user.givenName || ''} ${user.familyName || ''}`;
}

export function getDisplayedName(user: SentinelUser) {
  const fullName = getFullName(user);
  return StringUtils.isNonEmpty(fullName) ? fullName : user.email;
}

function getClaimsFullName(user: Claims) {
  return `${user.given_name || ''} ${user.family_name || ''}`;
}

export function getClaimsDisplayedName(claims: Claims | undefined) {
  if (!claims) {
    return '';
  }
  const fullName = getClaimsFullName(claims);
  return StringUtils.isNonEmpty(fullName) ? fullName : claims.email;
}

export function getUserName(user: SentinelUser) {
  let name = '';
  if (user.givenName) {
    name += user.givenName;
    if (user.familyName) {
      name = `${name} ${user.familyName}`;
    }
  }
  return name;
}
