export type TermsDto = {
  text: string;
  url: string;
};

export type CollectionPolicyDto = {
  collectionId: string;
  terms: TermsDto[];
  termsUrl: string;
};

export enum AccessRestrictions {
  OUT_OF_REQUESTS = 'OUT_OF_REQUESTS',
  OUT_OF_PUS = 'OUT_OF_PUS',
  BANNED = 'BANNED',
}

export interface AccountWithCollectionAccess {
  created: Date;
  domainAccountId: string;
  notes: string;
  rights: string[];
}

export type AccessRestrictionsRecordDto = {
  accountId: string;
  restrictions: AccessRestrictions[];
  updatedBy: string;
  notes: string;
  userNotes: string | undefined;
  jnStatus: string;
  jnCreated: Date;
};
