import React, { PropsWithChildren, JSXElementConstructor } from 'react';

import { PaymentFrequencyType } from '@src/meta/types/billing/payment';
import {
  PaymentStrategy,
  SalesPackage,
  BILLING_PLAN_MANAGEMENT_OFFER,
  BILLING_PLAN_RETRY,
} from '@src/meta/types/billing/salespackage';
import ClickableSpan from '@src/components/common/ClickableSpan';

export function getSalesPackagesRows(salesPackages: SalesPackage[]) {
  const gridRows = new Set<number>();
  const perRowPlans = new Map();

  salesPackages.forEach(plan => {
    if (plan.planId === BILLING_PLAN_MANAGEMENT_OFFER || plan.planId === BILLING_PLAN_RETRY) {
      return;
    }
    const { displayRow } = plan;
    let plans: SalesPackage[];
    if (!gridRows.has(displayRow)) {
      gridRows.add(displayRow);
      plans = [];
      perRowPlans.set(displayRow, plans);
    } else {
      plans = perRowPlans.get(displayRow);
    }
    plans.push(plan);
  });
  return { gridRows: Array.from(gridRows), perRowPlans };
}

export function isIncludedByText(salesPackage: SalesPackage, filter: string): boolean {
  return (
    salesPackage.planId.toLowerCase().includes(filter.toLowerCase()) ||
    salesPackage.name.toLowerCase().includes(filter.toLowerCase()) ||
    salesPackage.description.toLowerCase().includes(filter.toLowerCase())
  );
}

export function isIncludedByPaymentFrequency(
  salesPackage: SalesPackage,
  paymentFrequencyType: PaymentFrequencyType | null,
): boolean {
  if (paymentFrequencyType === null || salesPackage.planId === 'TOP_UP_PUS') {
    // TODO: don't hardcode
    return true;
  }
  return salesPackage.paymentStrategies.some(
    ps => ps.paymentFrequencyType === paymentFrequencyType,
  );
}

export function isIncludedByGroup(salesPackage: SalesPackage, group: string | undefined): boolean {
  return group ? salesPackage.packageTypeGroup?.toLowerCase() === group.toLowerCase() : true;
}

export function generateFilteredPackage(
  salesPackage: SalesPackage,
  paymentFrequencyType: PaymentFrequencyType | null,
): SalesPackage {
  if (salesPackage.planId === 'TOP_UP_PUS') {
    // TODO: don't hardcode
    return salesPackage;
  }
  return {
    ...salesPackage,
    paymentStrategies: salesPackage.paymentStrategies.filter(ps =>
      filterStrategyByPaymentFrequencyType(ps, paymentFrequencyType),
    ),
  };
}

function filterStrategyByPaymentFrequencyType(
  paymentStrategy: PaymentStrategy,
  paymentFrequencyType: PaymentFrequencyType | null,
) {
  if (paymentFrequencyType === null) {
    return true;
  }
  return paymentStrategy.paymentFrequencyType === paymentFrequencyType;
}

export function getMdLinkFunction(Elem: JSXElementConstructor<any>) {
  return (props: PropsWithChildren<any>) => (
    <Elem
      onClick={(e: any) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(props.href, props.target);
      }}
    >
      {props.children}
    </Elem>
  );
}

export function mdLinkFunction(props: PropsWithChildren<any>) {
  return getMdLinkFunction(ClickableSpan)(props);
}
