import produce, { Draft } from 'immer';
import { getDefaultBatchDeployment } from '@modules/batch/components/lib';

import { ISetBatchDeployment } from '../../actions/batch';
import * as actionTypes from '../../constants/actionTypes';

const initialState = Object.freeze({
  selectedDeployment: getDefaultBatchDeployment(),
});

export type BatchState = typeof initialState;

type BatchAction = ISetBatchDeployment;

const batch = (state: BatchState = initialState, action: BatchAction) => {
  return produce<BatchState>(state, (draft: Draft<BatchState>) => {
    switch (action.type) {
      case actionTypes.SET_BATCH_DEPLOYMENT:
        draft.selectedDeployment = action.deployment;
        return;
      default:
        return;
    }
  });
};

export default batch;
