import React from 'react';
import { MessageProps, Icon } from 'semantic-ui-react';
import BaseBanner from './BaseMessage';
import { styled } from '../../../styles/Theme';
import { getBannerIconStyles } from './WarningMessage';

type Props = {
  header?: string;
} & MessageProps;

const ErrorBanner = (props: Props) => {
  return (
    <StyledErrorBanner style={props.style}>
      <div className="icon-wrapper">
        <Icon name="x" />
      </div>
      <BaseBanner {...props} style={{ width: '100%', marginTop: '0' }}>
        {props.children}
      </BaseBanner>
    </StyledErrorBanner>
  );
};

export default ErrorBanner;

const StyledErrorBanner = styled.div`
  display: flex;
  width: 100%;

  ${getBannerIconStyles('#bf2c00')}
`;
