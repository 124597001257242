import produce, { Draft } from 'immer';
import { Dictionary } from 'lodash';

import { AVAILABLE_TIME_RANGES } from '@modules/usage/components/RequestStatistics/TimeRangePicker';

import { ISetManagementIssuedOrder } from '../../actions/billing';
import {
  IPatchUserData,
  ISetArchiveTimeRange,
  ISetNews,
  ISetNewsError,
  ISetNewsFetching,
  ISetRateLimitedOgcCount,
  ISetRateLimitedOgcCountFetching,
  ISetRateLimitedOgcError,
  ISetStatistics,
  ISetStatisticsError,
  ISetStatisticsFetching,
  ISetStatisticsTimeRange,
  ISetUserData,
  ISetUserDataError,
  ISetUserDataFetching,
  ISetUsingArchiveTimeRange,
  ISetTpdiQuota,
  ISetTpdiQuotaError,
  ISetTpdiQuotaFetching,
} from '../../actions/dashboard';
import * as actionTypes from '../../constants/actionTypes';
import { Cache } from '../../meta/types/requests-statistics';
import { NewsEntry } from '../../meta/types/news';
import { UserData } from '../../meta/types/userdata';
import { QuotaPair } from '../../meta/types/tpdi';

const currentDate = new Date();

export const initialState = Object.freeze({
  fetchingRateLimitedCount: false,
  statisticsCache: {
    rateLimitedRequestsCount: {},
    statistics: {},
  } as Cache,
  selectedTimeRange: AVAILABLE_TIME_RANGES[4],
  archiveTimeRange: {
    month: currentDate.getMonth().toString(),
    year: currentDate.getFullYear().toString(),
    isSelected: false,
  },
  fetchingStatistics: false,
  fetchingNews: false,
  fetchingUserData: false,
  fetchingTpdiQuotas: false,
});

export type DashboardState = typeof initialState & {
  rateLimitedRequestsError?: string;
  statisticsError?: string;
  news?: NewsEntry[];
  newsError?: string;
  userData?: UserData;
  userDataError?: string;
  tpdiQuotasError?: string;
  tpdiQuotas?: Dictionary<QuotaPair>;
};

type DashboardAction =
  | ISetRateLimitedOgcCount
  | ISetRateLimitedOgcCountFetching
  | ISetRateLimitedOgcError
  | ISetStatisticsTimeRange
  | ISetArchiveTimeRange
  | ISetUsingArchiveTimeRange
  | ISetManagementIssuedOrder
  | ISetStatistics
  | ISetStatisticsFetching
  | ISetStatisticsError
  | ISetNews
  | ISetNewsFetching
  | ISetNewsError
  | ISetUserData
  | ISetUserDataFetching
  | ISetUserDataError
  | ISetTpdiQuota
  | ISetTpdiQuotaError
  | ISetTpdiQuotaFetching
  | IPatchUserData;

const dashboard = (state: DashboardState = initialState, action: DashboardAction) => {
  return produce<DashboardState>(state, (draft: Draft<DashboardState>) => {
    switch (action.type) {
      case actionTypes.SET_STATISTICS_ERROR:
        draft.fetchingStatistics = false;
        draft.statisticsError = action.message;
        return;
      case actionTypes.SET_STATISTICS_FETCHING:
        draft.fetchingStatistics = true;
        return;
      case actionTypes.SET_STATISTICS:
        draft.statisticsCache.statistics[action.timeRange.value] = action.cost;
        draft.fetchingStatistics = false;
        return;
      case actionTypes.SET_RATE_LIMITED_REQUESTS_ERROR:
        draft.rateLimitedRequestsError = action.message;
        draft.fetchingRateLimitedCount = false;
        return;
      case actionTypes.SET_STATISTICS_TIME_RANGE:
        draft.selectedTimeRange = action.timeRange;
        return;
      case actionTypes.SET_ARCHIVE_TIME_RANGE:
        draft.archiveTimeRange.month =
          action.archiveTimeRange.month ?? draft.archiveTimeRange.month;
        draft.archiveTimeRange.year = action.archiveTimeRange.year ?? draft.archiveTimeRange.year;
        return;
      case actionTypes.SET_SELECT_ARCHIVE_TIME_RANGE:
        draft.archiveTimeRange.isSelected = action.isUsingArchive;
        return;
      case actionTypes.SET_RATE_LIMITED_REQUESTS_COUNT:
        draft.fetchingRateLimitedCount = false;
        draft.statisticsCache.rateLimitedRequestsCount[action.timeRange.value] = action.count;
        return;
      case actionTypes.SET_RATE_LIMITED_REQUESTS_COUNT_FETCHING:
        draft.rateLimitedRequestsError = undefined;
        draft.fetchingRateLimitedCount = action.fetching;
        return;
      case actionTypes.SET_NEWS_FETCHING:
        draft.fetchingNews = true;
        return;
      case actionTypes.SET_NEWS_ERROR:
        draft.fetchingNews = false;
        draft.newsError = action.message;
        return;
      case actionTypes.SET_NEWS:
        draft.fetchingNews = false;
        draft.news = action.news;
        return;
      case actionTypes.SET_USER_DATA_FETCHING:
        draft.fetchingUserData = true;
        return;
      case actionTypes.SET_USER_DATA_ERROR:
        draft.fetchingUserData = false;
        draft.userDataError = action.message;
        return;
      case actionTypes.SET_USER_DATA:
        draft.fetchingUserData = false;
        draft.userData = action.userData;
        return;
      case actionTypes.PATCH_USER_DATA:
        draft.userData = {
          ...draft.userData,
          ...action.userData,
        };
        return;
      case actionTypes.SET_TPDI_QUOTA:
        draft.tpdiQuotas = action.quotas;
        draft.fetchingTpdiQuotas = false;
        return;
      case actionTypes.SET_TPDI_QUOTA_ERROR:
        draft.tpdiQuotasError = action.message;
        draft.fetchingTpdiQuotas = false;
        return;
      case actionTypes.SET_TPDI_QUOTA_FETCHING:
        draft.fetchingTpdiQuotas = true;
        return;
      default:
        return;
    }
  });
};

export default dashboard;
