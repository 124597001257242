import { FormikErrors } from 'formik';
import { BadPayloadViolationError, Boom } from '../../meta/types/api';

export function toFormData(data: any): FormData {
  if (data instanceof FormData) {
    return data;
  }
  const formData = new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));
  return formData;
}

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i;

export function isEmailValid(email?: string): boolean {
  if (!email) {
    return false;
  }
  return EMAIL_REGEX.test(email);
}

export function asFormErrors(
  boom: Boom<BadPayloadViolationError[] | BadPayloadViolationError>,
  flattenParamNames = false,
): FormikErrors<any> {
  if (boom.errors === undefined) {
    return {};
  }

  let violations: BadPayloadViolationError[] = [];

  if (Array.isArray(boom.errors)) {
    violations = boom.errors;
  } else {
    violations = [boom.errors];
  }

  let formErrors = violations.reduce((acc, error) => {
    acc[error.parameter] = error.violation ? error.violation : boom.message;
    return acc;
  }, {} as any);

  if (flattenParamNames) {
    formErrors = Object.entries(formErrors).reduce((acc, [key, value]) => {
      acc[key.replace(/^.*->/, '')] = value;
      return acc;
    }, {} as any);
  }

  return formErrors;
}
