import config from '@config/config';

import api from '../../../lib/api';
import { ResourceFetch } from '../../../meta/types/api';
import { StatisticsServerResponse } from '../../../meta/types/requests-statistics';

const ACCOUNTING_STATISTICS_RESOURCE_BASE_PATH = `${config.accountingServiceBasePath}/api/v1/accounting/statistics/requests`;
const ACCOUNTING_USAGE_PATH = `${config.accountingServiceBasePath}/api/v1/accounting/usage`;

const { GET } = api;

type UserRangeResolutionParams = {
  accountId?: string;
  range?: string;
  resolution?: string;
};

type UserArchiveResolutionParams = {
  accountId?: string;
  resolution?: string;
  year: string;
  month: string;
};

export interface StatisticsResource {
  getStatistics: ResourceFetch<StatisticsServerResponse, UserRangeResolutionParams>;
  getArchive: ResourceFetch<StatisticsServerResponse, UserArchiveResolutionParams>;
  getUsage: ResourceFetch<any, any>;
}

const StatisticsResource: StatisticsResource = {
  getStatistics: GET(`${ACCOUNTING_STATISTICS_RESOURCE_BASE_PATH}/statistics`),
  getArchive: GET(`${ACCOUNTING_STATISTICS_RESOURCE_BASE_PATH}/monthlyStatistics`),
  getUsage: GET(ACCOUNTING_USAGE_PATH),
};

export default StatisticsResource;
