export enum BatchDeployment {
  EU_CENTRAL_DEPLOYMENT = 'EU_CENTRAL_DEPLOYMENT',
  US_WEST_DEPLOYMENT = 'US_WEST_DEPLOYMENT',
  CREODIAS = 'CREODIAS',
}

export const BATCH_DEPLOYMENT_TABS: { deployment: BatchDeployment; name: string }[] = [
  { deployment: BatchDeployment.EU_CENTRAL_DEPLOYMENT, name: 'EU Central' },
  { deployment: BatchDeployment.US_WEST_DEPLOYMENT, name: 'US West' },
  { deployment: BatchDeployment.CREODIAS, name: 'CreoDIAS' },
];
