import produce, { Draft } from 'immer';
import { Dictionary } from 'lodash';

import {
  IAddDataProductToCache,
  IAddDatasetDataProductsToCache,
  IIsFetchingDatasetDataProducts,
} from '../../actions/dataProducts';
import * as actionTypes from '../../constants/actionTypes';
import { DataProduct } from '../../meta/types/wms/DataProduct';
import { DatasetId } from '../../meta/types/wms/Dataset';

export const initialState = Object.freeze({
  dataProductCache: {} as Dictionary<DataProduct>,
  datasetDataProductsCache: {} as Record<DatasetId, DataProduct[]>,
  loadingDatasetDataProducts: true as boolean,
});

export type DataProductState = typeof initialState;

type DataProductAction =
  | IAddDataProductToCache
  | IAddDatasetDataProductsToCache
  | IIsFetchingDatasetDataProducts;

const dataProduct = (state: DataProductState = initialState, action: DataProductAction) => {
  return produce<DataProductState>(state, (draft: Draft<DataProductState>) => {
    switch (action.type) {
      case actionTypes.ADD_DATA_PRODUCT_TO_CACHE:
        draft.dataProductCache[action.dataProductUrl] = action.dataProduct;
        return;
      case actionTypes.ADD_DATA_PRODUCTS_TO_CACHE:
        draft.datasetDataProductsCache[action.datasetId] = action.dataProducts;
        return;
      case actionTypes.IS_FETCHING_DATAPRODUCTS:
        draft.loadingDatasetDataProducts = action.isFetchingDataProducts;
        return;
      default:
        return;
    }
  });
};

export default dataProduct;
