export enum ContentType {
  XML = 'application/xml',
  JSON = 'application/json',
  GEOJSON = 'application/geo+json',
  JS = 'application/javascript',
  ES = 'application/ecmascript',
  HTML = 'text/html',
  TEXT = 'text/plain',
  CSV = 'text/csv',
  FORM_DATA = 'multipart/form-data',
  OCTET_STREAM = 'application/octet-stream',
  PDF = 'application/pdf',
}
