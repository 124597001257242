import React from 'react';
import posed from 'react-pose';
import ConfigurationUtility from '@modules/configuration-utility';
import Collections from '@modules/collections';
import UserSettings from '@modules/user-settings';
import News from '@modules/news';
import Help from '@modules/help';
import Usage from '@modules/usage';
import BatchStatistical from '@modules/statistical-batch';
import Batch from '@modules/batch';
import Tpdi from '@modules/tpdi';
import Billing from '@modules/billing';
import StickyNewsWidget from '@modules/news/widgets/StickyNewsWidget';
import ImpersonationBanner from '@modules/auth/widgets/ImpersonationBanner';
import BannedBanner from '@modules/auth/widgets/BannedBanner';
import AccountLinkingBanner from '@modules/auth/widgets/AccountLinkingBanner';

import AsyncLoad from './components/common/AsyncLoad';
import { AuthRoutes, WmsValidAccountRoute } from './components/common/Route';
import { ROOT_PATH } from './constants/paths/internal';
import { withTracker } from './hocs';
import EnvironmentBanner from './components/EnvironmentBanner';

export const RouteContainer = posed.div({
  enter: { opacity: 1, beforeChildren: true, delay: 125 },
  exit: { opacity: 0 },
});

const AsyncDashboard = AsyncLoad({
  loader: () => import('./pages/Dashboard'),
}) as React.ComponentClass<any>;
const AsyncDashboardWithTracker = withTracker(AsyncDashboard);

export default () => {
  return (
    <AuthRoutes>
      <ImpersonationBanner />
      <EnvironmentBanner />
      <AccountLinkingBanner />
      <BannedBanner />
      <StickyNewsWidget />
      <ConfigurationUtility />
      <Collections />
      <UserSettings />
      <News />
      <Help />
      <Usage />
      <BatchStatistical />
      <Batch />
      <Tpdi />
      <Billing />
      <WmsValidAccountRoute exact path={ROOT_PATH} component={AsyncDashboardWithTracker} />
    </AuthRoutes>
  );
};
